import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetCguStatusQuery } from 'app/generated/graphql';
import useAuth from 'auth/view/hooks/useAuth';
import QueryIndicator from 'app/components/QueryIndicator';
import CGUForm, { type Event } from './CGUForm/CGUForm';

const CGUPage = () => {
  const { data, loading } = useGetCguStatusQuery();
  const navigate = useNavigate();
  const auth = useAuth();
  const isReactNativeWebView = window.ReactNativeWebView !== undefined;
  let needToValidateCGU: boolean = false;

  const postRnEvent = (event: 'cgu-accepted' | 'logout' | 'open-cgu') => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView
        .postMessage(JSON.stringify({ event }));
    }
  };

  const handleAction = (e: Event) => {
    switch (e) {
      case 'logout':
        if (isReactNativeWebView) {
          postRnEvent('logout');
        } else {
          auth.signout(() => navigate('/', { replace: true }));
        }
        break;
      case 'retry':
        if (isReactNativeWebView) {
          postRnEvent('cgu-accepted');
        } else {
          navigate(0);
        }
        break;
      case 'dashboard':
        if (isReactNativeWebView) {
          postRnEvent('cgu-accepted');
        } else {
          navigate('/');
        }
        break;
      case 'open-cgu':
        if (isReactNativeWebView) {
          postRnEvent('open-cgu');
        }
        break;
      default:
        break;
    }
  };

  if (loading) {
    return <QueryIndicator />;
  }

  needToValidateCGU = data?.me?.profile.userNeedCguValidation || false;

  return <CGUForm needToValidateCGU={needToValidateCGU} onAction={handleAction} />;
};

export default CGUPage;
