import { format as dateFnsFormat } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import LoginService from '../../auth/data/LoginService';

export const downloadDocument = (url: string | undefined | null) => {
  if (url && url !== undefined) {
    const token = LoginService.getToken();
    window.open(`${url}?bearer=${token}`, '_blank', 'noopener,noreferrer');
  }
};

export const formatDateTime = (JSONDateTime: string, format: string = 'dd/MM/yyyy') => {
  const tz = 'Europe/Paris';
  const utc = zonedTimeToUtc(JSONDateTime, tz);

  return dateFnsFormat(utcToZonedTime(utc, tz), format);
};

export const getLastUpdatedDate = (dates: (string | null)[]) => {
  const lastUpdatedDate = dates
    .filter((date): date is string => (
      date !== null && !Number.isNaN(new Date(date).getTime())
    ))
    .sort()
    .reverse()[0];

  return lastUpdatedDate ? formatDateTime(lastUpdatedDate, 'dd/MM/yyyy (HH:mm)') : null;
};

export const formatNumberToCurrency = (num: number): string => {
  const value = (Math.round(num * 100) / 100)
    .toFixed(2)
    .toString()
    .replace('.', ',');

  return `${value} €`;
};

export const isWebview = () => {
  if (window.ReactNativeWebView) {
    return true;
  }
  return false;
};

export const webviewPostProfileSubmission = () => {
  if (isWebview()) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'profile-submitted' }));
  }
};

export const webviewNavigateToAssistance = () => {
  if (isWebview()) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigate-assistance' }));
  }
};
