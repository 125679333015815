import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Time: any;
  UploadType: any;
  Uuid: any;
};

/** An administrator entity */
export type Administrator = {
  __typename?: 'Administrator';
  /** The administrator agenda link. */
  agendaLink?: Maybe<Scalars['String']>;
  /** The administrator email. */
  email: Scalars['String'];
  /** The administrator first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The administrator id. */
  id: Scalars['ID'];
  /** The image file. */
  imageFile?: Maybe<ImageFile>;
  /** The administrator job title */
  jobTitle?: Maybe<AdministratorJobTitleEnum>;
  /** The administrator last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The administrator phone number and code. */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** The administrator job title types enum. */
export enum AdministratorJobTitleEnum {
  /** Medelse counsellor */
  Adviser = 'ADVISER',
  /** Development manager */
  DevelopmentManager = 'DEVELOPMENT_MANAGER',
  /** Director of development */
  DirectorOfDevelopment = 'DIRECTOR_OF_DEVELOPMENT',
  /** Client Executive */
  ExecutiveCustomer = 'EXECUTIVE_CUSTOMER',
  /** Hiring Manager */
  HiringManager = 'HIRING_MANAGER',
  /** CTO - Associate */
  Job_9 = 'JOB_9',
  /** COO  - Cofounder */
  Job_10 = 'JOB_10',
  /** CEO - Cofounder */
  Job_11 = 'JOB_11',
  /** Business Developer */
  Job_12 = 'JOB_12',
  /** Conseillère en recrutement / Customer Success */
  Job_13 = 'JOB_13',
  /** Conseiller en recrutement / Customer Success */
  Job_14 = 'JOB_14',
  /** Product Owner */
  Job_15 = 'JOB_15',
  /** Job Dreamer */
  JobDreamer = 'JOB_DREAMER',
  /** Key Accounts Manager */
  KeyAccountManager = 'KEY_ACCOUNT_MANAGER',
  /** Medelse super hero */
  SuperHero = 'SUPER_HERO'
}

/** The advertisement status enum. */
export enum AdvertisementStatusEnum {
  /** The advertisement is deleted. */
  Deleted = 'DELETED',
  /** The advertisement is expired. */
  Expired = 'EXPIRED',
  /** The advertisement is pending : customer success must validate to be forecast. */
  Pending = 'PENDING',
  /** The advertisement is published. */
  Published = 'PUBLISHED',
  /** The advertisement is sub-accepted. */
  SubAccepted = 'SUB_ACCEPTED'
}

/** The amount object. */
export type Amount = {
  __typename?: 'Amount';
  /** The amount max. */
  max?: Maybe<Scalars['Int']>;
  /** The amount min. */
  min?: Maybe<Scalars['Int']>;
};

/** Announcement abstract entity */
export type Announcement = {
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** The announcement long representation. */
export type AnnouncementLong = Announcement & {
  __typename?: 'AnnouncementLong';
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** The announcement short representation. */
export type AnnouncementShort = Announcement & {
  __typename?: 'AnnouncementShort';
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /** The mission break time. */
  break?: Maybe<Scalars['Int']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The employee housing */
  employeeHousing?: Maybe<Scalars['String']>;
  /** Supports meals fee */
  employeeMeals?: Maybe<Scalars['String']>;
  /** Is the transport reimbursed ? */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /** Nbr needs */
  nbrNeeds?: Maybe<Scalars['Int']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The mission sector. */
  sector?: Maybe<Scalars['String']>;
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The mission time slot. */
  timeSlot?: Maybe<Scalars['Int']>;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** A connection to a list of items. */
export type AnnouncementShortConnection = {
  __typename?: 'AnnouncementShortConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<AnnouncementShortEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The amount of announcements. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type AnnouncementShortConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<AdvertisementStatusEnum>>;
};

/** An edge in a connection. */
export type AnnouncementShortEdge = {
  __typename?: 'AnnouncementShortEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AnnouncementShort>;
};

/** The availability establishment types enum. */
export enum AvailabilityEstablishmentTypeEnum {
  /** Clinical / Private hospital */
  Clinical = 'CLINICAL',
  /** EHPAD / Nursing Home. */
  EhpadNursing = 'EHPAD_NURSING',
  /** Hospitalization at home. */
  Had = 'HAD',
  /** Health Center. */
  HealthCenter = 'HEALTH_CENTER',
  /** Heal House. */
  HealthHouse = 'HEALTH_HOUSE',
  /** Home consultation service */
  HomeConsultationService = 'HOME_CONSULTATION_SERVICE',
  /** Laboratory. */
  Labo = 'LABO',
  /** Group / Individual Liberal Cabinet. */
  LiberalOffice = 'LIBERAL_OFFICE',
  /** Other social medicine */
  OtherMs = 'OTHER_MS',
  /** Private company. */
  PrivComp = 'PRIV_COMP',
  /** Public hospital. */
  PublicHospital = 'PUBLIC_HOSPITAL',
  /** Aftercare and Rehabilitation Care. */
  Ssr = 'SSR',
  /** Teleconsultation service */
  Teleconsutlation = 'TELECONSUTLATION'
}

/** A candidate. */
export type Candidate = NodeInterface & {
  __typename?: 'Candidate';
  /** The candidate account status. */
  accountStatus?: Maybe<CandidateAccountStatusEnum>;
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** Link to Medelse advantage (CE) */
  advantageLink?: Maybe<Scalars['String']>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** GroupOffices that has been contractualized. */
  contractualizedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** The user establishmentTypes. */
  establishmentTypes: Array<Maybe<EstablishmentTypeEnum>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** True if user has all criteria filled, false otherwise. */
  hasAllCriteriaFilled: Scalars['Boolean'];
  /** True if user has future planning date active, false otherwise. */
  hasFuturePlanningDateActive: Scalars['Boolean'];
  /** True if user profile is filled, false otherwise. */
  hasProfileFilled: Scalars['Boolean'];
  /** Does the candidate need to rate the mobile application */
  hasToRateTheApp: Scalars['Boolean'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** Datetime when iban was updated */
  ibanUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** GroupOffices that has been invoiced. */
  invoicedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The user locations. */
  locations: Array<Maybe<CandidateLocation>>;
  /** Returns whether or not a matching calculation is in progress for this candidate */
  matchingCalculation: Scalars['Boolean'];
  /** GroupOffice that match missions filtered by macroStatus. */
  matchingGroupOffices?: Maybe<Array<GroupOffice>>;
  /** GroupOffices that have matching feesInvoices */
  medelseFeesInvoicedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The paginated user medelse invoice. */
  medelseFeesInvoices?: Maybe<MedelseFeesInvoiceConnection>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** Candidate progression information */
  progression: CandidateProgression;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The current rating of fulfilled missions compared to contractualized cancellations on a scale of 10 */
  reliabilityScore: Scalars['Float'];
  /** A valid rpps number. */
  rppsNumber?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user services. */
  services: Array<Maybe<Service>>;
  /** A valid siret number. */
  siret?: Maybe<Scalars['String']>;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** Candidate suspension information */
  suspension?: Maybe<Suspension>;
  /** The user tags */
  tags: Array<CandidateTag>;
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A candidate. */
export type CandidateContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A candidate. */
export type CandidateInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A candidate. */
export type CandidateMatchingGroupOfficesArgs = {
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
};


/** A candidate. */
export type CandidateMedelseFeesInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedelseFeesInvoiceOrderFieldInput>>;
};


/** A candidate. */
export type CandidateMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A candidate. */
export type CandidateMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A candidate. */
export type CandidatePlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A candidate. */
export type CandidateStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** Define the mission to accept. */
export type CandidateAcceptMissionInput = {
  /** The mission id to accept. */
  missionUuid: Scalars['Uuid'];
};

/** The accept missions mutation payload. */
export type CandidateAcceptMissionsPayload = {
  __typename?: 'CandidateAcceptMissionsPayload';
  /** The mission object. */
  missions: Array<Mission>;
  /** The candidate whose accept mission. */
  user: Candidate;
};

/** Account status for candidate */
export enum CandidateAccountStatusEnum {
  /** Candidate is OFF (and never been ON) */
  Off = 'OFF',
  /** Candidate is ON */
  On = 'ON',
  /** Candidate is OFF (and previously been ON) */
  TemporaryOff = 'TEMPORARY_OFF'
}

/** Set the firebase token to add. */
export type CandidateAddFirebaseCloudMessageTokenInput = {
  /** The new firebase token. */
  token: Scalars['String'];
};

/** The firebase token creation mutation payload. */
export type CandidateAddFirebaseCloudMessageTokenPayload = {
  __typename?: 'CandidateAddFirebaseCloudMessageTokenPayload';
  /** True if token has been added. */
  success: Scalars['Boolean'];
  /** The user whose token was added. */
  user: Candidate;
};

/** Set the planning slot parameters. */
export type CandidateAddPlanningSlotInput = {
  /** Date range period for the planning slot */
  dates: DateRangeInput;
  /** The typical week for the planning slot. */
  typicalWeek: Array<TypicalDayInput>;
};

/** The planning slot payload. */
export type CandidateAddPlanningSlotPayload = {
  __typename?: 'CandidateAddPlanningSlotPayload';
  /** The updated planning days. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** True if planning slot has been created. */
  success: Scalars['Boolean'];
};

/** Define the mission to approve. */
export type CandidateApproveMissionInput = {
  /** The mission uuid to approve. */
  missionUuid: Scalars['Uuid'];
};

/** Define the mission to cancel. */
export type CandidateCancelMissionInput = {
  /** The mission cancelation reason. */
  cancelReason?: InputMaybe<MissionCancelReasonEnum>;
  /** The mission cancellation reason. */
  cancelReasonCustom?: InputMaybe<Scalars['String']>;
  /** The mission id to cancel. */
  missionUuid: Scalars['Uuid'];
};

/** The cancel missions mutation payload. */
export type CandidateCancelMissionsPayload = {
  __typename?: 'CandidateCancelMissionsPayload';
  /** The missions. */
  missions: Array<Mission>;
  /** The candidate whose cancel mission. */
  user: Candidate;
};

/** Define the mission to cancel request. */
export type CandidateCancelRequestMissionInput = {
  /** The mission cancelation request reason. */
  cancelReason?: InputMaybe<MissionCancelRequestReasonEnum>;
  /** The mission cancellation request custom reason. */
  cancelReasonCustom?: InputMaybe<Scalars['String']>;
  /** The mission id to cancel request. */
  missionUuid: Scalars['Uuid'];
};

/** The cancel missions request mutation payload. */
export type CandidateCancelRequestMissionsPayload = {
  __typename?: 'CandidateCancelRequestMissionsPayload';
  /** The missions. */
  missions: Array<Mission>;
  /** The candidate whose cancel request mission. */
  user: Candidate;
};

/** The cv mutation payload. */
export type CandidateCvPayload = {
  __typename?: 'CandidateCvPayload';
  /** The cv object. */
  cv: ImageFile;
  /** The user whose link cv. */
  user: Candidate;
};

/** The cv deletion mutation payload. */
export type CandidateCvRemovedPayload = {
  __typename?: 'CandidateCvRemovedPayload';
  /** True if cv has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose cv was deleted. */
  user: Candidate;
};

export type CandidateLocation = {
  __typename?: 'CandidateLocation';
  /** The city. */
  city?: Maybe<City>;
  /** The department. */
  department?: Maybe<Department>;
  /** The range associated to the city or department. */
  range: Scalars['Int'];
  /** The CandidateLocation uuid. */
  uuid: Scalars['Uuid'];
};

/** Add new UserLocation */
export type CandidateLocationInput = {
  /** The city id. */
  city?: InputMaybe<Scalars['ID']>;
  /** The department id. */
  department?: InputMaybe<Scalars['ID']>;
  /** The range associated to the city or department. */
  range: Scalars['Int'];
};

/** The candidate location mutation payload. */
export type CandidateLocationPayload = {
  __typename?: 'CandidateLocationPayload';
  /** new CandidateLocation created */
  location: CandidateLocation;
  /** Current user (Candidate) */
  user: Candidate;
};

/** The candidate location deletion mutation payload. */
export type CandidateLocationRemovePayload = {
  __typename?: 'CandidateLocationRemovePayload';
  /** True if location has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose location was deleted. */
  user: Candidate;
};

/** candidate and missions mutation payload. */
export type CandidateMissionsPayload = {
  __typename?: 'CandidateMissionsPayload';
  /** The missions. */
  missions: Array<Mission>;
  /** The candidate. */
  user: Candidate;
};

/** The NIC mutation payload. */
export type CandidateNicPayload = {
  __typename?: 'CandidateNicPayload';
  /** Current user (Candidate) */
  user: Candidate;
};

/** The update subscription mutation payload. */
export type CandidateParametersUpdateSubscriptionPayload = {
  __typename?: 'CandidateParametersUpdateSubscriptionPayload';
  /** If true, user will be subscribed to news letter. */
  newsLetter: Scalars['Boolean'];
  /** The user whose link password. */
  user: Candidate;
};

/** The default candidate mutation payload. */
export type CandidatePayload = {
  __typename?: 'CandidatePayload';
  /** Current user (Candidate) */
  user: Candidate;
};

/** Set the user pro information parameters. */
export type CandidateProInformationInput = {
  /** The user can exercice status. */
  canExercise?: InputMaybe<UserCanExerciseEnum>;
  /** The user council order number. */
  councilOrderNumber?: InputMaybe<Scalars['String']>;
  /** Set true if the user is subscribed on council order. */
  councilRegistration?: InputMaybe<Scalars['Boolean']>;
  /** The user rpps number. */
  rppsNumber?: InputMaybe<Scalars['String']>;
  /** The user SIRET number. */
  siret?: InputMaybe<Scalars['String']>;
  /** The user urssaf number. */
  urssaf?: InputMaybe<Scalars['String']>;
};

/** All the information concerning the Candidate's progression */
export type CandidateProgression = {
  __typename?: 'CandidateProgression';
  /** Number of missions the candidate has cancelled illegitimately last 30 days */
  cancelledMissions: Scalars['Int'];
  /** Number of missions the candidate has fulfilled last 30 days */
  completedMissions: Scalars['Int'];
  /** Total number of missions the user needs to complete in order to obtain the next tier of the progression badge */
  currentExperimentedBadgeInterval: TagRange;
  /** Number of missions the candidate has cancelled but justified last 30 days */
  legitimatelyCancelledMissions: Scalars['Int'];
  /** Number of missions the user needs to complete in order to obtain the next tier of the progression badge */
  missionsUntilNextExperimentedBadgeTier?: Maybe<Scalars['Int']>;
  /** Number of badges the candidate has obtained last 30 days */
  obtainedBadges: Scalars['Int'];
  /** Total number of missions the candidate has fulfilled */
  totalCompletedMissions: Scalars['Int'];
};

/** Rate a mission with multiple rating items. */
export type CandidateRateMissionInput = {
  /** The mission uuid to approve. */
  missionUuid: Scalars['Uuid'];
  /** A free text for other / custom rating information. */
  ratingOtherText?: InputMaybe<Scalars['String']>;
  /** The mission report reason. */
  ratings: Array<MissionRatingInput>;
};

/** Define the missions to read. */
export type CandidateReadMissionInput = {
  /** The missions uuid. */
  missionsUuid?: InputMaybe<Array<Scalars['Uuid']>>;
};

/** The read mission mutation payload. */
export type CandidateReadMissionPayload = {
  __typename?: 'CandidateReadMissionPayload';
  /** The readed missions. */
  missions: Array<Mission>;
  /** True if missions has been mark as read. */
  success: Scalars['Boolean'];
};

/** Define the mission to refuse. */
export type CandidateRefuseMissionInput = {
  /** The mission id to refuse. */
  missionUuid: Scalars['Uuid'];
  /** The mission refusal reason. */
  refusalReason?: InputMaybe<MissionRefusalReasonEnum>;
  /** The mission refusal reason. */
  refusalReasonCustom?: InputMaybe<Scalars['String']>;
};

/** The refuse missions mutation payload. */
export type CandidateRefuseMissionsPayload = {
  __typename?: 'CandidateRefuseMissionsPayload';
  /** The missions. */
  missions: Array<Mission>;
  /** The candidate whose refuse mission. */
  user: Candidate;
};

/** Define the mission to approve. */
export type CandidateReportMissionInput = {
  /** The break time duration in minutes if reason is BAD_TIME. */
  break?: InputMaybe<Scalars['Int']>;
  /** The mission report comment if reason is NOT_DONE or OTHER. */
  comment?: InputMaybe<Scalars['String']>;
  /** The working time duration in minutes if reason is BAD_TIME. */
  duration?: InputMaybe<Scalars['Int']>;
  /** The end hour time if reason is BAD_TIME. */
  endTime?: InputMaybe<Scalars['Time']>;
  /** The mission uuid to approve. */
  missionUuid: Scalars['Uuid'];
  /** The mission report reason. */
  reason: MissionReportReasonEnum;
  /** The start hour time if reason is BAD_TIME. */
  startTime?: InputMaybe<Scalars['Time']>;
};

/** The skill mutation payload. */
export type CandidateSkillPayload = {
  __typename?: 'CandidateSkillPayload';
  /** The skill object. */
  skill: Skill;
  /** The user whose link skill. */
  user: Candidate;
};

/** The skill deletion mutation payload. */
export type CandidateSkillRemovedPayload = {
  __typename?: 'CandidateSkillRemovedPayload';
  /** True if skill has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose skill was deleted. */
  user: Candidate;
};

/** The specialty mutation payload. */
export type CandidateSpecialtyPayload = {
  __typename?: 'CandidateSpecialtyPayload';
  /** The specialty object. */
  specialty: Specialty;
  /** The user whose link specialty. */
  user: Candidate;
};

/** The specialty deletion mutation payload. */
export type CandidateSpecialtyRemovedPayload = {
  __typename?: 'CandidateSpecialtyRemovedPayload';
  /** True if specialty has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose specialty was deleted. */
  user: Candidate;
};

/** Candidate UserTag */
export type CandidateTag = {
  __typename?: 'CandidateTag';
  /** Percentage of users having unlocked the badge */
  stat?: Maybe<Scalars['Float']>;
  /** UserTagEnum */
  tag: UserTagEnum;
};

/** Set the global unavailability for the candidate. */
export type CandidateUpdateGlobalUnavailabilityInput = {
  /** The candidate global unavailability. */
  globalUnavailability: Scalars['Boolean'];
};

/** The update global unavailability payload. */
export type CandidateUpdateGlobalUnavailabilityPayload = {
  __typename?: 'CandidateUpdateGlobalUnavailabilityPayload';
  /** The updated planning days. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user whose set global unavailability. */
  user: Candidate;
};

/** Set the planning days to update. */
export type CandidateUpdatePlanningDaysInput = {
  /** The planning days to update. */
  planningDays: Array<PlanningDayInput>;
};

/** The update planning days payload. */
export type CandidateUpdatePlanningDaysPayload = {
  __typename?: 'CandidateUpdatePlanningDaysPayload';
  /** The updated planning days. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** True if all planning days has been updated. */
  success: Scalars['Boolean'];
};

/** DEPRECATED - Add a specialty in search criterias. */
export type CandidateUpdateSearchCriteriaInput = {
  /** The establishment type id add in criterias. */
  establishmentTypes: Array<AvailabilityEstablishmentTypeEnum>;
  /** Generate matching after update. */
  generateMatching?: InputMaybe<Scalars['Boolean']>;
  /** The locations to add in criterias. */
  locationsRanges: Array<CandidateLocationInput>;
  /** The services id to add in criterias. */
  servicesId?: InputMaybe<Array<Scalars['ID']>>;
  /** The specialties id to add in criterias. */
  specialtiesId: Array<Scalars['ID']>;
};

/** The update criteria type mutation payload. */
export type CandidateUpdateSearchCriteriaPayload = {
  __typename?: 'CandidateUpdateSearchCriteriaPayload';
  /** The user search criterias. */
  searchCriterias: SearchCriteria;
  /** The user whose link password. */
  user: Candidate;
};

/** Set the update subscription parameters. */
export type CandidateUpdateSubscriptionInput = {
  /** If true, user will be subscribed to news letter. */
  status: Scalars['Boolean'];
};

export type City = NodeInterface & {
  __typename?: 'City';
  /** The city coordinates. */
  coordinates?: Maybe<Coordinates>;
  /** The country where the city is located. */
  country: Country;
  /** The department where the city is located. */
  department?: Maybe<Department>;
  /** The node id. A base 64 encode of 'City:$id'. */
  globalId: Scalars['ID'];
  /** The city google place id. */
  googlePlaceId?: Maybe<Scalars['String']>;
  /** The City id. */
  id: Scalars['ID'];
  /** The city name. */
  name?: Maybe<Scalars['String']>;
  /** The city North Est coordinates. */
  northEastCoordinates?: Maybe<Coordinates>;
  /** The city slug used in url. */
  slug?: Maybe<Scalars['String']>;
  /** The city South West coordinates. */
  southWestCoordinates?: Maybe<Coordinates>;
  /** The city zip code */
  zipCode?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CityConnection = {
  __typename?: 'CityConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<CityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of cities. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CityEdge = {
  __typename?: 'CityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<City>;
};

/** Contracts */
export type Contract = {
  __typename?: 'Contract';
  /** The contract candidate */
  candidate?: Maybe<Candidate>;
  /** The candidate signed date */
  candidateSignedAt?: Maybe<Scalars['DateTime']>;
  /** The candidate signing link */
  candidateSigningLink?: Maybe<Scalars['String']>;
  /** The candidate signing status */
  candidateSigningStatus?: Maybe<SigningStatus>;
  /** The candidate signed date */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The contract number */
  contractNumber: Scalars['String'];
  /** The contract date */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The contract missions dates */
  dates?: Maybe<DateRange>;
  /** The contract document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The contract group office */
  groupOffice?: Maybe<GroupOffice>;
  /** is a CDD contract */
  isCDD: Scalars['Boolean'];
  /** The package status */
  packageStatus?: Maybe<PackageStatus>;
  /** The contract parent (if the contract is an amendment). */
  parent?: Maybe<Contract>;
  /** The contract recruiter email */
  recruiterEmail?: Maybe<Scalars['String']>;
  /** The recruiter signed date */
  recruiterSignedAt?: Maybe<Scalars['DateTime']>;
  /** The recruiter signing link */
  recruiterSigningLink?: Maybe<Scalars['String']>;
  /** The recruiter signing status */
  recruiterSigningStatus?: Maybe<SigningStatus>;
  /**
   * The signed contract document link
   * @deprecated Use documentDownloadLink instead
   */
  signedDocumentDownloadLink?: Maybe<Scalars['String']>;
  /** The contract specialty */
  specialty?: Maybe<Specialty>;
  /** The contract uuid. */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type ContractConnection = {
  __typename?: 'ContractConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ContractEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** Contracts count. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type ContractConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};

/** Contract detail */
export type ContractDetail = {
  __typename?: 'ContractDetail';
  /** Break duration in minutes */
  breakDuration?: Maybe<Scalars['Int']>;
  /** The candidate. */
  candidate: Candidate;
  /** Awaiting candidate validation */
  candidateNeedToBeValidated?: Maybe<Scalars['Boolean']>;
  /** The candidate validation date. */
  candidateValidationDate?: Maybe<Scalars['DateTime']>;
  /** The candidate validation status. */
  candidateValidationStatus?: Maybe<ValidationStatusEnum>;
  /** The child contract detail. */
  child?: Maybe<ContractDetail>;
  /** The contract. */
  contract: Contract;
  /** The contract detail dates. */
  dateTimes: DateTimeRange;
  /**
   * The contract detail dates.
   * @deprecated Use dateTimes instead
   */
  dates: DateRange;
  /** The employee housing */
  employeeHousing?: Maybe<Scalars['String']>;
  /** Supports meals fee */
  employeeMeals?: Maybe<Scalars['String']>;
  /** Is the transport reimbursed ? */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The candidate. */
  groupOffice: GroupOffice;
  /** The invoiced. */
  invoice?: Maybe<Invoice>;
  /** The invoice status. */
  invoiceStatus?: Maybe<ContractDetailInvoiceStatusEnum>;
  /** The invoiced amount. */
  invoicedAmount?: Maybe<InvoicedAmount>;
  /** The parent contract detail. */
  parent?: Maybe<ContractDetail>;
  /** The recruiter validation date. */
  recruiterValidationDate?: Maybe<Scalars['DateTime']>;
  /** The recruiter validation status. */
  recruiterValidationStatus?: Maybe<ValidationStatusEnum>;
  /** The rhythm. */
  rhythm: RhythmEnum;
  /** The salary. */
  salary: Scalars['Float'];
  /** The service. */
  service: Service;
  /** The specialty. */
  specialty: Specialty;
  /** The contract detail status. */
  status: ContractDetailStatusEnum;
  /** Time slot */
  timeSlot?: Maybe<Scalars['Int']>;
  /** The contract detail uuid. */
  uuid: Scalars['Uuid'];
};

/** The contract detail invoice status. */
export enum ContractDetailInvoiceStatusEnum {
  /** Contract detail is not invoiceable. */
  Invoiceable = 'INVOICEABLE',
  /** Contract detail is invoiced. */
  Invoiced = 'INVOICED',
  /** Contract detail is invoiceable. */
  NotInvoiceable = 'NOT_INVOICEABLE'
}

/** The contract detail status. */
export enum ContractDetailStatusEnum {
  /** Contract detail is active. */
  Active = 'ACTIVE',
  /** Contract detail is amended. */
  Amendment = 'AMENDMENT',
  /** Contract detail is cancelled. */
  Cancel = 'CANCEL'
}

/** An edge in a connection. */
export type ContractEdge = {
  __typename?: 'ContractEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Contract>;
};

/** Nic type. */
export enum ContractNicEnum {
  /** NIC back. */
  Back = 'BACK',
  /** NIC front. */
  Front = 'FRONT'
}

/** The contract field to order by. */
export enum ContractOrderFieldEnum {
  /** Order by contractNumber field */
  ContractNumber = 'CONTRACT_NUMBER',
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by first mission date field */
  MissionDate = 'MISSION_DATE'
}

/** Ordering options for contract connections */
export type ContractOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order contract by. */
  field: ContractOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** The coordinates representation. */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** The latitude. */
  latitude?: Maybe<Scalars['Float']>;
  /** The longitude. */
  longitude?: Maybe<Scalars['Float']>;
};

export type Country = NodeInterface & {
  __typename?: 'Country';
  /** The country alpha code 2. */
  alpha2: Scalars['String'];
  /** The country alpha code 3. */
  alpha3: Scalars['String'];
  /** The country id. */
  id: Scalars['ID'];
  /** If true, the country is active. */
  isActive: Scalars['Boolean'];
  /** The country name. */
  name: Scalars['String'];
  /** The international country phone code. */
  phoneCode?: Maybe<Scalars['Int']>;
  /** The country slug. Used in url. */
  slug?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of countries. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** The end date. */
  endDate?: Maybe<Scalars['Date']>;
  /** The start date. */
  startDate?: Maybe<Scalars['Date']>;
};

/** Date range input */
export type DateRangeInput = {
  /** The end date. */
  endDate: Scalars['Date'];
  /** The start date. */
  startDate: Scalars['Date'];
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  /** The end datetime. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The start datetime. */
  startDate?: Maybe<Scalars['DateTime']>;
};

/** The availability status. */
export enum DayEnum {
  /** Friday day */
  Friday = 'FRIDAY',
  /** Monday day */
  Monday = 'MONDAY',
  /** Saturday day */
  Saturday = 'SATURDAY',
  /** Sunday day */
  Sunday = 'SUNDAY',
  /** Thursday day */
  Thursday = 'THURSDAY',
  /** Tuesday day */
  Tuesday = 'TUESDAY',
  /** Wednesday day */
  Wednesday = 'WEDNESDAY'
}

export type Department = NodeInterface & {
  __typename?: 'Department';
  /** The country where the department is located. */
  country: Country;
  /** The node id. A base 64 encode of 'Department:$id'. */
  globalId: Scalars['ID'];
  /** The department id. */
  id: Scalars['ID'];
  /** The department name. */
  name?: Maybe<Scalars['String']>;
  /** The local department number. */
  number?: Maybe<Scalars['String']>;
};

/** A document entity. */
export type Document = {
  __typename?: 'Document';
  /** Set when the document is deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The document id. */
  id: Scalars['ID'];
  /** The document mime types accepted. */
  mimeTypes: Array<Scalars['String']>;
  /** The document name. */
  name: Scalars['String'];
  /** The document order. */
  order?: Maybe<Scalars['Int']>;
};

/** The availability establishment types enum. */
export enum EstablishmentTypeEnum {
  /** Clinical / Private hospital */
  Clinical = 'CLINICAL',
  /** EHPAD / Nursing Home. */
  EhpadNursing = 'EHPAD_NURSING',
  /** Hospitalization at home. */
  Had = 'HAD',
  /** Health Center. */
  HealthCenter = 'HEALTH_CENTER',
  /** Heal House. */
  HealthHouse = 'HEALTH_HOUSE',
  /** Home consultation service */
  HomeConsultationService = 'HOME_CONSULTATION_SERVICE',
  /** Laboratory. */
  Labo = 'LABO',
  /** Other social medicine */
  OtherMs = 'OTHER_MS',
  /** Private company. */
  PrivComp = 'PRIV_COMP',
  /** Public hospital. */
  PublicHospital = 'PUBLIC_HOSPITAL',
  /** Aftercare and Rehabilitation Care. */
  Ssr = 'SSR',
  /** Teleconsultation service */
  Teleconsultation = 'TELECONSULTATION'
}

/** Export to spreadsheet options. */
export type ExportToSpreadsheetInput = {
  fields?: InputMaybe<Array<InputMaybe<SpreadsheetExportableFields>>>;
  /** Expected file''s format: Csv or Xlsx */
  format?: InputMaybe<Scalars['String']>;
  /** Spreadsheet's file name. */
  name?: InputMaybe<Scalars['String']>;
};

/** The file representation. */
export type File = {
  __typename?: 'File';
  /** The file name. */
  name: Scalars['String'];
  /** The public file name without uniq id. */
  publicName: Scalars['String'];
  /** The file upload date. */
  uploadedAt: Scalars['DateTime'];
  /** The file url. */
  url: Scalars['String'];
};

/** Gender types. */
export enum GenderEnum {
  /** A doctor */
  Doctor = 'DOCTOR',
  /** Female gender. */
  Female = 'FEMALE',
  /** Male gender. */
  Male = 'MALE',
  /** No gender expected. */
  None = 'NONE'
}

/** Group. */
export type Group = {
  __typename?: 'Group';
  /** The group id. */
  id: Scalars['ID'];
  /** Is the group active ? */
  isActive: Scalars['Boolean'];
  /** The group name. */
  name: Scalars['String'];
  /** The group public information */
  publicInformation: PublicInformation;
};

/** Group office. */
export type GroupOffice = {
  __typename?: 'GroupOffice';
  /** The office address. */
  addr1?: Maybe<Scalars['String']>;
  /** The office address 2. */
  addr2?: Maybe<Scalars['String']>;
  /** The office city. */
  city: City;
  /** The group office coordinates. */
  coordinates?: Maybe<Coordinates>;
  /** The office country. */
  country: Country;
  /** The parent group. */
  group?: Maybe<Group>;
  /** The office introduce text. */
  groupOfficeIntroduceText?: Maybe<Scalars['String']>;
  /** Has the office an Upflow ID stored ? */
  hasUpflowId: Scalars['Boolean'];
  /** The office id. */
  id: Scalars['ID'];
  /** Does the group office have CDD missions */
  isCDD: Scalars['Boolean'];
  /** Has the group office the feature mission confirmation enabled */
  isFeatureConfirmationEnabled: Scalars['Boolean'];
  /** The group office Medelse fees */
  medelseFees?: Maybe<Array<MedelseFees>>;
  /** The group office metabases */
  metabases?: Maybe<GroupOfficeMetabase>;
  /** The office name. */
  name: Scalars['String'];
  /** The group office public information */
  publicInformation: PublicInformation;
  /** The group office tags */
  tags: Array<GroupOfficeTagEnum>;
  /** The office uuid. */
  uuid: Scalars['Uuid'];
  /** The office zipcode. */
  zipCode?: Maybe<Scalars['String']>;
};

/** The address object. */
export type GroupOfficeMetabase = {
  __typename?: 'GroupOfficeMetabase';
  /** The contract and invoice group office metabase. */
  contractInvoice?: Maybe<Scalars['String']>;
  /** The general group office metabase. */
  homepage?: Maybe<Scalars['String']>;
  /** The medelse rate group office metabase. */
  medelseRate?: Maybe<Scalars['String']>;
};

/** Group office tags */
export enum GroupOfficeTagEnum {
  /** Adapted equipment */
  Equipment = 'EQUIPMENT',
  /** new office on Medelse */
  New = 'NEW',
  /** Reception and orientation quality */
  Reception = 'RECEPTION',
  /** reliable and low cancellation rate */
  Reliable = 'RELIABLE',
  /** Welcoming and professional team */
  Team = 'TEAM',
  /** Efficient transmissions */
  Transmissions = 'TRANSMISSIONS',
  /** Accessibility and transport */
  Transport = 'TRANSPORT'
}

/** The image file crop object. */
export type ImageCrop = {
  __typename?: 'ImageCrop';
  /** The image url cropped has 35. */
  crop35: Scalars['String'];
  /** The image url cropped has 75. */
  crop75: Scalars['String'];
  /** The image url cropped has 90. */
  crop90: Scalars['String'];
  /** The image url cropped has 100. */
  crop100: Scalars['String'];
  /** The image url cropped has 130. */
  crop130: Scalars['String'];
  /** The image url cropped has 273. */
  crop273: Scalars['String'];
};

/** The image file representation. */
export type ImageFile = {
  __typename?: 'ImageFile';
  /** The image crop object. */
  crop: ImageCrop;
  /** The file name. */
  name: Scalars['String'];
  /** The public file name without uniq id. */
  publicName: Scalars['String'];
  /** The image square object. */
  square: ImageSquare;
  /** The file upload date. */
  uploadedAt: Scalars['DateTime'];
  /** The file url. */
  url: Scalars['String'];
};

/** The image mutation payload. */
export type ImagePayload = {
  __typename?: 'ImagePayload';
  /** The image object. */
  image: ImageFile;
  /** The user whose link image. */
  user: User;
};

/** The image deletion mutation payload. */
export type ImageRemovedPayload = {
  __typename?: 'ImageRemovedPayload';
  /** True if image has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose image was deleted. */
  user: User;
};

/** The image file crop object. */
export type ImageSquare = {
  __typename?: 'ImageSquare';
  /** The image url squared has 75. */
  square75: Scalars['String'];
  /** The image url squared has 90. */
  square90: Scalars['String'];
  /** The image url squared has 130. */
  square130: Scalars['String'];
  /** The image url squared has 273. */
  square273: Scalars['String'];
  /** The image url squared has 350. */
  square350: Scalars['String'];
};

/** The validation data object */
export type Initiator = {
  __typename?: 'Initiator';
  /** The initiator first name */
  firstName?: Maybe<Scalars['String']>;
  /** The initiator gender */
  gender?: Maybe<Scalars['String']>;
  /** The initiator id */
  id: Scalars['ID'];
  /** The initiator last name */
  lastName?: Maybe<Scalars['String']>;
};

/** Invoices */
export type Invoice = {
  __typename?: 'Invoice';
  /** The invoice candidate */
  candidate?: Maybe<Candidate>;
  /** The invoice contract detail */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The invoice created date */
  createdAt: Scalars['DateTime'];
  /** The invoice dates */
  dates?: Maybe<DateRange>;
  /** The invoice document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The invoice group office */
  groupOffice?: Maybe<GroupOffice>;
  /** The invoice id */
  id: Scalars['ID'];
  /** The invoice payment data */
  payment?: Maybe<Payment>;
  /** The invoice total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  /** The invoice uuid */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The total of invoice. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type InvoiceConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

/** The invoice field to order by. */
export enum InvoiceOrderFieldEnum {
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by invoiceNumber (id) field */
  InvoiceNumber = 'INVOICE_NUMBER'
}

/** Ordering options for invoice connections */
export type InvoiceOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order invoice by. */
  field: InvoiceOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoicedAmount = {
  __typename?: 'InvoicedAmount';
  /** The medelse fee amount. */
  medelseAmount?: Maybe<Scalars['Float']>;
  /** The pro amount. */
  proAmount?: Maybe<Scalars['Float']>;
  /** The invoiced total amount. */
  totalAmount?: Maybe<Scalars['Float']>;
};

/** The available locale. */
export enum LocaleEnum {
  Fr = 'FR'
}

/** Set the user profile. */
export type LocalisationInput = {
  /** The address */
  address: Scalars['String'];
  /** The city address. */
  city: Scalars['ID'];
  /** The city zip code. */
  zipCode: Scalars['String'];
};

/** The location description. */
export type Location = {
  __typename?: 'Location';
  /** The location address. */
  address?: Maybe<Scalars['String']>;
  /** The location city. */
  city?: Maybe<City>;
  /** The location country. */
  country?: Maybe<Country>;
  /** The location zip code. */
  zipCode?: Maybe<Scalars['String']>;
};

export type MedelseFees = {
  __typename?: 'MedelseFees';
  /** The medelse fees date. */
  date: Scalars['Date'];
  /** The medelse fees percent. */
  percent: Scalars['Float'];
};

/** Medelse Invoices for User Fees */
export type MedelseFeesInvoice = {
  __typename?: 'MedelseFeesInvoice';
  /** The medelse invoice amount without tax */
  amountExclTax?: Maybe<Scalars['Float']>;
  /** The medelse invoice amount with tax included */
  amountInclTax?: Maybe<Scalars['Float']>;
  /** The medelse invoice vat amount */
  amountVat?: Maybe<Scalars['Float']>;
  /** The medelse invoice candidate */
  candidate?: Maybe<Candidate>;
  /** The invoice contract detail */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The invoice created date */
  createdAt: Scalars['DateTime'];
  /** The medelse invoice document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The medelse invoice group office */
  groupOffice?: Maybe<GroupOffice>;
  /** Invoice linked to this medelse invoice */
  invoice?: Maybe<Invoice>;
  /** The medelse invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** The medelse invoice total amount. Same as amountInclTax */
  totalAmount?: Maybe<Scalars['Float']>;
  /** The medelse invoice uuid */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type MedelseFeesInvoiceConnection = {
  __typename?: 'MedelseFeesInvoiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<MedelseFeesInvoiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total of medelse invoice. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MedelseFeesInvoiceEdge = {
  __typename?: 'MedelseFeesInvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MedelseFeesInvoice>;
};

/** The medelse invoice field to order by. */
export enum MedelseFeesInvoiceOrderFieldEnum {
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by invoiceNumber field */
  InvoiceNumber = 'INVOICE_NUMBER'
}

/** Ordering options for medelse invoice connections */
export type MedelseFeesInvoiceOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order medese invoice by. */
  field: MedelseFeesInvoiceOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** Missions */
export type Mission = {
  __typename?: 'Mission';
  /** The announcement additional info file stream url if exists */
  additionalInfoFile?: Maybe<Scalars['String']>;
  /** DEPRECATED - The mission break time. */
  break: Scalars['Int'];
  /** The mission break time. */
  breakDuration: Scalars['Int'];
  /** The mission candidate. */
  candidate: Candidate;
  /** The active contract detail */
  contractDetail?: Maybe<ContractDetail>;
  /** The mission datetimes. */
  dates: DateTimeRange;
  /** The mission description. */
  description?: Maybe<Scalars['String']>;
  /** The mission employee housing info. */
  employeeHousing?: Maybe<Scalars['String']>;
  /** The mission employee meals info. */
  employeeMeal?: Maybe<Scalars['String']>;
  /** The mission employee transport info. */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The estimated mission invoice date. */
  estimatedInvoiceDate: Scalars['DateTime'];
  /** The mission estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The mission expired date. */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** For CDD - The mission gross salary. */
  grossSalary?: Maybe<Scalars['Float']>;
  /** The mission group office. */
  groupOffice: GroupOffice;
  /** The mission last object status. */
  lastStatusHistory: MissionStatus;
  /** The mission macro status. */
  macroStatus: MissionMacroStatusEnum;
  /** The mission medelse fees. */
  medelseFeesAmount: Scalars['Float'];
  /** For CDD - The mission paid holidays bonus. */
  paidHolidaysBonus?: Maybe<Scalars['Float']>;
  /** The mission practical information from office note field */
  practicalInformation?: Maybe<Scalars['String']>;
  /** The mission pro amount. */
  proAmount: Scalars['Float'];
  /** The mission read at date. */
  readAt?: Maybe<Scalars['DateTime']>;
  /** The recruiter validation datas. */
  recruiterValidation?: Maybe<Validation>;
  /** The mission's replacement type */
  replacementType?: Maybe<ReplacementType>;
  /** The mission rhythm. */
  rhythm: RhythmEnum;
  /** The mission salary. */
  salary: Scalars['Float'];
  /** The mission sector. */
  sector?: Maybe<Scalars['String']>;
  /** The mission service. */
  service: Service;
  /** For CDD - The mission contract bonus. */
  shortTermContractBonus?: Maybe<Scalars['Float']>;
  /** The mission specialty. */
  specialty: Specialty;
  /** The mission specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The mission state. */
  state: MissionStateEnum;
  /** The mission status. */
  status: MissionStatusEnum;
  /** The mission status object history. */
  statusHistory: Array<MissionStatus>;
  /** The mission time slot. */
  timeSlot: Scalars['Int'];
  /** The mission uuid. */
  uuid: Scalars['Uuid'];
};

/** The advertisement user application cancellation reason enum. */
export enum MissionCancelReasonEnum {
  /** Some conditions did not suit me (on-call / travel...) */
  BadConditions = 'BAD_CONDITIONS',
  /** Facilities / equipment do not meet my expectations */
  BadEquipment = 'BAD_EQUIPMENT',
  /** I don't want to work in this establishment */
  BadEstablishment = 'BAD_ESTABLISHMENT',
  /** Assignment / project is not suitable */
  BadMission = 'BAD_MISSION',
  /** Others */
  Custom = 'CUSTOM',
  /** Manual error, misunderstanding... */
  Error = 'ERROR',
  /** Location / travel time too high */
  Localisation = 'LOCALISATION',
  /** Misunderstanding: the proposed position does not correspond at all to my research */
  Misunderstanding = 'MISUNDERSTANDING',
  /** I don't have enough information */
  NotEnoughInformation = 'NOT_ENOUGH_INFORMATION',
  /** I found elsewhere (other than on Medelse) */
  OtherPlace = 'OTHER_PLACE',
  /** I found elsewhere (thanks to Medelse) */
  OtherPlaceMedelse = 'OTHER_PLACE_MEDELSE',
  /** Rhythm or working days are not suitable */
  Rhythm = 'RHYTHM',
  /** Salary negotiation did not succeed */
  Salary = 'SALARY',
  /** Team too small / load too heavy */
  TooMuchWork = 'TOO_MUCH_WORK',
  /** I am unavailable on this date */
  UnavailableDate = 'UNAVAILABLE_DATE',
  /** I am unavailable at these times */
  UnavailableHour = 'UNAVAILABLE_HOUR',
  /** Establishment unreachable for too long / seems unreliable */
  Unreachable = 'UNREACHABLE'
}

/** The recruitment cancellation reason enum. */
export enum MissionCancelRequestReasonEnum {
  /** Amenities: transport problems, housing... */
  Amenities = 'AMENITIES',
  /** Contract terms */
  ContractualTerms = 'CONTRACTUAL_TERMS',
  /** Other reasons */
  Custom = 'CUSTOM',
  /** I don't have enough information */
  NotEnoughInformation = 'NOT_ENOUGH_INFORMATION',
  /** I found elsewhere (other than on Medelse) */
  OtherPlace = 'OTHER_PLACE',
  /** Personal: health, children, appointments... */
  Personal = 'PERSONAL',
  /** Professional: replacement, planning.... */
  Professional = 'PROFESSIONAL',
  /** I am unavailable on this date */
  UnavailableDate = 'UNAVAILABLE_DATE',
  /** I am unavailable at these times */
  UnavailableHour = 'UNAVAILABLE_HOUR',
  /** Working conditions: hardship, confirmation times... */
  WorkingConditions = 'WORKING_CONDITIONS'
}

/** A connection to a list of items. */
export type MissionConnection = {
  __typename?: 'MissionConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<MissionEdge>>>;
  /** The group office's missions. */
  groupOffices?: Maybe<Array<Maybe<GroupOffice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The missions total number. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type MissionConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};

/** An edge in a connection. */
export type MissionEdge = {
  __typename?: 'MissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Mission>;
};

/** The mission object. */
export type MissionFilter = {
  __typename?: 'MissionFilter';
  /** The group offices who's match with filters. */
  groupOffices?: Maybe<Array<GroupOffice>>;
};

/** Mission status */
export type MissionInitiator = {
  __typename?: 'MissionInitiator';
  /** The mission initiator first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The mission initiator gender. */
  gender?: Maybe<Scalars['String']>;
  /** The mission initiator last name. */
  lastName?: Maybe<Scalars['String']>;
};

/** The initiator on mission actions. */
export enum MissionInitiatorEnum {
  /** The initiator is admin */
  InitiatorAdmin = 'INITIATOR_ADMIN',
  /** The initiator is auto */
  InitiatorAuto = 'INITIATOR_AUTO',
  /** The initiator is group office */
  InitiatorOffice = 'INITIATOR_OFFICE',
  /** The initiator is candidate */
  InitiatorPro = 'INITIATOR_PRO'
}

/** The mission macro status types enum. */
export enum MissionMacroStatusEnum {
  Accepted = 'ACCEPTED',
  CancellationRequested = 'CANCELLATION_REQUESTED',
  CancellationRequestedByCandidate = 'CANCELLATION_REQUESTED_BY_CANDIDATE',
  CancellationRequestedByRecruiter = 'CANCELLATION_REQUESTED_BY_RECRUITER',
  CancelledByAdmin = 'CANCELLED_BY_ADMIN',
  CancelledByCandidate = 'CANCELLED_BY_CANDIDATE',
  CancelledByRecruiter = 'CANCELLED_BY_RECRUITER',
  ContractualizedFullySignedInProgress = 'CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS',
  ContractualizedFullySignedUpcoming = 'CONTRACTUALIZED_FULLY_SIGNED_UPCOMING',
  ContractualizedPending = 'CONTRACTUALIZED_PENDING',
  ContractualizedSignedByCandidate = 'CONTRACTUALIZED_SIGNED_BY_CANDIDATE',
  ContractualizedSignedByRecruiter = 'CONTRACTUALIZED_SIGNED_BY_RECRUITER',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Invoiceable = 'INVOICEABLE',
  Invoiced = 'INVOICED',
  NotInvoiceable = 'NOT_INVOICEABLE',
  Proposed = 'PROPOSED',
  Refused = 'REFUSED',
  ValidationInvalidatedByBoth = 'VALIDATION_INVALIDATED_BY_BOTH',
  ValidationInvalidatedByCandidate = 'VALIDATION_INVALIDATED_BY_CANDIDATE',
  ValidationInvalidatedByCandidateValidatedByRecruiter = 'VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER',
  ValidationInvalidatedByRecruiter = 'VALIDATION_INVALIDATED_BY_RECRUITER',
  ValidationPending = 'VALIDATION_PENDING',
  ValidationValidatedByCandidate = 'VALIDATION_VALIDATED_BY_CANDIDATE',
  ValidationValidatedByCandidateInvalidatedByRecruiter = 'VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER',
  ValidationValidatedByRecruiter = 'VALIDATION_VALIDATED_BY_RECRUITER',
  WaitingForContract = 'WAITING_FOR_CONTRACT'
}

/** The invoice field to order by. */
export enum MissionOrderFieldEnum {
  /** Order by group office name */
  GroupOfficeName = 'GROUP_OFFICE_NAME',
  /** Order by salary field */
  Salary = 'SALARY',
  /** Order by start date field */
  StartDate = 'START_DATE'
}

/** Ordering options for mission connections */
export type MissionOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order mission by. */
  field: MissionOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** Mission rating items. */
export enum MissionRatingEnum {
  /** Adapted equipment */
  Equipment = 'EQUIPMENT',
  /** Reception and orientation quality */
  Reception = 'RECEPTION',
  /** Welcoming and professional team */
  Team = 'TEAM',
  /** Efficient transmissions */
  Transmissions = 'TRANSMISSIONS',
  /** Accessibility and transport */
  Transport = 'TRANSPORT'
}

/** Mission rating input */
export type MissionRatingInput = {
  /** The mission rating enum. */
  name: MissionRatingEnum;
  /** Value true | false or null. */
  value?: InputMaybe<Scalars['Boolean']>;
};

/** Mission refusal */
export type MissionRefusal = {
  __typename?: 'MissionRefusal';
  /** The mission cancelled user. */
  cancelledBy?: Maybe<Scalars['String']>;
  /** The mission refusal reason. */
  reason?: Maybe<Scalars['String']>;
  /** The mission refusal reason custom. */
  reasonCustom?: Maybe<Scalars['String']>;
};

/** The missions refusal reason enum. */
export enum MissionRefusalReasonEnum {
  /** Candidate alreay work for establishment */
  AlreadyWorkedFor = 'ALREADY_WORKED_FOR',
  /** Mission have a bad location */
  BadLocation = 'BAD_LOCATION',
  /** Mission have a bad replacement type */
  BadReplacementType = 'BAD_REPLACEMENT_TYPE',
  /** Mission have a salary that doesn't match with candidate salary */
  BadSalary = 'BAD_SALARY',
  /** Mission have incomplete advertisement */
  IncompleteAdvertisement = 'INCOMPLETE_ADVERTISEMENT',
  /** Candidate is not available for the announcement dates */
  NotAvailable = 'NOT_AVAILABLE',
  /** Other reason */
  Other = 'OTHER'
}

/** The missions report reason enum. */
export enum MissionReportReasonEnum {
  /** Mission done with other time information (strat / end / break...) */
  BadTime = 'BAD_TIME',
  /** Mission not done */
  NotDone = 'NOT_DONE',
  /** Mission report for other reason */
  Other = 'OTHER'
}

/** Current mission state compared to the current Datetime. */
export enum MissionStateEnum {
  /** Mission is in progress. Start date less than now and end date greater then now */
  InProgress = 'IN_PROGRESS',
  /** Mission is passed. End date less then now. */
  Passed = 'PASSED',
  /** Mission is upcoming. Start date greater than now. */
  Upcoming = 'UPCOMING'
}

/** Mission status */
export type MissionStatus = {
  __typename?: 'MissionStatus';
  /** The mission status asked date. */
  askedAt?: Maybe<Scalars['DateTime']>;
  /** The mission status created date. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The mission initiator. */
  initiator?: Maybe<MissionInitiator>;
  /** The mission initiator. */
  initiatorType: MissionInitiatorEnum;
  /** The mission refusal. */
  refusal?: Maybe<MissionRefusal>;
  /** The mission status. */
  status: MissionStatusEnum;
};

/** The mission status types enum. */
export enum MissionStatusEnum {
  /** Mission accepted by candidate */
  Accepted = 'ACCEPTED',
  /** Mission is in request for cancellation */
  CancellationRequested = 'CANCELLATION_REQUESTED',
  /** Mission is cancelled */
  Cancelled = 'CANCELLED',
  /** Mission is contractualized */
  Contractualized = 'CONTRACTUALIZED',
  /** Mission is deleted */
  Deleted = 'DELETED',
  /** Mission is expired */
  Expired = 'EXPIRED',
  /** Mission is invoiced */
  Invoiced = 'INVOICED',
  /** Mission proposed to candidate */
  Proposed = 'PROPOSED',
  /** Mission is refused */
  Refused = 'REFUSED',
  /** Mission is pending contract */
  WaitingForContract = 'WAITING_FOR_CONTRACT'
}

/** Fetches an object given its ID */
export type NodeInterface = {
  /** The ID of an object */
  id: Scalars['ID'];
};

/** Possible directions in which to order a list of items when provided an orderBy argument. */
export enum OrderDirectionEnum {
  /** Specifies an ascending order for a given orderBy argument */
  Asc = 'ASC',
  /** Specifies a descending order for a given orderBy argument */
  Desc = 'DESC'
}

/** The package status. */
export enum PackageStatus {
  /** Signing process is canceled */
  Canceled = 'CANCELED',
  /** Fully signed */
  FullySigned = 'FULLY_SIGNED',
  /** Pending signing */
  Pending = 'PENDING',
  /** Pending signing approval */
  PendingApproval = 'PENDING_APPROVAL',
  /** Pending signature */
  PendingSignature = 'PENDING_SIGNATURE',
  /** Package rejected */
  Rejected = 'REJECTED',
  /** Package revoked */
  Revoked = 'REVOKED'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** The payment details. */
export type Payment = {
  __typename?: 'Payment';
  /** The payment bill date. */
  billDate?: Maybe<Scalars['DateTime']>;
  /** The payment due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The payment end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The payment pending date. */
  pendingDate?: Maybe<Scalars['DateTime']>;
  /** The payment status. */
  status?: Maybe<PaymentStatus>;
};

/** The package status. */
export enum PaymentStatus {
  /** Blocked payment */
  Blocked = 'BLOCKED',
  /** Canceled payment */
  Canceled = 'CANCELED',
  /** Failed payment */
  Failed = 'FAILED',
  /** Late payment */
  Late = 'LATE',
  /** Paid payment */
  Paid = 'PAID',
  /** Pending payment */
  Pending = 'PENDING',
  /** Processing payment */
  Processing = 'PROCESSING',
  /** Refused payment */
  Refused = 'REFUSED',
  /** Sended payment */
  Sent = 'SENT'
}

/** The phone object. */
export type Phone = {
  __typename?: 'Phone';
  /** The phone country code. */
  code?: Maybe<Scalars['Int']>;
  /** The phone number. */
  number?: Maybe<Scalars['String']>;
};

/** Set the user profile. */
export type PhoneInput = {
  /** The phone country code. */
  code?: InputMaybe<Scalars['Int']>;
  /** The phone number. */
  number?: InputMaybe<Scalars['String']>;
};

/** UserHasPlanning availability. */
export enum PlanningAvailabilityEnum {
  /** User is available */
  Available = 'AVAILABLE',
  /** User is unavailable because he is already committed with Medelse */
  MedelseMission = 'MEDELSE_MISSION',
  /** User is unavailable */
  Unavailable = 'UNAVAILABLE'
}

/** The planning day object. */
export type PlanningDay = {
  __typename?: 'PlanningDay';
  /**
   * The day availability.
   * @deprecated availabilityDay is now an enum: availabilityDayEnum.
   */
  availabilityDay: Scalars['Boolean'];
  /** The day availability enum. */
  availabilityDayEnum: PlanningAvailabilityEnum;
  /**
   * The night availability.
   * @deprecated availabilityNight is now an enum: availabilityNightEnum.
   */
  availabilityNight: Scalars['Boolean'];
  /** The night availability enum. */
  availabilityNightEnum: PlanningAvailabilityEnum;
  /** The date day. */
  date: Scalars['Date'];
};

/** A candidate planning day input. */
export type PlanningDayInput = {
  /** The day availability. */
  availabilityDay: Scalars['Boolean'];
  /** The night availability. */
  availabilityNight: Scalars['Boolean'];
  /** Planning day date */
  date: Scalars['Date'];
};

/** A candidate user information. */
export type ProInformation = {
  __typename?: 'ProInformation';
  /** Is the user is beta short. */
  betaShort?: Maybe<Scalars['Boolean']>;
  /** The first replacement type. */
  canExercise?: Maybe<UserCanExerciseEnum>;
  /** The user council order number. */
  councilOrderNumber?: Maybe<Scalars['String']>;
  /** Is the user is subscribed on council order. */
  councilRegistration?: Maybe<Scalars['Boolean']>;
  /** Is the user is global unavailability. */
  globalUnavailability?: Maybe<Scalars['Boolean']>;
  /** Is the user liberal. */
  isLiberal?: Maybe<Scalars['Boolean']>;
  /** The french shared doctor repository index. */
  rppsNumber?: Maybe<Scalars['String']>;
  /** The user SIRET number. */
  siret?: Maybe<Scalars['String']>;
  /** The french urssaf code. */
  urssaf?: Maybe<Scalars['String']>;
};

/** The public information object */
export type PublicInformation = {
  __typename?: 'PublicInformation';
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
};

/** A recruiter. */
export type Recruiter = NodeInterface & {
  __typename?: 'Recruiter';
  /** A paginated list of announcements. */
  Announcements?: Maybe<AnnouncementShortConnection>;
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** The recruiter roles. */
  recruiterGroupOffices?: Maybe<Array<Maybe<RecruiterGroupOffice>>>;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A recruiter. */
export type RecruiterAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<AdvertisementStatusEnum>>;
};


/** A recruiter. */
export type RecruiterContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A recruiter. */
export type RecruiterInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A recruiter. */
export type RecruiterMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A recruiter. */
export type RecruiterMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A recruiter. */
export type RecruiterPlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A recruiter. */
export type RecruiterStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** A recruiter group office association. */
export type RecruiterGroupOffice = {
  __typename?: 'RecruiterGroupOffice';
  /** The recruiter group office. */
  groupOffice: GroupOffice;
  /** The recruiter roles for the group office. */
  roles?: Maybe<Array<Maybe<RecruiterRoleEnum>>>;
};

/** The recruiter role enum. */
export enum RecruiterRoleEnum {
  /** Accountant role */
  Accountant = 'ACCOUNTANT',
  /** Administrator role */
  Administrator = 'ADMINISTRATOR',
  /** Human resource role */
  HumanResource = 'HUMAN_RESOURCE'
}

export type ReplacementType = {
  __typename?: 'ReplacementType';
  /** The replacement type id. */
  id: Scalars['ID'];
  /** Is replacement active. */
  isActive: Scalars['Boolean'];
  /** The replacement type name. */
  name: Scalars['String'];
  /** The replacement display order. */
  order: Scalars['Int'];
  /** The replacement type. */
  type?: Maybe<ReplacementTypeEnum>;
};

/** The replacement type. */
export enum ReplacementTypeEnum {
  /** CDD replacement types. */
  Cdd = 'CDD',
  /** Short replacement types. */
  Session = 'SESSION'
}

/** The rhythm type. */
export enum RhythmEnum {
  /** Day rhythm. */
  Day = 'DAY',
  /** Night rhythm. */
  Night = 'NIGHT'
}

/** The salary object. */
export type Salary = {
  __typename?: 'Salary';
  /** The salary amount. */
  amount?: Maybe<Amount>;
  /** The salary type. */
  type: SalaryTypeEnum;
};

/** Salary types. */
export enum SalaryTypeEnum {
  /** The gross annual salary. */
  GrossAnnual = 'GROSS_ANNUAL',
  /** The gross daily salary. */
  GrossDaily = 'GROSS_DAILY',
  /** The gross monthly salary. */
  GrossMonthly = 'GROSS_MONTHLY',
  /** The retrocession salary. */
  GrossRetrocession = 'GROSS_RETROCESSION',
  /** The net annual salary. */
  NetAnnual = 'NET_ANNUAL',
  /** The net daily salary. */
  NetDaily = 'NET_DAILY',
  /** The net monthly salary. */
  NetMonthly = 'NET_MONTHLY',
  /** The net retrocession salary. */
  NetRetrocession = 'NET_RETROCESSION',
  /** The profile salary. */
  Profile = 'PROFILE',
  /** The no sector salary. */
  SectorNone = 'SECTOR_NONE',
  /** The sector one salary. */
  SectorOne = 'SECTOR_ONE',
  /** The sector one or sector two salary. */
  SectorOneOrTwo = 'SECTOR_ONE_OR_TWO',
  /** The sector three salary. */
  SectorThree = 'SECTOR_THREE',
  /** The sector two salary. */
  SectorTwo = 'SECTOR_TWO'
}

/** DEPRECATED - Advertisement user applications */
export type SearchCriteria = {
  __typename?: 'SearchCriteria';
  /** The establishment types criteria. */
  establishmentTypes?: Maybe<Array<AvailabilityEstablishmentTypeEnum>>;
  /** The location range criteria. */
  locationRanges?: Maybe<Array<CandidateLocation>>;
  /** The services criteria. */
  services?: Maybe<Array<Service>>;
  /** The specialties criteria. */
  specialties?: Maybe<Array<Specialty>>;
};

/** Service. */
export type Service = {
  __typename?: 'Service';
  /** The service id. */
  id: Scalars['ID'];
  /** Is the service active ? */
  isActive: Scalars['Boolean'];
  /** The service name. */
  name: Scalars['String'];
};

/** A connection to a list of items. */
export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of services. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename?: 'ServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Service>;
};

/** The signing status. */
export enum SigningStatus {
  /** Not signed status. */
  NotSigned = 'NOT_SIGNED',
  /** Signed status. */
  Signed = 'SIGNED'
}

export type Skill = NodeInterface & {
  __typename?: 'Skill';
  /** The skill id. */
  id: Scalars['ID'];
  /** The skill name. */
  name: Scalars['String'];
};

/** A connection to a list of items. */
export type SkillConnection = {
  __typename?: 'SkillConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SkillEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of skills. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SkillEdge = {
  __typename?: 'SkillEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Skill>;
};

export type Specialty = NodeInterface & {
  __typename?: 'Specialty';
  /** The node id. A base 64 encode of 'Specialty:$id'. */
  globalId: Scalars['ID'];
  /** The specialty id. */
  id: Scalars['ID'];
  /** Defined if the specialty is active. */
  isActive: Scalars['Boolean'];
  /** Defined if the specialty is active for register. */
  isActiveRegister: Scalars['Boolean'];
  /** The specialty name. */
  name: Scalars['String'];
  /** The rank to be ordered. */
  order: Scalars['Int'];
  /** The specialty slug used in url. */
  slug: Scalars['String'];
};

/** A connection to a list of items. */
export type SpecialtyConnection = {
  __typename?: 'SpecialtyConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SpecialtyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of specialties. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SpecialtyEdge = {
  __typename?: 'SpecialtyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Specialty>;
};

export type SpecificService = NodeInterface & {
  __typename?: 'SpecificService';
  /** Is the specific service enabled ? */
  enabled: Scalars['Boolean'];
  /** The specific service id. */
  id: Scalars['ID'];
  /** The specific service name. */
  name: Scalars['String'];
};

/** A Spreadsheet file stored in Google Cloud. */
export type SpreadSheetFile = {
  __typename?: 'SpreadSheetFile';
  /** Public spreadsheet''s url. */
  url?: Maybe<Scalars['String']>;
};

/** Dto''s attributes to export. */
export enum SpreadsheetExportableFields {
  Cancelled = 'CANCELLED',
  ContractDates = 'CONTRACT_DATES',
  ContractNumber = 'CONTRACT_NUMBER',
  Dates = 'DATES',
  FullName = 'FULL_NAME',
  Hours = 'HOURS',
  InvoiceNumber = 'INVOICE_NUMBER',
  NbrNeeds = 'NBR_NEEDS',
  PaymentDate = 'PAYMENT_DATE',
  PendingDate = 'PENDING_DATE',
  Reported = 'REPORTED',
  Rhythm = 'RHYTHM',
  Salary = 'SALARY',
  Sector = 'SECTOR',
  Service = 'SERVICE',
  SignedAt = 'SIGNED_AT',
  Speciality = 'SPECIALITY',
  SpecificService = 'SPECIFIC_SERVICE',
  Status = 'STATUS',
  Validated = 'VALIDATED'
}

/** Candidate suspension information */
export type Suspension = {
  __typename?: 'Suspension';
  /** Period of dates for which the user is suspended */
  dates: DateTimeRange;
  /** The reason for which the candidate is suspended */
  reason?: Maybe<SuspensionReasonEnum>;
};

/** Suspensions reason */
export enum SuspensionReasonEnum {
  /** Bad cancellation rate, 7 day suspension */
  BadCancellationRatio = 'BAD_CANCELLATION_RATIO',
  /** Indefinite suspension */
  Definitive = 'DEFINITIVE',
  /** Serious cancellation rate, 14 day suspension */
  SeriousCancellationRatio = 'SERIOUS_CANCELLATION_RATIO'
}

/** Range between the minimum value and maximum value need to obtain a tag */
export type TagRange = {
  __typename?: 'TagRange';
  /** Max */
  max?: Maybe<Scalars['Int']>;
  /** Min */
  min?: Maybe<Scalars['Int']>;
};

/** A candidate typical day. */
export type TypicalDayInput = {
  /** The day availability. */
  availabilityDay: Scalars['Boolean'];
  /** The night availability. */
  availabilityNight: Scalars['Boolean'];
  /** Week day */
  day: DayEnum;
};

/** Set the update email parameters. */
export type UpdateEmailInput = {
  /** The new email. */
  email: Scalars['String'];
  /** The new email confirmation. */
  emailConfirmation: Scalars['String'];
};

/** The update email mutation payload. */
export type UpdateEmailPayload = {
  __typename?: 'UpdateEmailPayload';
  /** The email. */
  email: Scalars['String'];
  /** The user whose link email. */
  user: User;
};

/** Set the update password parameters. */
export type UpdatePasswordInput = {
  /** The new candidate password. */
  newPassword: Scalars['String'];
  /** The old candidate password. */
  oldPassword: Scalars['String'];
  /** The confirmation of new candidate password. */
  passwordConfirmation: Scalars['String'];
};

/** The update password mutation payload. */
export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  /** The user whose link password. */
  user: User;
};

/** Ordering options for UserDocument connections */
export type UploadDocumentFieldInput = {
  /** The type of document uploaded. */
  document: Scalars['Int'];
  /** The file uploaded. */
  file: Scalars['UploadType'];
};

/** A user entity. */
export type User = NodeInterface & {
  __typename?: 'User';
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A user entity. */
export type UserContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A user entity. */
export type UserInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A user entity. */
export type UserMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A user entity. */
export type UserMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A user entity. */
export type UserPlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A user entity. */
export type UserStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** Salary types. */
export enum UserCanExerciseEnum {
  /** No, in study or PAE in progress. */
  No = 'NO',
  /** Valid replacement license. */
  Pending = 'PENDING',
  /** Order number registered or in progress. */
  Yes = 'YES'
}

/** A user document entity. */
export type UserDocument = {
  __typename?: 'UserDocument';
  /** Set when the user document is uploaded. */
  createdAt: Scalars['DateTime'];
  /** Set when the user document is deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The type of document. */
  document: Document;
  /** The user document id. */
  id: Scalars['ID'];
  /** The user document file mime type. */
  mimeType: Scalars['String'];
  /** The user document file name. */
  name: Scalars['String'];
  /** The user owns the document. */
  user: Candidate;
  /** The user document uuid. */
  uuid: Scalars['Uuid'];
};

/** The document mutation payload. */
export type UserDocumentPayload = {
  __typename?: 'UserDocumentPayload';
  /** The user document object. */
  userDocument: UserDocument;
};

/** The document deletion mutation payload. */
export type UserDocumentRemovedPayload = {
  __typename?: 'UserDocumentRemovedPayload';
  /** True if document has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose document was deleted. */
  user: Candidate;
};

/** The user files. */
export type UserFiles = {
  __typename?: 'UserFiles';
  /** The cv file name. */
  cv?: Maybe<File>;
  /** The image file. */
  image?: Maybe<ImageFile>;
};

/** The user profile. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** The user birth day date. */
  birthDay?: Maybe<Scalars['Date']>;
  /** The user description. */
  description?: Maybe<Scalars['String']>;
  /** The user email. Used for login. */
  email: Scalars['String'];
  /** The user first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user gender. */
  gender: GenderEnum;
  /** The user last name. */
  lastName: Scalars['String'];
  /** The user location object. */
  location?: Maybe<Location>;
  /** Set when the user has updated his name. */
  nameUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The user password expire date. */
  passwordExpiredAt?: Maybe<Scalars['Date']>;
  /** The user phone number and code. */
  phone: Phone;
  /** Does the user have to validate the cgu ? */
  userNeedCguValidation: Scalars['Boolean'];
};

/** Set the user profile. */
export type UserProfileInput = {
  /** DEPRECATED (Will be removed) - The user birth date. */
  birthDay?: InputMaybe<Scalars['Date']>;
  /** DEPRECATED (bad typo) The user can exercice ou not */
  canExercice?: InputMaybe<UserCanExerciseEnum>;
  /** The user can exercice ou not */
  canExercise?: InputMaybe<UserCanExerciseEnum>;
  /** DEPRECATED (Will be removed) - The user description. */
  description?: InputMaybe<Scalars['String']>;
  /** The user first name. */
  firstName: Scalars['String'];
  /** The user gender. */
  gender: GenderEnum;
  /** An iban to validate. */
  iban?: InputMaybe<Scalars['String']>;
  /** The user is a liberal */
  isLiberal?: InputMaybe<Scalars['Boolean']>;
  /** The user last name. */
  lastName: Scalars['String'];
  /** Location of user */
  localisation?: InputMaybe<LocalisationInput>;
  /** The user phone number and code. */
  phone: PhoneInput;
  /** A valide rpps number. */
  rppsNumber?: InputMaybe<Scalars['String']>;
  /** A valide siret number. */
  siret?: InputMaybe<Scalars['String']>;
  /** Specialities */
  specialities?: InputMaybe<Array<Scalars['ID']>>;
};

/** User statistic group by type. */
export enum UserStatisticGroupByEnum {
  /** Group user statistics by month. */
  Month = 'MONTH',
  /** Group user statistics by year. */
  Year = 'YEAR'
}

/** The user statistic entry. */
export type UserStatisticPeriod = {
  __typename?: 'UserStatisticPeriod';
  /** The period's dates number. */
  nbDates: Scalars['Int'];
  /** The analysis period. */
  period: Scalars['Date'];
  /** The period's total net salaries. */
  totalNetSalaries: Scalars['Float'];
  /** The period's total salaries. */
  totalSalaries: Scalars['Float'];
};

/** List of user status. */
export enum UserStatusEnum {
  /** User is in the public network. */
  CandidateAccess = 'CANDIDATE_ACCESS',
  /** User is in the public network, but not in active research. */
  CandidateDiscrete = 'CANDIDATE_DISCRETE',
  /** User is in the public network, but not visible by recruiters. */
  CandidateInvisible = 'CANDIDATE_INVISIBLE',
  /** User is in the talent network. */
  CandidateTalent = 'CANDIDATE_TALENT'
}

/** User tags */
export enum UserTagEnum {
  /** Feedback addict */
  CommunityDriven = 'COMMUNITY_DRIVEN',
  /** Product driven user */
  Contributor = 'CONTRIBUTOR',
  /** Experienced user level 1 */
  Experienced_1 = 'EXPERIENCED_1',
  /** Experienced user level 2 */
  Experienced_2 = 'EXPERIENCED_2',
  /** Experienced user level 3 */
  Experienced_3 = 'EXPERIENCED_3',
  /** Experienced user level 4 */
  Experienced_4 = 'EXPERIENCED_4',
  /** Experienced user level 5 */
  Experienced_5 = 'EXPERIENCED_5',
  /** User with Advantage */
  Privileged = 'PRIVILEGED',
  /** Fast contract signer */
  Reactive = 'REACTIVE',
  /** At least 5 other tags */
  SuperCaregiver = 'SUPER_CAREGIVER',
  /** Often connected */
  Technophile = 'TECHNOPHILE',
  /** Mobile user */
  Traveler = 'TRAVELER',
  /** Multi service user */
  Versatile = 'VERSATILE'
}

/** The validation data object */
export type Validation = {
  __typename?: 'Validation';
  /** The validation by admin boolean */
  validationByAdmin?: Maybe<Scalars['Boolean']>;
  /** The validation date */
  validationDate?: Maybe<Scalars['DateTime']>;
  /** The validation initiator */
  validationInitiator?: Maybe<Initiator>;
  /** The validation status */
  validationStatus?: Maybe<ValidationStatusEnum>;
};

/** The validation status. */
export enum ValidationStatusEnum {
  /** Object is invalidated. */
  Invalid = 'INVALID',
  /** Object is validated. */
  Valid = 'VALID'
}

/** The working time object. */
export type WorkingTime = {
  __typename?: 'WorkingTime';
  /** The break time duration in minutes. */
  break: Scalars['Int'];
  /** The working time duration in minutes. */
  duration: Scalars['Int'];
  /** The start hour time. */
  time: Scalars['Time'];
};

/** The candidate mutation object. */
export type CandidateMutation = {
  __typename?: 'candidateMutation';
  /** Accept CGU. */
  acceptCGU: Candidate;
  /** Accept missions. */
  acceptMissions: CandidateAcceptMissionsPayload;
  /** Add cloud message firebase token. */
  addFirebaseCloudMessageToken: CandidateAddFirebaseCloudMessageTokenPayload;
  addLocation: CandidateLocationPayload;
  /** Add a planning slot for the user. */
  addPlanningSlot: CandidateAddPlanningSlotPayload;
  /** Link skill on user. */
  addUserSkill: CandidateSkillPayload;
  /** Link specialty on user. */
  addUserSpecialty: CandidateSpecialtyPayload;
  /** Approve missions. */
  approveMissions: CandidateMissionsPayload;
  /** Cancel missions. */
  cancelMissions: CandidateCancelMissionsPayload;
  /** Cancel request missions. */
  cancelRequestMissions: CandidateCancelRequestMissionsPayload;
  /** Create skill and auto link on user. */
  createSkill: CandidateSkillPayload;
  /** Delete candidate account. */
  deleteCandidateAccount: Candidate;
  /** Rate missions. */
  rateMissions: CandidateMissionsPayload;
  /** Read missions. */
  readMissions: CandidateReadMissionPayload;
  /** Refuse missions. */
  refuseMissions: CandidateRefuseMissionsPayload;
  removeCV: CandidateCvRemovedPayload;
  removeDocument: UserDocumentRemovedPayload;
  removeImage: ImageRemovedPayload;
  removeLocation: CandidateLocationRemovePayload;
  /** Remove link skill on user. If never used, remove skill. */
  removeUserSkill: CandidateSkillRemovedPayload;
  /** Remove link specialty on user. */
  removeUserSpecialty: CandidateSpecialtyRemovedPayload;
  /** Report missions. */
  reportMissions: CandidateMissionsPayload;
  /** Update candidate password. */
  updateCandidatePassword: UpdatePasswordPayload;
  /** Update candidate profile. */
  updateCandidateProfile: Candidate;
  /** Update candidate email. */
  updateEmail: UpdateEmailPayload;
  /** Update establishmentTypes. */
  updateEstablishmentTypes: CandidatePayload;
  /** Update global unavailability. */
  updateGlobalUnavailability: CandidateUpdateGlobalUnavailabilityPayload;
  /** Update candidate Iban. */
  updateIban: Candidate;
  /** Update candidate isLiberal state. */
  updateIsLiberal: Candidate;
  /** Update candidate news letter subscription. */
  updateNewsLetterSubscription: CandidateParametersUpdateSubscriptionPayload;
  /** Update candidate password. */
  updatePassword: UpdatePasswordPayload;
  /** Update candidate planning days. */
  updatePlanningDays: CandidateUpdatePlanningDaysPayload;
  /** Update candidate pro information. */
  updateProInformation: Candidate;
  /** Update salary. */
  updateSalary: CandidatePayload;
  /** DEPRECATED - Update search criterias. */
  updateSearchCriteria: CandidateUpdateSearchCriteriaPayload;
  /** Update establishmentTypes. */
  updateServices: CandidatePayload;
  uploadCV: CandidateCvPayload;
  uploadDocument: UserDocumentPayload;
  uploadImage: ImagePayload;
  uploadNic: CandidateNicPayload;
};


/** The candidate mutation object. */
export type CandidateMutationAcceptMissionsArgs = {
  input: Array<CandidateAcceptMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationAddFirebaseCloudMessageTokenArgs = {
  input: CandidateAddFirebaseCloudMessageTokenInput;
};


/** The candidate mutation object. */
export type CandidateMutationAddLocationArgs = {
  input: CandidateLocationInput;
};


/** The candidate mutation object. */
export type CandidateMutationAddPlanningSlotArgs = {
  input: CandidateAddPlanningSlotInput;
};


/** The candidate mutation object. */
export type CandidateMutationAddUserSkillArgs = {
  id: Scalars['ID'];
};


/** The candidate mutation object. */
export type CandidateMutationAddUserSpecialtyArgs = {
  id: Scalars['ID'];
};


/** The candidate mutation object. */
export type CandidateMutationApproveMissionsArgs = {
  input: Array<CandidateApproveMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationCancelMissionsArgs = {
  input: Array<CandidateCancelMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationCancelRequestMissionsArgs = {
  input: Array<CandidateCancelRequestMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationCreateSkillArgs = {
  name: Scalars['String'];
};


/** The candidate mutation object. */
export type CandidateMutationRateMissionsArgs = {
  input: Array<CandidateRateMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationReadMissionsArgs = {
  input: CandidateReadMissionInput;
};


/** The candidate mutation object. */
export type CandidateMutationRefuseMissionsArgs = {
  input: Array<CandidateRefuseMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationRemoveCvArgs = {
  file: Scalars['String'];
};


/** The candidate mutation object. */
export type CandidateMutationRemoveDocumentArgs = {
  file: Scalars['Uuid'];
};


/** The candidate mutation object. */
export type CandidateMutationRemoveImageArgs = {
  file: Scalars['String'];
};


/** The candidate mutation object. */
export type CandidateMutationRemoveLocationArgs = {
  location: Scalars['Uuid'];
};


/** The candidate mutation object. */
export type CandidateMutationRemoveUserSkillArgs = {
  id: Scalars['ID'];
};


/** The candidate mutation object. */
export type CandidateMutationRemoveUserSpecialtyArgs = {
  id: Scalars['ID'];
};


/** The candidate mutation object. */
export type CandidateMutationReportMissionsArgs = {
  input: Array<CandidateReportMissionInput>;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateCandidatePasswordArgs = {
  input: UpdatePasswordInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateCandidateProfileArgs = {
  input: UserProfileInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateEstablishmentTypesArgs = {
  establishmentTypes: Array<EstablishmentTypeEnum>;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateGlobalUnavailabilityArgs = {
  input: CandidateUpdateGlobalUnavailabilityInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateIbanArgs = {
  iban: Scalars['String'];
};


/** The candidate mutation object. */
export type CandidateMutationUpdateIsLiberalArgs = {
  isLiberal: Scalars['Boolean'];
};


/** The candidate mutation object. */
export type CandidateMutationUpdateNewsLetterSubscriptionArgs = {
  input: CandidateUpdateSubscriptionInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdatePlanningDaysArgs = {
  input: CandidateUpdatePlanningDaysInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateProInformationArgs = {
  input: CandidateProInformationInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateSalaryArgs = {
  salary: Scalars['Int'];
};


/** The candidate mutation object. */
export type CandidateMutationUpdateSearchCriteriaArgs = {
  input: CandidateUpdateSearchCriteriaInput;
};


/** The candidate mutation object. */
export type CandidateMutationUpdateServicesArgs = {
  services?: InputMaybe<Array<Scalars['ID']>>;
};


/** The candidate mutation object. */
export type CandidateMutationUploadCvArgs = {
  file?: InputMaybe<Scalars['UploadType']>;
};


/** The candidate mutation object. */
export type CandidateMutationUploadDocumentArgs = {
  input: UploadDocumentFieldInput;
};


/** The candidate mutation object. */
export type CandidateMutationUploadImageArgs = {
  file?: InputMaybe<Scalars['UploadType']>;
};


/** The candidate mutation object. */
export type CandidateMutationUploadNicArgs = {
  file?: InputMaybe<Scalars['UploadType']>;
  side?: InputMaybe<ContractNicEnum>;
};

/** The available queries for document. */
export type CandidateQuery = {
  __typename?: 'candidateQuery';
  /** A paginated list of cities. */
  Cities?: Maybe<CityConnection>;
  /** The city getting by id or google place id. */
  City?: Maybe<City>;
  /** The contract getting by uuid. */
  Contract?: Maybe<Contract>;
  /** A paginated list of countries. */
  Countries?: Maybe<CountryConnection>;
  /** The country getting by id. */
  Country?: Maybe<Country>;
  /** Get the list of documents. */
  Documents: Array<Document>;
  /** The invoice getting by uuid. */
  Invoice?: Maybe<Invoice>;
  /** The medelse invoice getting by uuid. */
  MedelseFeesInvoice?: Maybe<MedelseFeesInvoice>;
  /** The mission getting by uuid. */
  Mission?: Maybe<Mission>;
  /** A paginated list of services. */
  Services?: Maybe<ServiceConnection>;
  /** The skill getting by id. */
  Skill: Skill;
  /** A paginated list of skills. */
  Skills: SkillConnection;
  /** A paginated list of specialties. */
  Specialties?: Maybe<SpecialtyConnection>;
  /** A specialty getting by id. */
  Specialty?: Maybe<Specialty>;
  /** An array of MissionRatingEnum based on latest Rating for other Mission in same Office. */
  latestMissionRatings: Array<MissionRatingEnum>;
  me?: Maybe<Candidate>;
};


/** The available queries for document. */
export type CandidateQueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The available queries for document. */
export type CandidateQueryCityArgs = {
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


/** The available queries for document. */
export type CandidateQueryContractArgs = {
  uuid: Scalars['Uuid'];
};


/** The available queries for document. */
export type CandidateQueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The available queries for document. */
export type CandidateQueryCountryArgs = {
  id: Scalars['ID'];
};


/** The available queries for document. */
export type CandidateQueryInvoiceArgs = {
  uuid: Scalars['Uuid'];
};


/** The available queries for document. */
export type CandidateQueryMedelseFeesInvoiceArgs = {
  uuid: Scalars['Uuid'];
};


/** The available queries for document. */
export type CandidateQueryMissionArgs = {
  uuid: Scalars['Uuid'];
};


/** The available queries for document. */
export type CandidateQueryServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The available queries for document. */
export type CandidateQuerySkillArgs = {
  id: Scalars['ID'];
};


/** The available queries for document. */
export type CandidateQuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The available queries for document. */
export type CandidateQuerySpecialtiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<LocaleEnum>;
  search?: InputMaybe<Scalars['String']>;
};


/** The available queries for document. */
export type CandidateQuerySpecialtyArgs = {
  id: Scalars['ID'];
};


/** The available queries for document. */
export type CandidateQueryLatestMissionRatingsArgs = {
  missionUuid: Scalars['Uuid'];
};

export type CheckAccountStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAccountStatusQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, hasProfileFilled: boolean, accountStatus?: CandidateAccountStatusEnum | null } | null };

export type GetUserFirstnameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFirstnameQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, profile: { __typename?: 'UserProfile', firstName?: string | null } } | null };

export type CheckAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckAuthQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any } | null };

export type AcceptCguMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptCguMutation = { __typename?: 'candidateMutation', acceptCGU: { __typename?: 'Candidate', uuid: any, profile: { __typename?: 'UserProfile', userNeedCguValidation: boolean } } };

export type GetCguStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCguStatusQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, profile: { __typename?: 'UserProfile', userNeedCguValidation: boolean } } | null };

export type GetContractsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractsQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, contracts?: { __typename?: 'ContractConnection', edges?: Array<{ __typename?: 'ContractEdge', node?: { __typename?: 'Contract', uuid: any, contractNumber: string, packageStatus?: PackageStatus | null, documentDownloadLink?: string | null, groupOffice?: { __typename?: 'GroupOffice', name: string, zipCode?: string | null } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null } } | null> | null } | null } | null> | null } | null } | null };

export type GetFeesInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeesInvoicesQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, medelseFeesInvoices?: { __typename?: 'MedelseFeesInvoiceConnection', edges?: Array<{ __typename?: 'MedelseFeesInvoiceEdge', node?: { __typename?: 'MedelseFeesInvoice', uuid: any, invoiceNumber?: string | null, totalAmount?: number | null, createdAt: any, documentDownloadLink?: string | null, groupOffice?: { __typename?: 'GroupOffice', name: string, zipCode?: string | null } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null } } | null> | null } | null } | null> | null } | null } | null };

export type GetInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, invoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', uuid: any, id: string, createdAt: any, totalAmount?: number | null, documentDownloadLink?: string | null, payment?: { __typename?: 'Payment', status?: PaymentStatus | null, pendingDate?: any | null } | null, groupOffice?: { __typename?: 'GroupOffice', name: string, zipCode?: string | null } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null } } | null> | null } | null } | null> | null } | null } | null };

export type DeleteCandidateAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteCandidateAccountMutation = { __typename?: 'candidateMutation', deleteCandidateAccount: { __typename?: 'Candidate', uuid: any } };

export type GetParametersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParametersQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, profile: { __typename?: 'UserProfile', email: string } } | null };

export type UpdateCandidateEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;


export type UpdateCandidateEmailMutation = { __typename?: 'candidateMutation', updateEmail: { __typename?: 'UpdateEmailPayload', user: { __typename?: 'User', uuid: any } } };

export type UpdateCandidatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdateCandidatePasswordMutation = { __typename?: 'candidateMutation', updateCandidatePassword: { __typename?: 'UpdatePasswordPayload', user: { __typename?: 'User', uuid: any } } };

export type GetCitiesQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetCitiesQuery = { __typename?: 'candidateQuery', Cities?: { __typename?: 'CityConnection', edges?: Array<{ __typename?: 'CityEdge', node?: { __typename?: 'City', id: string, name?: string | null } | null } | null> | null } | null };

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = { __typename?: 'candidateQuery', me?: { __typename?: 'Candidate', uuid: any, accountStatus?: CandidateAccountStatusEnum | null, hasProfileFilled: boolean, iban?: string | null, ibanUpdatedAt?: any | null, profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string, phone: { __typename?: 'Phone', code?: number | null, number?: string | null }, location?: { __typename?: 'Location', zipCode?: string | null, address?: string | null, city?: { __typename?: 'City', id: string, name?: string | null } | null } | null }, specialties: Array<{ __typename?: 'Specialty', id: string, name: string }>, proInformation: { __typename?: 'ProInformation', isLiberal?: boolean | null, canExercise?: UserCanExerciseEnum | null, rppsNumber?: string | null, siret?: string | null }, nic?: Array<{ __typename?: 'File', name: string, publicName: string, uploadedAt: any } | null> | null } | null, Specialties?: { __typename?: 'SpecialtyConnection', edges?: Array<{ __typename?: 'SpecialtyEdge', node?: { __typename?: 'Specialty', id: string, name: string, isActive: boolean } | null } | null> | null } | null };

export type UpdateCandidateIbanMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type UpdateCandidateIbanMutation = { __typename?: 'candidateMutation', updateIban: { __typename?: 'Candidate', uuid: any, iban?: string | null, ibanUpdatedAt?: any | null } };

export type UpdateCandidateProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UpdateCandidateProfileMutation = { __typename?: 'candidateMutation', updateCandidateProfile: { __typename?: 'Candidate', uuid: any, id: string, accountStatus?: CandidateAccountStatusEnum | null, hasProfileFilled: boolean, iban?: string | null, specialties: Array<{ __typename?: 'Specialty', id: string, name: string }>, nic?: Array<{ __typename?: 'File', name: string, publicName: string } | null> | null, proInformation: { __typename?: 'ProInformation', canExercise?: UserCanExerciseEnum | null, rppsNumber?: string | null, siret?: string | null }, profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string, phone: { __typename?: 'Phone', code?: number | null, number?: string | null }, location?: { __typename?: 'Location', address?: string | null, zipCode?: string | null, city?: { __typename?: 'City', id: string, name?: string | null } | null, country?: { __typename?: 'Country', id: string, name: string } | null } | null } } };

export type UploadFilesMutationVariables = Exact<{
  file?: InputMaybe<Scalars['UploadType']>;
  side?: InputMaybe<ContractNicEnum>;
}>;


export type UploadFilesMutation = { __typename?: 'candidateMutation', uploadNic: { __typename?: 'CandidateNicPayload', user: { __typename?: 'Candidate', uuid: any, nic?: Array<{ __typename?: 'File', name: string, publicName: string, url: string } | null> | null } } };


export const CheckAccountStatusDocument = gql`
    query checkAccountStatus {
  me {
    uuid
    hasProfileFilled
    accountStatus
  }
}
    `;

/**
 * __useCheckAccountStatusQuery__
 *
 * To run a query within a React component, call `useCheckAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAccountStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAccountStatusQuery(baseOptions?: Apollo.QueryHookOptions<CheckAccountStatusQuery, CheckAccountStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAccountStatusQuery, CheckAccountStatusQueryVariables>(CheckAccountStatusDocument, options);
      }
export function useCheckAccountStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAccountStatusQuery, CheckAccountStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAccountStatusQuery, CheckAccountStatusQueryVariables>(CheckAccountStatusDocument, options);
        }
export type CheckAccountStatusQueryHookResult = ReturnType<typeof useCheckAccountStatusQuery>;
export type CheckAccountStatusLazyQueryHookResult = ReturnType<typeof useCheckAccountStatusLazyQuery>;
export type CheckAccountStatusQueryResult = Apollo.QueryResult<CheckAccountStatusQuery, CheckAccountStatusQueryVariables>;
export const GetUserFirstnameDocument = gql`
    query getUserFirstname {
  me {
    uuid
    profile {
      firstName
    }
  }
}
    `;

/**
 * __useGetUserFirstnameQuery__
 *
 * To run a query within a React component, call `useGetUserFirstnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFirstnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFirstnameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFirstnameQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFirstnameQuery, GetUserFirstnameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFirstnameQuery, GetUserFirstnameQueryVariables>(GetUserFirstnameDocument, options);
      }
export function useGetUserFirstnameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFirstnameQuery, GetUserFirstnameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFirstnameQuery, GetUserFirstnameQueryVariables>(GetUserFirstnameDocument, options);
        }
export type GetUserFirstnameQueryHookResult = ReturnType<typeof useGetUserFirstnameQuery>;
export type GetUserFirstnameLazyQueryHookResult = ReturnType<typeof useGetUserFirstnameLazyQuery>;
export type GetUserFirstnameQueryResult = Apollo.QueryResult<GetUserFirstnameQuery, GetUserFirstnameQueryVariables>;
export const CheckAuthDocument = gql`
    query checkAuth {
  me {
    uuid
  }
}
    `;

/**
 * __useCheckAuthQuery__
 *
 * To run a query within a React component, call `useCheckAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
      }
export function useCheckAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAuthQuery, CheckAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAuthQuery, CheckAuthQueryVariables>(CheckAuthDocument, options);
        }
export type CheckAuthQueryHookResult = ReturnType<typeof useCheckAuthQuery>;
export type CheckAuthLazyQueryHookResult = ReturnType<typeof useCheckAuthLazyQuery>;
export type CheckAuthQueryResult = Apollo.QueryResult<CheckAuthQuery, CheckAuthQueryVariables>;
export const AcceptCguDocument = gql`
    mutation acceptCGU {
  acceptCGU {
    uuid
    profile {
      userNeedCguValidation
    }
  }
}
    `;
export type AcceptCguMutationFn = Apollo.MutationFunction<AcceptCguMutation, AcceptCguMutationVariables>;

/**
 * __useAcceptCguMutation__
 *
 * To run a mutation, you first call `useAcceptCguMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCguMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCguMutation, { data, loading, error }] = useAcceptCguMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptCguMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCguMutation, AcceptCguMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCguMutation, AcceptCguMutationVariables>(AcceptCguDocument, options);
      }
export type AcceptCguMutationHookResult = ReturnType<typeof useAcceptCguMutation>;
export type AcceptCguMutationResult = Apollo.MutationResult<AcceptCguMutation>;
export type AcceptCguMutationOptions = Apollo.BaseMutationOptions<AcceptCguMutation, AcceptCguMutationVariables>;
export const GetCguStatusDocument = gql`
    query getCGUStatus {
  me {
    uuid
    profile {
      userNeedCguValidation
    }
  }
}
    `;

/**
 * __useGetCguStatusQuery__
 *
 * To run a query within a React component, call `useGetCguStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCguStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCguStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCguStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetCguStatusQuery, GetCguStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCguStatusQuery, GetCguStatusQueryVariables>(GetCguStatusDocument, options);
      }
export function useGetCguStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCguStatusQuery, GetCguStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCguStatusQuery, GetCguStatusQueryVariables>(GetCguStatusDocument, options);
        }
export type GetCguStatusQueryHookResult = ReturnType<typeof useGetCguStatusQuery>;
export type GetCguStatusLazyQueryHookResult = ReturnType<typeof useGetCguStatusLazyQuery>;
export type GetCguStatusQueryResult = Apollo.QueryResult<GetCguStatusQuery, GetCguStatusQueryVariables>;
export const GetContractsDocument = gql`
    query getContracts {
  me {
    uuid
    contracts(
      orderBy: {field: CREATED_AT, direction: DESC}
      packageStatus: [PENDING_SIGNATURE, FULLY_SIGNED]
    ) {
      edges {
        node {
          uuid
          contractNumber
          packageStatus
          documentDownloadLink
          groupOffice {
            name
            zipCode
          }
          contractDetails {
            dateTimes {
              startDate
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetContractsQuery__
 *
 * To run a query within a React component, call `useGetContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractsQuery(baseOptions?: Apollo.QueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
      }
export function useGetContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractsQuery, GetContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractsQuery, GetContractsQueryVariables>(GetContractsDocument, options);
        }
export type GetContractsQueryHookResult = ReturnType<typeof useGetContractsQuery>;
export type GetContractsLazyQueryHookResult = ReturnType<typeof useGetContractsLazyQuery>;
export type GetContractsQueryResult = Apollo.QueryResult<GetContractsQuery, GetContractsQueryVariables>;
export const GetFeesInvoicesDocument = gql`
    query getFeesInvoices {
  me {
    uuid
    medelseFeesInvoices(orderBy: {field: CREATED_AT, direction: DESC}) {
      edges {
        node {
          uuid
          invoiceNumber
          groupOffice {
            name
            zipCode
          }
          totalAmount
          createdAt
          contractDetails {
            dateTimes {
              startDate
            }
          }
          documentDownloadLink
        }
      }
    }
  }
}
    `;

/**
 * __useGetFeesInvoicesQuery__
 *
 * To run a query within a React component, call `useGetFeesInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeesInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeesInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeesInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeesInvoicesQuery, GetFeesInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeesInvoicesQuery, GetFeesInvoicesQueryVariables>(GetFeesInvoicesDocument, options);
      }
export function useGetFeesInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeesInvoicesQuery, GetFeesInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeesInvoicesQuery, GetFeesInvoicesQueryVariables>(GetFeesInvoicesDocument, options);
        }
export type GetFeesInvoicesQueryHookResult = ReturnType<typeof useGetFeesInvoicesQuery>;
export type GetFeesInvoicesLazyQueryHookResult = ReturnType<typeof useGetFeesInvoicesLazyQuery>;
export type GetFeesInvoicesQueryResult = Apollo.QueryResult<GetFeesInvoicesQuery, GetFeesInvoicesQueryVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices {
  me {
    uuid
    invoices(
      orderBy: {field: CREATED_AT, direction: DESC}
      paymentStatus: [PAID, LATE, PENDING, PROCESSING, SENT, FAILED, BLOCKED, CANCELED]
    ) {
      edges {
        node {
          uuid
          id
          createdAt
          totalAmount
          payment {
            status
            pendingDate
          }
          documentDownloadLink
          groupOffice {
            name
            zipCode
          }
          contractDetails {
            dateTimes {
              startDate
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const DeleteCandidateAccountDocument = gql`
    mutation deleteCandidateAccount {
  deleteCandidateAccount {
    uuid
  }
}
    `;
export type DeleteCandidateAccountMutationFn = Apollo.MutationFunction<DeleteCandidateAccountMutation, DeleteCandidateAccountMutationVariables>;

/**
 * __useDeleteCandidateAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCandidateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidateAccountMutation, { data, loading, error }] = useDeleteCandidateAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCandidateAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCandidateAccountMutation, DeleteCandidateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCandidateAccountMutation, DeleteCandidateAccountMutationVariables>(DeleteCandidateAccountDocument, options);
      }
export type DeleteCandidateAccountMutationHookResult = ReturnType<typeof useDeleteCandidateAccountMutation>;
export type DeleteCandidateAccountMutationResult = Apollo.MutationResult<DeleteCandidateAccountMutation>;
export type DeleteCandidateAccountMutationOptions = Apollo.BaseMutationOptions<DeleteCandidateAccountMutation, DeleteCandidateAccountMutationVariables>;
export const GetParametersDocument = gql`
    query getParameters {
  me {
    uuid
    profile {
      email
    }
  }
}
    `;

/**
 * __useGetParametersQuery__
 *
 * To run a query within a React component, call `useGetParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParametersQuery(baseOptions?: Apollo.QueryHookOptions<GetParametersQuery, GetParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParametersQuery, GetParametersQueryVariables>(GetParametersDocument, options);
      }
export function useGetParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParametersQuery, GetParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParametersQuery, GetParametersQueryVariables>(GetParametersDocument, options);
        }
export type GetParametersQueryHookResult = ReturnType<typeof useGetParametersQuery>;
export type GetParametersLazyQueryHookResult = ReturnType<typeof useGetParametersLazyQuery>;
export type GetParametersQueryResult = Apollo.QueryResult<GetParametersQuery, GetParametersQueryVariables>;
export const UpdateCandidateEmailDocument = gql`
    mutation updateCandidateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    user {
      uuid
    }
  }
}
    `;
export type UpdateCandidateEmailMutationFn = Apollo.MutationFunction<UpdateCandidateEmailMutation, UpdateCandidateEmailMutationVariables>;

/**
 * __useUpdateCandidateEmailMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateEmailMutation, { data, loading, error }] = useUpdateCandidateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidateEmailMutation, UpdateCandidateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidateEmailMutation, UpdateCandidateEmailMutationVariables>(UpdateCandidateEmailDocument, options);
      }
export type UpdateCandidateEmailMutationHookResult = ReturnType<typeof useUpdateCandidateEmailMutation>;
export type UpdateCandidateEmailMutationResult = Apollo.MutationResult<UpdateCandidateEmailMutation>;
export type UpdateCandidateEmailMutationOptions = Apollo.BaseMutationOptions<UpdateCandidateEmailMutation, UpdateCandidateEmailMutationVariables>;
export const UpdateCandidatePasswordDocument = gql`
    mutation updateCandidatePassword($input: UpdatePasswordInput!) {
  updateCandidatePassword(input: $input) {
    user {
      uuid
    }
  }
}
    `;
export type UpdateCandidatePasswordMutationFn = Apollo.MutationFunction<UpdateCandidatePasswordMutation, UpdateCandidatePasswordMutationVariables>;

/**
 * __useUpdateCandidatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdateCandidatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidatePasswordMutation, { data, loading, error }] = useUpdateCandidatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidatePasswordMutation, UpdateCandidatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidatePasswordMutation, UpdateCandidatePasswordMutationVariables>(UpdateCandidatePasswordDocument, options);
      }
export type UpdateCandidatePasswordMutationHookResult = ReturnType<typeof useUpdateCandidatePasswordMutation>;
export type UpdateCandidatePasswordMutationResult = Apollo.MutationResult<UpdateCandidatePasswordMutation>;
export type UpdateCandidatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdateCandidatePasswordMutation, UpdateCandidatePasswordMutationVariables>;
export const GetCitiesDocument = gql`
    query getCities($input: String!) {
  Cities(search: $input) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile {
  me {
    uuid
    accountStatus
    hasProfileFilled
    profile {
      gender
      firstName
      lastName
      phone {
        code
        number
      }
      location {
        city {
          id
          name
        }
        zipCode
        address
      }
    }
    specialties {
      id
      name
    }
    proInformation {
      isLiberal
      canExercise
      rppsNumber
      siret
    }
    nic {
      name
      publicName
      uploadedAt
    }
    iban
    ibanUpdatedAt
  }
  Specialties(first: 1000, locale: FR) {
    edges {
      node {
        id
        name
        isActive
      }
    }
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const UpdateCandidateIbanDocument = gql`
    mutation updateCandidateIban($input: String!) {
  updateIban(iban: $input) {
    uuid
    iban
    ibanUpdatedAt
  }
}
    `;
export type UpdateCandidateIbanMutationFn = Apollo.MutationFunction<UpdateCandidateIbanMutation, UpdateCandidateIbanMutationVariables>;

/**
 * __useUpdateCandidateIbanMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateIbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateIbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateIbanMutation, { data, loading, error }] = useUpdateCandidateIbanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateIbanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidateIbanMutation, UpdateCandidateIbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidateIbanMutation, UpdateCandidateIbanMutationVariables>(UpdateCandidateIbanDocument, options);
      }
export type UpdateCandidateIbanMutationHookResult = ReturnType<typeof useUpdateCandidateIbanMutation>;
export type UpdateCandidateIbanMutationResult = Apollo.MutationResult<UpdateCandidateIbanMutation>;
export type UpdateCandidateIbanMutationOptions = Apollo.BaseMutationOptions<UpdateCandidateIbanMutation, UpdateCandidateIbanMutationVariables>;
export const UpdateCandidateProfileDocument = gql`
    mutation updateCandidateProfile($input: UserProfileInput!) {
  updateCandidateProfile(input: $input) {
    uuid
    id
    accountStatus
    hasProfileFilled
    iban
    specialties {
      id
      name
    }
    nic {
      name
      publicName
    }
    proInformation {
      canExercise
      rppsNumber
      siret
    }
    profile {
      gender
      firstName
      lastName
      phone {
        code
        number
      }
      location {
        address
        zipCode
        city {
          id
          name
        }
        country {
          id
          name
        }
      }
    }
  }
}
    `;
export type UpdateCandidateProfileMutationFn = Apollo.MutationFunction<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>;

/**
 * __useUpdateCandidateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateProfileMutation, { data, loading, error }] = useUpdateCandidateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>(UpdateCandidateProfileDocument, options);
      }
export type UpdateCandidateProfileMutationHookResult = ReturnType<typeof useUpdateCandidateProfileMutation>;
export type UpdateCandidateProfileMutationResult = Apollo.MutationResult<UpdateCandidateProfileMutation>;
export type UpdateCandidateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateCandidateProfileMutation, UpdateCandidateProfileMutationVariables>;
export const UploadFilesDocument = gql`
    mutation uploadFiles($file: UploadType, $side: ContractNicEnum) {
  uploadNic(file: $file, side: $side) {
    user {
      uuid
      nic {
        name
        publicName
        url
      }
    }
  }
}
    `;
export type UploadFilesMutationFn = Apollo.MutationFunction<UploadFilesMutation, UploadFilesMutationVariables>;

/**
 * __useUploadFilesMutation__
 *
 * To run a mutation, you first call `useUploadFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFilesMutation, { data, loading, error }] = useUploadFilesMutation({
 *   variables: {
 *      file: // value for 'file'
 *      side: // value for 'side'
 *   },
 * });
 */
export function useUploadFilesMutation(baseOptions?: Apollo.MutationHookOptions<UploadFilesMutation, UploadFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFilesMutation, UploadFilesMutationVariables>(UploadFilesDocument, options);
      }
export type UploadFilesMutationHookResult = ReturnType<typeof useUploadFilesMutation>;
export type UploadFilesMutationResult = Apollo.MutationResult<UploadFilesMutation>;
export type UploadFilesMutationOptions = Apollo.BaseMutationOptions<UploadFilesMutation, UploadFilesMutationVariables>;