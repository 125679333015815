import React from 'react';

type Props = {
  disabled?: boolean
  id: string
  label?: string
  onChange: (val: string) => void
  type?: React.HTMLInputTypeAttribute
  value?: string | undefined
  placeholder?: string
  error?: boolean
};

const TextField = ({
  disabled, id, label, onChange, type, value, placeholder, error,
}: Props) => {
  const className: string = disabled ? 'text-field-disabled' : 'text-field';
  const isDisabled = !!disabled;
  return (
    <label htmlFor={`field-${id}`} className="text-field-label">
      {label && <span className={`${error && 'text-invalid'}`}>{label}</span>}
      <input
        placeholder={placeholder}
        className={`${className} ${error && 'form-control is-invalid bg-white'}`}
        disabled={isDisabled}
        id={`field-${id}`}
        onChange={(
          evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => onChange(evt.target.value)}
        type={type}
        value={value}
      />
    </label>
  );
};

TextField.defaultProps = {
  disabled: false,
  label: undefined,
  type: 'text',
  value: undefined,
  placeholder: undefined,
  error: false,
};

export default TextField;
