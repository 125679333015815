import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import { PaymentStatus, useGetInvoicesQuery } from '../../../app/generated/graphql';
import { downloadDocument } from '../../../app/domain/utils';
import { Invoice } from '../domain/models';
import InvoiceRepository from '../data/InvoiceRepository';
import { GetEntityListUsecase } from '../../../app/domain/GetEntityListUsecase';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';

const useInvoices: GetEntityListUsecase<Invoice> = () => {
  let invoices: Invoice[] | undefined;
  const { error, loading, data } = useGetInvoicesQuery();

  if (data) {
    const repo = new InvoiceRepository(data);

    invoices = repo.getInvoices();
  }

  return {
    loading,
    entities: invoices,
    errorMessage: error?.message,
  };
};

const InvoiceTable = () => {
  const { t } = useTranslation();
  const {
    entities,
    errorMessage,
    loading,
  } = useInvoices();

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorKey: 'invoiceNumber',
      header: () => t('invoice.invoiceNumber'),
    },
    {
      id: 'groupOffice',
      header: () => t('invoice.groupOffice'),
      accessorFn: (row) => row.office,
    },
    {
      accessorKey: 'amount',
      header: () => t('invoice.amount'),
    },
    {
      id: 'created_at',
      header: () => t('invoice.created_at'),
      accessorFn: (row) => row.createdAt,
    },
    {
      id: 'packageStatus',
      header: () => t('invoice.status'),
      accessorFn: ({ status, datePaid }) => {
        let result: string = t(`paymentStatus.${status}`);

        if (status === PaymentStatus.Paid && datePaid) {
          result += ` ${t('on')} ${t(datePaid)}`;
        }

        return result;
      },
    },
    {
      id: 'dates',
      header: () => t('invoice.dates'),
      accessorFn: (row) => row.dates.join('; '),
    },
    {
      id: 'download',
      header: () => t('invoice.download'),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => (
        <button
          type="button"
          className="btn border-0 text-primary"
          data-testid="donwload_invoice"
          aria-labelledby="Download invoice"
          onClick={() => downloadDocument(row.original.downloadURL)}
        >
          <FontAwesomeIcon icon={faFileArrowDown} />
        </button>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];

  if (loading) {
    return <QueryIndicator />;
  }

  if (errorMessage) {
    return <div className="alert alert-danger">{errorMessage}</div>;
  }

  return (
    <Table
      title={t('invoices.tableTitle')}
      pageSize={ARRAY_ITEMS_PER_PAGE}
      columns={columns}
      data={entities!}
      canBeFiltered
    />
  );
};

export default InvoiceTable;
