import PageLayout from 'app/components/PageLayout';
import Profile from 'features/Profile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout navigateTo={(path) => navigate(path)} title={t('pages.profile')}>
      <Profile />
    </PageLayout>
  );
};

export default ProfilePage;
