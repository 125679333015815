/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Column,
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';

import EmptyDataComponent from '../EmptyDataComponent';
import TablePagination from './TablePagination';

type Props<T> = {
  canBeFiltered?: boolean
  data: T[]
  columns: ColumnDef<T>[]
  pageSize?: number
  defaultColumnSort?: ColumnSort | undefined
  setRowStyles?: (row: any) => React.CSSProperties
  title?: string | undefined
};

const Table = <T extends object>({
  canBeFiltered,
  columns,
  data,
  defaultColumnSort,
  pageSize,
  setRowStyles,
  title,
}: Props<T>) => {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  React.useEffect(() => {
    if (defaultColumnSort !== undefined) {
      setSorting([defaultColumnSort]);
    }
  }, [defaultColumnSort]);

  let paginationState: PaginationState | undefined;
  if (pageSize !== undefined) {
    paginationState = {
      pageIndex,
      pageSize,
    };
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      pagination: paginationState,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pageSize ? getPaginationRowModel() : undefined,
  });

  const startItem = () => {
    if (pageIndex === 0) {
      return pageIndex + 1;
    }

    return ((pageSize || 0) * pageIndex) + 1;
  };

  const endItem = () => {
    const maxResult = table.getFilteredRowModel().rows.length;
    const maxItems = (pageSize || 0) * (pageIndex + 1);

    if (maxItems > maxResult) {
      return maxResult;
    }

    return maxItems;
  };

  const isEmpty = data.length === 0;

  const onColumnHeaderClick = (column: Column<T, unknown>) => {
    if (column.getCanSort() === true) {
      column.toggleSorting();
    }
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
      }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          { title ? (
            <h2 className="h5 fw-semibold">{title}</h2>
          ) : null }
        </div>
        {!isEmpty && canBeFiltered && (
          <div style={{ width: 200 }}>
            <input
              value={globalFilter}
              onChange={(e) => {
                setGlobalFilter(String(e.target.value));
                setPageIndex(0);
              }}
              className="p-2 form-control w-100"
              placeholder={t('table.filterPlaceholder')}
            />
          </div>
        )}
      </div>
      <div>
        <table className="table striped table-bordered text-center">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan} className="text-white bg-primary">
                    {header.isPlaceholder ? null : (
                      <div
                        role="presentation"
                        className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                        onClick={() => onColumnHeaderClick(header.column)}
                        style={{ paddingRight: header.column.getCanSort() ? 20 : 0 }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: <FontAwesomeIcon icon={faSortUp} role="img" />,
                          desc: <FontAwesomeIcon icon={faSortDown} role="img" />,
                        }[header.column.getIsSorted() as string] ?? (
                          header.column.getCanSort() ? (
                            <FontAwesomeIcon icon={faSort} role="img" />
                          ) : null
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={setRowStyles ? setRowStyles(row) : {}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        { !isEmpty && pageSize && (
          <div className="d-flex flex-direction-row btn-toolbar">
            <div className="col-form-label text-black-50" data-testid="pagination-details">
              {`${startItem()} à ${endItem()} sur ${table.getFilteredRowModel().rows.length}`}
            </div>
            <div className="mx-auto">
              <TablePagination
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                getNextPage={table.getCanNextPage()}
                getPreviousPage={table.getCanPreviousPage()}
              />
            </div>
          </div>
        )}
        {isEmpty ? <EmptyDataComponent /> : null}
      </div>
    </div>
  );
};

Table.defaultProps = {
  canBeFiltered: false,
  defaultColumnSort: undefined,
  pageSize: undefined,
  setRowStyles: () => ({}),
  title: undefined,
};

export default Table;
