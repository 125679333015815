import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCguStatusQuery } from 'app/generated/graphql';
import Button from 'app/components/Button';
import QueryIndicator from 'app/components/QueryIndicator';

type Props = {
  children: React.ReactNode
  onNeedToValidate: () => void
  onReturn: () => void
};

const CGUContainer = ({ children, onNeedToValidate, onReturn }: Props) => {
  const { error, loading } = useGetCguStatusQuery({
    onCompleted(res) {
      if (res?.me?.profile.userNeedCguValidation === true) {
        onNeedToValidate();
      }
    },
  });
  const { t } = useTranslation();

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return (
      <div className="text-center">
        <div className="alert alert-danger">{error.message}</div>
        <Button
          title={t('cgu.dashboard')}
          onClick={() => onReturn()}
          variant="secondary"
        />
      </div>
    );
  }

  return children;
};

export default CGUContainer;
