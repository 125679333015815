import React, { FormEventHandler } from 'react';
import {
  FetchResult,
  useMutation,
  gql,
  ApolloError,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GenderEnum, Phone } from 'app/generated/graphql';
import {
  PartialRegisterInput,
  RegisterCandidateFieldsValidationPayload,
  RegisterCandidateFieldsValidationVariables,
} from 'app/domain/registerTypes';
import QueryIndicator from 'app/components/QueryIndicator';
import PhoneNumberInput from 'app/components/PhoneNumberInput';

export const MUTATION = gql`
mutation registerFieldsValidation($input: RegisterCandidateFieldsValidationInput!){
  registerCandidateFieldsValidation(input: $input) {
    valid
  }
}
`;

type Option = { label: string, value: string };
type Props = {
  departments: Array<Option>
  specialties: Array<Option>
  onSuccess: (validInput: PartialRegisterInput) => void
  initialData: PartialRegisterInput | undefined
};

type UseRegisterStep1 = () => ({
  error: ApolloError | undefined
  invalidFields: Array<string>
  loading: boolean
  validateFields: (
    input: PartialRegisterInput
  ) => Promise<FetchResult<RegisterCandidateFieldsValidationPayload>>
});

const useRegisterStep1: UseRegisterStep1 = () => {
  const [
    mutate, { loading, error },
  ] = useMutation<
  RegisterCandidateFieldsValidationPayload,
  RegisterCandidateFieldsValidationVariables
  >(MUTATION, { context: { clientName: 'register' } });

  return {
    error,
    invalidFields: error ? error.graphQLErrors.map((e) => {
      if (e.message.startsWith('{')) {
        return JSON.parse(e.message).field;
      }

      return '';
    }) : [],
    loading,
    validateFields: (input: PartialRegisterInput) => mutate(
      { variables: { input } },
    ),
  };
};

const RegisterStep1Form = ({
  departments,
  specialties,
  onSuccess,
  initialData,
} : Props) => {
  const { t } = useTranslation();
  const [selectErrors, setSelectErrors] = React.useState<string[]>([]);
  const [input, setInput] = React.useState<PartialRegisterInput>({
    department: '',
    firstName: '',
    gender: ('' as GenderEnum),
    isLiberal: false,
    lastName: '',
    referralCode: '',
    specialty: '',
    phone: { code: 33, number: '' },
  });
  const {
    error,
    invalidFields,
    validateFields,
    loading,
  } = useRegisterStep1();

  React.useEffect(() => {
    if (initialData !== undefined) {
      setInput(initialData);
    }
  }, [initialData]);

  const handlePhoneChange = (
    field: 'phone',
    value: Required<Pick<Phone, 'number' | 'code'>>,
  ) => setInput({
    ...input,
    [field]: value,
  });

  const handleChange = (
    field: keyof PartialRegisterInput,
    value: string | Pick<Phone, 'number' | 'code'>,
  ) => setInput({
    ...input,
    [field]: value,
  });

  const handleOnSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();
    setSelectErrors([]);

    // Check if each select have value to prevent weird API errors message
    if (input.department.length > 0 && input.specialty.length > 0 && input.gender.length > 0) {
      const apiInput = { ...input };

      if (input.referralCode?.length === 0) {
        delete apiInput.referralCode;
      }

      validateFields(apiInput)
        .then(() => onSuccess(input))
        .catch(() => {});
    } else {
      const frontErrors: string[] = [];

      if (input.gender.length === 0) { frontErrors.push('gender'); }
      if (input.department.length === 0) { frontErrors.push('department'); }
      if (input.specialty.length === 0) { frontErrors.push('specialty'); }

      setSelectErrors(frontErrors);
    }
  };

  const getControlClasses = (base: string, fieldName: string, type: 'field' | 'label'):string => {
    const hasError = invalidFields.indexOf(fieldName) !== -1
      || selectErrors.indexOf(fieldName) !== -1;

    if (type === 'field') {
      return `${base} ${hasError ? 'is-invalid' : ''}`;
    }
    return `${base} ${hasError ? 'text-invalid' : ''}`;
  };

  return (
    <form onSubmit={handleOnSubmit}>
      { loading ? (
        <QueryIndicator />
      ) : (
        <>
          {selectErrors.length ? (
            <div className="alert alert-danger" role="alert">
              {selectErrors.map((fieldName) => (
                <div key={`front-error-${fieldName}`}>{`${t(`register.${fieldName}.label`)}: ${t('errors.requiredField')}`}</div>
              ))}
            </div>
          ) : null}
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error.graphQLErrors.length ? '' : error.message}
              {error.graphQLErrors.map((e) => {
                let errorLine: string = e.message;
                if (e.message.startsWith('{')) {
                  const { field, message } = JSON.parse(e.message);

                  const fieldName = t(`register.${field}.label`);
                  errorLine = `${fieldName}: ${message}`;
                }

                return (
                  <div key={e.message}>{errorLine}</div>
                );
              })}
            </div>
          ) : null }
          <label htmlFor="gender" className={getControlClasses('d-block mb-2', 'gender', 'label')}>
            {t('register.gender.label')}
            <select
              id="gender"
              className={getControlClasses('form-select bg-white', 'gender', 'field')}
              onChange={(val) => handleChange('gender', val.target.value)}
              value={input.gender}
            >
              <option value="">{t('register.gender.placeholder')}</option>
              <option value={GenderEnum.Female}>{t(`gender.${GenderEnum.Female}`)}</option>
              <option value={GenderEnum.Male}>{t(`gender.${GenderEnum.Male}`)}</option>
              <option value={GenderEnum.Doctor}>{t(`gender.${GenderEnum.Doctor}`)}</option>
              <option value={GenderEnum.None}>{t(`gender.${GenderEnum.None}`)}</option>
            </select>
          </label>
          <label htmlFor="lastName" className={getControlClasses('d-block mb-2', 'lastName', 'label')}>
            {t('register.lastName.label')}
            <input
              type="text"
              id="lastName"
              className={getControlClasses('form-control bg-white', 'lastName', 'field')}
              placeholder={t('register.lastName.placeholder')}
              value={input.lastName}
              onChange={(val) => handleChange('lastName', val.target.value)}
            />
          </label>
          <label htmlFor="firstName" className={getControlClasses('d-block mb-2', 'firstName', 'label')}>
            {t('register.firstName.label')}
            <input
              type="text"
              id="firstName"
              className={getControlClasses('form-control bg-white', 'firstName', 'field')}
              placeholder={t('register.firstName.placeholder')}
              value={input.firstName}
              onChange={(val) => handleChange('firstName', val.target.value)}
            />
          </label>
          <label htmlFor="department" className={getControlClasses('d-block mb-2', 'department', 'label')}>
            {t('register.department.label')}
            <select
              id="department"
              className={getControlClasses('form-select bg-white', 'department', 'field')}
              onChange={(val) => handleChange('department', val.target.value)}
              value={input.department}
            >
              <option value="">{t('register.department.placeholder')}</option>
              {departments.map(({ label, value }) => (
                <option value={value} key={value}>{label}</option>
              ))}
            </select>
          </label>
          <label htmlFor="specialty" className={getControlClasses('d-block mb-2', 'specialty', 'label')}>
            {t('register.specialty.label')}
            <select
              id="specialty"
              className={getControlClasses('form-select bg-white', 'specialty', 'field')}
              onChange={(val) => handleChange('specialty', val.target.value)}
              value={input.specialty}
            >
              <option value="">{t('register.specialty.placeholder')}</option>
              {specialties.map(({ label, value }) => (
                <option value={value} key={value}>{t(`database_fields.${label}`)}</option>
              ))}
            </select>
          </label>
          <PhoneNumberInput
            phoneValue={input.phone!}
            onChange={(val) => handlePhoneChange('phone', { code: val.code, number: val.number })}
            label={t('register.phone.label')}
            placeholder={t('register.phone.placeholder')}
          />
          <label htmlFor="referralCode" className="d-block mb-2">
            {t('register.referralCode.label')}
            <input
              type="text"
              id="referralCode"
              className="form-control bg-white"
              placeholder={t('register.referralCode.placeholder')}
              value={input.referralCode}
              onChange={(val) => handleChange('referralCode', val.target.value)}
            />
          </label>
          <div className="form-check mb-4">
            <label className="form-check-label" htmlFor="isLiberal">
              <input
                className={getControlClasses('form-check-input', 'isLiberal', 'field')}
                type="checkbox"
                checked={input.isLiberal}
                onChange={() => { setInput({ ...input, isLiberal: !input.isLiberal }); }}
                id="isLiberal"
              />
              {t('register.isLiberal.label')}
            </label>
          </div>
          <div className="d-grid gap-2 pt-2">
            <button type="submit" className="btn btn-medelse btn-primary text-uppercase">{t('register.validateFields')}</button>
          </div>
        </>
      )}
    </form>
  );
};

export default RegisterStep1Form;
