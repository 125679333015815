import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

const Chatbot = () => (
  <div className="min-vw-100 min-vh-100 d-flex justify-content-center align-items-center">
    <FontAwesomeIcon icon={faComments} role="img" fontSize={90} color="#DCDFE6" />
  </div>
);

export default Chatbot;
