import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import useTracking from 'app/domain/useTracking';
import QueryIndicator from 'app/components/QueryIndicator';
import useAuth from './hooks/useAuth';

type Props = { children: JSX.Element };

const Autolog = ({ children }: Props) => {
  const { refreshToken } = useParams();
  const auth = useAuth();
  const { manageTracking } = useTracking();
  const location = useLocation();
  const [isValid, setIsValid] = React.useState<boolean | undefined>();

  React.useEffect(() => {
    if (refreshToken) {
      auth.generateTokens(refreshToken)
        .then(() => setIsValid(true))
        .catch(() => setIsValid(false));
    }
  }, [refreshToken, auth]);

  if (isValid === undefined) {
    return <QueryIndicator />;
  }

  if (isValid) {
    manageTracking(auth.getToken());

    return children;
  }

  manageTracking(null);
  return <Navigate to="/404" state={{ from: location }} replace />;
};

export default Autolog;
