import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import LoggerService from 'app/services/LoggerService';
import CGUContainer from 'features/CGU/CGUContainer';
import useTracking from 'app/domain/useTracking';
import useAuth from './hooks/useAuth';
import CheckAuthContainer from './CheckAuthContainer';

type Props = { children: JSX.Element };

const RequireAuth = ({ children }: Props) => {
  const auth = useAuth();
  const { manageTracking } = useTracking();
  const navigate = useNavigate();
  const location = useLocation();

  const token = auth.getToken();

  React.useEffect(() => {
    if (token) {
      LoggerService.logNavigation(location.pathname, token);
    }
  }, [location, token]);

  const handleNeedToValidateCGU = () => {
    if (location.pathname !== '/cgu-update') {
      navigate('/cgu-update');
    }
  };

  const handleOnReturn = () => navigate(location.pathname);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  manageTracking(token);

  return (
    <CheckAuthContainer>
      <CGUContainer onNeedToValidate={handleNeedToValidateCGU} onReturn={handleOnReturn}>
        { children }
      </CGUContainer>
    </CheckAuthContainer>
  );
};

export default RequireAuth;
