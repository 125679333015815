import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';

import { authAPIRoutes } from '../../app/constants';
import { Credentials } from '../domain/types/Credentials';
import { Tokens } from '../domain/types/Tokens';
import { Token } from '../domain/types/Token';
import { IAuthService } from '../domain/types/IAuthService';

const config: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000, // docker can be slow at times
};

const AuthService: IAuthService = {
  signInWithCredentials: (credentials: Credentials) => (
    new Promise<Tokens>((resolve, reject) => {
      axios.post(process.env.REACT_APP_API_URL + authAPIRoutes.signIn, credentials, config)
        .then((response) => {
          const { token, refresh_token: refreshToken } = response.data;
          const tokenData: any = jwtDecode(token);
          if (
            tokenData
            && tokenData.roles
            && (tokenData.roles as Array<string>).includes('ROLE_USER_CANDIDATE')
          ) {
            resolve({ token, refreshToken });
          } else {
            reject(new Error('unauthorized'));
          }
        })
        .catch((error) => {
          const res = error.response?.request?.response;
          if (res && JSON.parse(res).message === 'account.blocked') {
            reject(new Error('account.blocked'));
          } else {
            reject(error);
          }
        });
    })
  ),
  recoverPassword: (email: string) => (
    new Promise<void>((resolve, reject) => {
      axios.post(process.env.REACT_APP_API_URL + authAPIRoutes.recoverPassword, { email }, config)
        .then(() => resolve())
        .catch((error) => reject(error));
    })
  ),
  refreshToken: (currentRefreshToken: Token) => (
    new Promise<Tokens>((resolve, reject) => {
      axios.post(
        process.env.REACT_APP_API_URL + authAPIRoutes.refreshToken,
        { refresh_token: currentRefreshToken },
        config,
      )
        .then((response) => {
          const { token, refresh_token: refreshToken } = response.data;
          resolve({ token, refreshToken });
        })
        .catch((error) => reject(error));
    })
  ),
};

export default AuthService;
