import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LayoutSplit from '../../app/components/LayoutSplit/LayoutSplit';
import logo from '../../assets/logo_medelse_white.svg';

import LoginForm from '../../auth/view/LoginForm';

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <LayoutSplit>
      <div className="justify-content-center row">
        <div className="col-xxl-8 col-xl-8 col-lg-9 title-container">
          <h1 className="text-uppercase mb-5"><strong>{t('login.pageTitle')}</strong></h1>
          <div className="card overflow-hidden">
            <div className="text-center p-3 bg-primary">
              <img src={logo} className="card-img-top" alt="Medelse logo" style={{ width: 200 }} />
            </div>
            <div className="card-body bg-white">
              <div className="row d-flex align-items-center">
                <div className="col">
                  <h2 className="h2">{t('login.title')}</h2>
                </div>
                <div className="col text-end text-right">
                  <Link
                    to="/register"
                    className="text-decoration-none"
                  >
                    <span className="text-dark">{`${t('login.noAccount')} `}</span>
                    <span className="fw-bolder text-primary text-decoration-underline">{t('login.register')}</span>
                  </Link>
                </div>
              </div>
              <LoginForm />
            </div>
          </div>
          <div className="text-center my-4">
            <span>{t('login.wrongSiteCategory')}</span>
            <a
              className=" ms-2 link link-secondary"
              href={process.env.REACT_APP_BUSINESS_URL}
            >
              {t('login.loginHere')}
            </a>
          </div>
        </div>
      </div>
    </LayoutSplit>
  );
};

export default LoginPage;
