import React from 'react';
import { useTranslation } from 'react-i18next';

import { GetParametersQuery, useUpdateCandidateEmailMutation } from 'app/generated/graphql';
import CardTitle from 'app/components/Card/CardTitle/CardTitle';
import QueryIndicator from 'app/components/QueryIndicator';
import FormActions from 'app/components/FormActions';
import TextField from 'app/components/TextField';
import AlertMessage from 'app/components/AlertMessage';

type Props = {
  userProfile: GetParametersQuery['me']
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersEmailForm = ({ userProfile, onCancel, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [updateCandidateEmailMutation, { loading, error }] = useUpdateCandidateEmailMutation();
  const [email, setEmail] = React.useState<string>('');
  const [emailConfirmation, setEmailConfirmation] = React.useState<string>('');

  const handleOnSubmit = () => {
    updateCandidateEmailMutation({
      variables: { input: { email, emailConfirmation } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('parametersEmailForm.title')}</CardTitle>
      { loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <div style={{ marginBottom: 20 }}>
              <TextField
                label={t('parametersEmailForm.currentEmail')}
                id="currentEmmail"
                value={userProfile?.profile.email}
                onChange={() => {}}
                disabled
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <TextField
                id="email"
                label={t('parametersEmailForm.email')}
                onChange={setEmail}
                value={email}
              />
            </div>
            <TextField
              id="emailConfirm"
              label={t('parametersEmailForm.emailConfirm')}
              onChange={setEmailConfirmation}
              value={emailConfirmation}
            />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            variant="primary"
          />
        </>
      )}
    </div>
  );
};

export default ParametersEmailForm;
