import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  BaseQueryResultData,
  DepartmentsConnection,
  SpecialtiesConnection,
  PartialRegisterInput,
  RegisterCandidatePayload,
  UtmDataInput,
} from 'app/domain/registerTypes';

import medelseMono from 'assets/medelse_mono.png';
import QueryIndicator from 'app/components/QueryIndicator';
import RegisterStep1Form from './RegisterStep1Form';
import RegisterStep2Form from './RegisterStep2Form';
import RegisterStep3Form from './RegisterStep3Form';

export const QUERY = gql`
query registerBase {
  Departments(first: 200) {
    edges {
      node {
        id
        name
      }
    }
  }
  Specialties(first: 200) {
    edges {
      node {
        id
        name
        isActive
        isActiveRegister
      }
    }
  }
}
`;

type Props = {
  onAutoLog: (loginToken: RegisterCandidatePayload['loginToken']) => void
  utmData: UtmDataInput
};
type Connection = DepartmentsConnection | SpecialtiesConnection;
type Option = { label: string, value: string };

const RegisterForm = ({ onAutoLog, utmData }: Props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<BaseQueryResultData>(QUERY, { context: { clientName: 'register' } });
  const [registerPayload, setRegisterPayload] = useState<RegisterCandidatePayload['loginToken'] | undefined>();
  const [partialInput, setPartialInput] = useState<PartialRegisterInput | undefined>();
  const [forceStep1, setForceStep1] = useState<boolean>(false);

  const handleOnContinue = () => {
    if (registerPayload) {
      onAutoLog(registerPayload);
    }
  };

  if (loading) {
    return <QueryIndicator />;
  }

  const transformConnectionToOptions = (connection: Connection): Array<Option> => connection.edges
    .map((e) => ({
      label: e.node.name,
      value: e.node.id,
    }));

  const getAvailableSpecialtiesConnection = (
    connection: SpecialtiesConnection,
  ): SpecialtiesConnection => {
    const edges = connection.edges
      .filter(({ node }) => node.isActive && node.isActiveRegister);

    return { edges };
  };

  const getVisibleStep = (): 'step1' | 'step2' | 'step3' | 'other' => {
    if (forceStep1) {
      return 'step1';
    }

    if (registerPayload) {
      return 'step3';
    }

    if (partialInput) {
      return 'step2';
    }

    if (data) {
      return 'step1';
    }

    return 'other';
  };

  const getClassName = (prefix: string, step: string) => {
    if (step === getVisibleStep()) {
      return `${prefix}-primary`;
    }

    return `${prefix}-tertiary`;
  };

  const handleValidateSuccess = (input: PartialRegisterInput) => {
    setPartialInput(input);
    setForceStep1(false);
  };

  const handleOnReturn = () => setForceStep1(true);

  return (
    <div>
      <div className="text-center">
        <img src={medelseMono} className="mb-3" alt="Medelse logo" style={{ width: 80 }} />
        <h1 className="h5 fw-semibold mb-5">{t('pages.register')}</h1>
        <div className="d-flex flex-row align-items-center">
          <div className="breadcrumb-item">
            <span className={`badge rounded-pill fs-6 ${getClassName('bg', 'step1')}`}>1</span>
            <div className={getClassName('text', 'step1')}>{t('register.step1')}</div>
          </div>
          <div className="flex-fill bg-tertiary mb-4" style={{ height: 1 }} />
          <div className="breadcrumb-item">
            <span className={`badge rounded-pill fs-6 ${getClassName('bg', 'step2')}`}>2</span>
            <div className={getClassName('text', 'step2')}>{t('register.step2')}</div>
          </div>
          <div className="flex-fill bg-tertiary mb-4" style={{ height: 1 }} />
          <div className="breadcrumb-item">
            <span className={`badge rounded-pill fs-6 ${getClassName('bg', 'step3')}`}>3</span>
            <div className={getClassName('text', 'step3')}>{t('register.step3')}</div>
          </div>
        </div>
      </div>
      <h2 className="h5 fw-semibold my-5">{t(`register.${getVisibleStep()}`)}</h2>
      {error ? (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      ) : null}
      <div>
        {getVisibleStep() === 'step1' ? (
          <RegisterStep1Form
            initialData={partialInput || undefined}
            departments={transformConnectionToOptions(data!.Departments)}
            specialties={
              transformConnectionToOptions(
                getAvailableSpecialtiesConnection(data!.Specialties),
              )
            }
            onSuccess={handleValidateSuccess}
          />
        ) : null}
      </div>
      <div>
        {getVisibleStep() === 'step2' ? (
          <RegisterStep2Form
            onReturn={handleOnReturn}
            partialInput={partialInput!}
            onSuccess={setRegisterPayload}
            utmData={utmData}
          />
        ) : null}
      </div>
      <div>
        { getVisibleStep() === 'step3' ? (
          <RegisterStep3Form onContinue={handleOnContinue} />
        ) : null}
      </div>
    </div>
  );
};

export default RegisterForm;
