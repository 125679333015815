import { LOCAL_STORAGE_REMEMBER_KEY, LOCAL_STORAGE_AUTH_KEY } from 'app/constants';
import { Token } from '../domain/types/Token';
import { Tokens } from '../domain/types/Tokens';
import { Credentials } from '../domain/types/Credentials';
import { ILoginService } from '../domain/types/ILoginService';
import AuthService from './AuthService';

const getToken = (): Token | null => {
  const storedTokens = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

  if (storedTokens) {
    return (JSON.parse(storedTokens) as Tokens)!.token;
  }

  return null;
};

const getRefreshToken = (): Token | null => {
  const storedTokens = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

  if (storedTokens) {
    return (JSON.parse(storedTokens) as Tokens)!.refreshToken;
  }

  return null;
};

const saveToken = (tokens: Tokens) => localStorage
  .setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(tokens));

const login = (
  credentials: Credentials,
  rememberMe: boolean,
) => new Promise<void>((resolve, reject) => {
  AuthService
    .signInWithCredentials(credentials)
    .then((authTokens) => {
      saveToken(authTokens);
      if (rememberMe) {
        localStorage.setItem(LOCAL_STORAGE_REMEMBER_KEY, '1');
      } else {
        localStorage.removeItem(LOCAL_STORAGE_REMEMBER_KEY);
      }
      resolve();
    })
    .catch((err) => reject(err));
});

const signout = () => {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
  localStorage.removeItem(LOCAL_STORAGE_REMEMBER_KEY);
};

const refreshToken = () => new Promise<void>((resolve, reject) => {
  const savedRefrehToken = getRefreshToken();

  if (!refreshToken) { reject(new Error('No refresh token found')); }

  AuthService.refreshToken(savedRefrehToken)
    .then((tokens) => {
      saveToken(tokens);
      resolve();
    })
    .catch((err) => reject(err));
});

const generateTokens = (refresh: string) => new Promise<void>((resolve, reject) => {
  AuthService.refreshToken(refresh)
    .then((tokens) => {
      saveToken(tokens);
      resolve();
    })
    .catch((err) => reject(err));
});

const recoverPassword = (email: string) => AuthService.recoverPassword(email);

const LoginService: ILoginService = {
  login, signout, refreshToken, getToken, saveToken, recoverPassword, generateTokens,
};

export default LoginService;
