import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCheckAuthQuery } from 'app/generated/graphql';
import QueryIndicator from 'app/components/QueryIndicator';
import useAuth from '../hooks/useAuth';

type Props = { children: JSX.Element };

const CheckAuthContainer = ({ children }: Props) => {
  const { signout } = useAuth();
  const navigate = useNavigate();
  const { loading, data } = useCheckAuthQuery({
    onError() {
      signout(() => navigate('/login', { replace: true }));
    },
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (!data) {
    return null;
  }

  return children;
};

export default CheckAuthContainer;
