import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RecoverForm from 'auth/view/RecoverForm';
import LayoutSplit from '../../app/components/LayoutSplit/LayoutSplit';

const RecoverPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <LayoutSplit>
      <div className="justify-content-center row">
        <div className="col-xxl-8 col-xl-8 col-lg-9">
          <div className="card bg-white overflow-hidden">
            <div className="card-body">
              <div className="h2">{t('recover.headline')}</div>
              <div>{t('recover.instructions')}</div>
              <RecoverForm onCancel={() => navigate('/')} />
            </div>
          </div>
        </div>
      </div>
    </LayoutSplit>
  );
};

export default RecoverPage;
