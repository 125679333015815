import { GetFeesInvoicesQueryResult } from 'app/generated/graphql';
import { FeesInvoice, IFeesInvoiceRepository } from './domain';
import { formatDateTime, formatNumberToCurrency } from '../../app/domain/utils';

class FeesInvoicesRepository implements IFeesInvoiceRepository {
  private invoices: FeesInvoice[];

  constructor(data: GetFeesInvoicesQueryResult['data']) {
    if (data && data.me && data.me.medelseFeesInvoices && data.me.medelseFeesInvoices.edges) {
      this.invoices = data.me.medelseFeesInvoices.edges.map((item) => {
        const invoice = item!.node!;

        return ({
          invoiceNumber: invoice.invoiceNumber!,
          office: `${invoice.groupOffice?.name} (${invoice.groupOffice?.zipCode})`,
          amount: formatNumberToCurrency((invoice.totalAmount as number)),
          createdAt: formatDateTime(invoice.createdAt),
          dates: invoice.contractDetails!.map((cd) => formatDateTime(cd!.dateTimes.startDate)),
          downloadURL: (invoice.documentDownloadLink as string),
        });
      });
    } else {
      this.invoices = [];
    }
  }

  getInvoices() {
    return this.invoices;
  }
}

export default FeesInvoicesRepository;
