import React from 'react';

type Props = {
  title: string
  onClick: VoidFunction
  variant: string
  isLarge?: boolean
};

const Button = ({
  isLarge,
  title,
  onClick,
  variant,
}: Props) => {
  const classNames: string[] = ['btn', `btn-${variant}`, 'btn-medelse'];

  if (isLarge) {
    classNames.push('btn-lg');
  }

  return (
    <button className={classNames.join(' ')} type="button" onClick={onClick}>{title}</button>
  );
};

Button.defaultProps = { isLarge: false };

export default Button;
