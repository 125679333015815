import React from 'react';

type Props = {
  children: React.ReactNode
  h100?: boolean
  style?: Object
};

const Card = ({ children, h100, style }: Props) => (
  <div className={`card bg-white shadow-sm border-0 ${h100 ? 'h-100' : ''}`} style={style}>
    <div className="card-body">
      {children}
    </div>
  </div>
);

Card.defaultProps = { h100: false, style: {} };

export default Card;
