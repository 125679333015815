import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../app/components/PageLayout';
import DownloadAppView from '../../app/view/DownloadAppView/DownloadAppView';

const DashboardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout navigateTo={(path) => navigate(path)} title={t('pages.home')}>
      <DownloadAppView />
    </PageLayout>
  );
};

export default DashboardPage;
