import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import PasswordInput from 'app/components/PasswordInput';
import useAuth from '../hooks/useAuth';
import Spinner from '../../../app/components/Spinner/Spinner';

const LoginForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    let userNameValid = false;
    let passwordValid = false;

    if (username && username.trim().length > 0) {
      userNameValid = true;
    }

    if (password && password.trim().length > 0) {
      passwordValid = true;
    }

    if (userNameValid && passwordValid) {
      setLoading(true);
      auth.signin(username, password, rememberMe)
        .then(() => navigate('/', { replace: true }))
        .catch((err) => {
          setError(t(err.message));
          setLoading(false);
        });
    } else {
      setIsUsernameValid(userNameValid);
      setIsPasswordValid(passwordValid);
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error === t('unauthorized') ? (
            <Trans i18nKey="unauthorized">
              <span>
                {t(error)}
              </span>
              <a className="text-danger" href={process.env.REACT_APP_BUSINESS_URL} target="_blank" rel="noreferrer">
                {process.env.REACT_APP_BUSINESS_URL}
              </a>
            </Trans>
          )
            : (t(error))}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="input-group has-validation">
          <label htmlFor="usernameField" className="form-label w-100">
            {t('login.username')}
            <input
              data-testid="usernameField"
              className={`form-control${!isUsernameValid ? ' is-invalid' : ''}`}
              id="usernameField"
              name="username"
              type="text"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              required
            />
            {!isUsernameValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
          </label>
        </div>
        <div className="input-group has-validation">
          <PasswordInput
            label={t('login.password')}
            labelClasses="form-label w-100"
            classes={`form-control${!isPasswordValid ? ' is-invalid' : ''}`}
            placeholder=""
            id="passwordField"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            required
          />
          {!isPasswordValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <div className="form-check">
              <label className="form-check-label" htmlFor="rememberMeField">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMeField"
                  data-tracking-event="m.login.buttons.remember.click"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                {t('login.rememberMe')}
              </label>
            </div>
          </div>
          <div className="text-end col">
            <button
              className="btn btn-link link-tertiary"
              type="button"
              onClick={() => navigate('/login/recover')}
            >
              {t('login.recoverPassword')}
            </button>
          </div>
        </div>
        <div className="d-grid gap-2 pt-2">
          <button
            data-testid="loginSubmitBtn"
            type="submit"
            className="btn btn-primary btn-medelse"
          >
            {t('login.button')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
