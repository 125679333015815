import React, { ReactNode } from 'react';
import { IAuthContext } from '../../domain/types/IAuthContext';
import AuthContext from '../context/AuthContext';
import login from '../../domain/useCases/login';
import logout from '../../domain/useCases/logout';
import refreshToken from '../../domain/useCases/refreshToken';
import getToken from '../../domain/useCases/getToken';
import generateTokens from '../../domain/useCases/generateTokens';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const context: IAuthContext = {
    getToken,
    refreshToken,
    signin: login,
    signout: logout,
    generateTokens,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
