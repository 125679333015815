import React, { ReactNode } from 'react';

type Props = {
  message: string | ReactNode
  variant?: 'danger' | 'success' | 'info'
};

const AlertMessage = ({ message: errorMessage, variant }: Props) => {
  let finalMessage: ReactNode;

  try {
    if (typeof errorMessage === 'string') {
      const { message } = JSON.parse(errorMessage);
      finalMessage = message || errorMessage;
    } else {
      finalMessage = errorMessage;
    }
  } catch (error) {
    finalMessage = errorMessage;
  }
  return (
    <div className={`alert alert-${variant || 'danger'}`} role="alert">{finalMessage}</div>
  );
};
AlertMessage.defaultProps = { variant: undefined };

export default AlertMessage;
