import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'app/components/Button';

type Props = {
  onCancel: VoidFunction
  onConfirm: VoidFunction
  variant: string
  closeLabel?: string | undefined
  submitLabel?: string | undefined,
};

const FormActions = ({
  onCancel,
  onConfirm,
  variant,
  closeLabel,
  submitLabel,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="action-block">
      <Button
        onClick={onCancel}
        title={closeLabel || t('actions.cancel')}
        variant="secondary"
      />
      <Button
        onClick={onConfirm}
        title={submitLabel || t('actions.confirm')}
        variant={variant}
      />
    </div>
  );
};

FormActions.defaultProps = {
  closeLabel: undefined,
  submitLabel: undefined,
};

export default FormActions;
