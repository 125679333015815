import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractNicEnum, useUploadFilesMutation } from 'app/generated/graphql';
import { webviewPostProfileSubmission } from 'app/domain/utils';
import QueryIndicator from '../../../app/components/QueryIndicator';
import AlertMessage from '../../../app/components/AlertMessage';
import CardTitle from '../../../app/components/Card/CardTitle';
import FileInput from '../../../app/components/FileInput';
import FormActions from '../../../app/components/FormActions';

type Nic = {
  name: string | null
  lastUpdatedDate: string | null
};

type Props = {
  documents: {
    nicFront: Nic
    nicBack: Nic
  }
  onClose: () => void
  onMutationSuccess: () => void
};

const ProfileIdDocumentsForm = ({ documents, onClose, onMutationSuccess }: Props) => {
  const [nicFront, setNicFront] = useState<File | null>(null);
  const [nicBack, setNicBack] = useState<File | null>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState<string | null>(null);

  const [uploadFile, {
    loading, error,
  }] = useUploadFilesMutation();

  const { t } = useTranslation();

  const handleSubmit = () => {
    if (nicFront) {
      uploadFile({
        variables: {
          file: nicFront,
          side: ContractNicEnum.Front,
        },
        onCompleted() {
          if (!loading) {
            onMutationSuccess();
          }
          webviewPostProfileSubmission();
        },
      });
    }
    if (nicBack) {
      uploadFile({
        variables: {
          file: nicBack,
          side: ContractNicEnum.Back,
        },
        onCompleted() {
          if (!loading) {
            onMutationSuccess();
          }
          webviewPostProfileSubmission();
        },
      });
    }
  };

  const handleFileChange = (file: File, side: 'front' | 'back') => {
    const fileSizeLimit = 5242880;

    if (file.size > fileSizeLimit) {
      setFileErrorMessage(t('profilePage.error.fileSize'));
      return;
    }

    if (side === 'front') {
      setNicFront(file);
    }
    if (side === 'back') {
      setNicBack(file);
    }

    setFileErrorMessage(null);
  };

  return (
    <div>
      <CardTitle>{t('profilePage.profileIdDocumentsForm.title')}</CardTitle>
      {error && <AlertMessage message={error.message} />}
      {fileErrorMessage && <AlertMessage message={fileErrorMessage} />}
      {loading ? <QueryIndicator /> : (
        <>
          <form>
            <div className="mb-4">
              <FileInput
                onChange={(value: File) => handleFileChange(value, 'front')}
                id="nic_front"
                label={t('profilePage.profileIdDocumentsForm.front')}
                lastUpdatedDate={documents.nicFront.lastUpdatedDate}
                isEmpty={nicFront === null && documents.nicFront.name === null}
              />
              <div className="border-bottom border-1 mx-5" />
              <FileInput
                onChange={(value: File) => handleFileChange(value, 'back')}
                id="nic_back"
                label={t('profilePage.profileIdDocumentsForm.back')}
                lastUpdatedDate={documents.nicBack.lastUpdatedDate}
                isEmpty={nicBack === null && documents.nicBack.name === null}
              />
            </div>
          </form>
          <FormActions
            onCancel={onClose}
            onConfirm={handleSubmit}
            variant="primary"
          />
        </>
      )}
    </div>
  );
};

export default ProfileIdDocumentsForm;
