import React, { FormEventHandler } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ApolloError,
  FetchResult,
  gql,
  useMutation,
} from '@apollo/client';

import {
  PartialRegisterInput,
  RegisterCandidatePayload,
  RegisterInput,
  RegisterResultData,
  RegisterVariables,
  UtmDataInput,
} from 'app/domain/registerTypes';
import QueryIndicator from 'app/components/QueryIndicator';
import Button from 'app/components/Button';
import { cguUrl, privacyPolicyUrl } from 'app/constants';
import PasswordInput from 'app/components/PasswordInput';

export const MUTATION = gql`
mutation registerCandidate($input: RegisterCandidateInput!){
  registerCandidate(input: $input) {
    loginToken {
      token
    }
  }
}
`;

const checkEmailRegex = /^\w([.-]?\w+)*(\+\w+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;

type ErrorField = {
  field: string,
  errorMessage: string
};

type UseRegisterStep2 = () => ({
  error: ApolloError | undefined
  invalidFields: Array<string>
  loading: boolean
  register: (
    input: RegisterInput
  ) => Promise<FetchResult<RegisterResultData>>
});

const useRegisterStep2: UseRegisterStep2 = () => {
  const [
    mutate, { loading, error },
  ] = useMutation<RegisterResultData, RegisterVariables>(
    MUTATION,
    { context: { clientName: 'register' } },
  );

  return {
    error,
    invalidFields: error ? error.graphQLErrors.map((e) => {
      if (e.message.startsWith('{')) {
        return JSON.parse(e.message).field;
      }

      return '';
    }) : [],
    loading,
    register: (input: RegisterInput) => mutate(
      { variables: { input } },
    ),
  };
};

type Props = {
  onReturn: VoidFunction
  onSuccess: (tokens: RegisterCandidatePayload['loginToken']) => void
  partialInput: PartialRegisterInput
  utmData: UtmDataInput
};

const RegisterStep2Form = ({
  onReturn,
  onSuccess,
  partialInput,
  utmData,
} : Props) => {
  const { t } = useTranslation();
  const {
    error,
    invalidFields,
    loading,
    register,
  } = useRegisterStep2();

  const [input, setInput] = React.useState<RegisterInput>({
    ...partialInput,
    email: '',
    uncryptedPassword: '',
    cguMedelse: false,
    utmData,
  });
  const [passwordConfirm, setPasswordConfirm] = React.useState<string>('');
  const [frontendInvalidFields, setFrontendInvalidFields] = React.useState<ErrorField[]>([]);

  const getControlClasses = (base: string, fieldName: string, type: 'field' | 'label'):string => {
    const isInvalid = invalidFields.indexOf(fieldName) !== -1
      || frontendInvalidFields.map((e) => e.field).indexOf(fieldName) !== -1;

    if (type === 'field') {
      return `${base} ${isInvalid ? 'is-invalid' : ''}`;
    }
    return `${base} ${isInvalid ? 'text-invalid' : ''}`;
  };

  const handleChange = (field: keyof RegisterInput, value: string) => setInput({
    ...input,
    [field]: value,
  });

  const validateForm = (): boolean => {
    const errors: ErrorField[] = [];

    if (!input.email.length) {
      errors.push({ field: 'email', errorMessage: t('errors.requiredField') });
    } else if (!checkEmailRegex.test(input.email)) {
      errors.push({ field: 'email', errorMessage: t('errors.email-format') });
    }

    if (!input.uncryptedPassword.length) {
      errors.push({ field: 'uncryptedPassword', errorMessage: t('errors.requiredField') });
    }

    if (!passwordConfirm.length) {
      errors.push({ field: 'passwordConfirm', errorMessage: t('errors.requiredField') });
    } else if (passwordConfirm !== input.uncryptedPassword) {
      errors.push({ field: 'passwordConfirm', errorMessage: t('errors.password-missmatch') });
    }

    if (!input.cguMedelse) {
      errors.push({ field: 'cguMedelse', errorMessage: t('errors.requiredField') });
    }

    setFrontendInvalidFields(errors);

    return errors.length === 0;
  };

  const handleOnSubmit: FormEventHandler<HTMLFormElement> = (evt) => {
    evt.preventDefault();

    if (validateForm()) {
      const apiInput: RegisterInput = { ...input };

      if (input.referralCode?.length === 0) {
        delete apiInput.referralCode;
      }

      register(apiInput)
        .then((result) => {
          if (result.data) {
            onSuccess(result.data?.registerCandidate.loginToken);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          {frontendInvalidFields.length > 0 ? (
            <div className="alert alert-danger" role="alert">
              {frontendInvalidFields.map(({ field, errorMessage }) => (
                <div key={`frontError-${field}`}>{errorMessage}</div>
              ))}
            </div>
          ) : null}
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error.graphQLErrors.length ? '' : error.message}
              {error.graphQLErrors.map((e) => {
                let errorLine: string = e.message;
                if (e.message.startsWith('{')) {
                  const { field, message } = JSON.parse(e.message);

                  const fieldName = t(`register.${field}.label`);
                  errorLine = `${fieldName}: ${message}`;
                }

                return (
                  <div key={e.message}>{errorLine}</div>
                );
              })}
            </div>
          ) : null }
          <label htmlFor="email" className={getControlClasses('d-block mb-2', 'email', 'label')}>
            {t('register.email.label')}
            <input
              type="email"
              id="email"
              className={getControlClasses('form-control bg-white', 'email', 'field')}
              placeholder={t('register.email.placeholder')}
              value={input.email}
              onChange={(val) => handleChange('email', val.target.value)}
            />
          </label>
          <PasswordInput
            label={t('register.uncryptedPassword.label')}
            labelClasses={getControlClasses('d-block', 'uncryptedPassword', 'label')}
            classes={getControlClasses('form-control bg-white', 'uncryptedPassword', 'field')}
            placeholder={t('register.uncryptedPassword.placeholder')}
            value={input.uncryptedPassword}
            onChange={(val) => handleChange('uncryptedPassword', val.target.value)}
            id="uncryptedPassword"
          />
          <PasswordInput
            label={t('register.passwordConfirm.label')}
            labelClasses={getControlClasses('d-block', 'passwordConfirm', 'label')}
            classes={getControlClasses('form-control bg-white', 'passwordConfirm', 'field')}
            placeholder={t('register.passwordConfirm.placeholder')}
            value={passwordConfirm}
            onChange={(val) => setPasswordConfirm(val.target.value)}
            id="passwordConfirm"
          />
          <div className="form-check mb-4">
            <label className="form-check-label" htmlFor="cguMedelse">
              <input
                className={getControlClasses('form-check-input', 'cguMedelse', 'field')}
                type="checkbox"
                checked={input.cguMedelse}
                onChange={() => { setInput({ ...input, cguMedelse: !input.cguMedelse }); }}
                id="cguMedelse"
                required
              />
              <Trans i18nKey="register.cguMedelse.label">
                <span>{t('register.cguMedelse.label')}</span>
                <a href={cguUrl} target="_blank" rel="noreferrer">{t('register.cguMedelse.cgu')}</a>
                <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">{t('register.cguMedelse.privacy_policy')}</a>
              </Trans>
            </label>
          </div>
          <div className="d-grid gap-2 pt-2">
            <Button title={t('register.return')} onClick={onReturn} variant="tertiary" />
            <button type="submit" className="btn btn-medelse btn-primary text-uppercase">{t('register.submitRegister')}</button>
          </div>
        </>
      )}
    </form>
  );
};

export default RegisterStep2Form;
