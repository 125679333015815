import React from 'react';
import { useTranslation } from 'react-i18next';

import appStoreLogo from 'assets/apple-app-store.svg';
import playStoreLogo from 'assets/google-play-arrow.svg';
import { appStoreURL, playStoreURL } from 'app/constants';

export interface Props {
  store: 'apple' | 'google'
}

// quite large to handle planet sized screens (iphone XX Max Pro Ultra Wide)
const MOBILE_BREAKPOINT = 1200;

const DownloadAppButton = ({ store }: Props) => {
  const { t } = useTranslation();

  const isDesktopScreen = window.innerWidth > MOBILE_BREAKPOINT
  || window.screen.width > MOBILE_BREAKPOINT;
  const logo = store === 'apple' ? appStoreLogo : playStoreLogo;
  const displayedStore = store === 'apple' ? 'AppStore' : 'PlayStore';
  const link = store === 'apple' ? appStoreURL : playStoreURL;

  const onClick = () => {
    if (!isDesktopScreen) {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <button
      type="button"
      className="button-container bg-white"
      onClick={onClick}
      disabled={isDesktopScreen}
    >
      <div className="logo-container"><img src={logo} alt={`${displayedStore} logo`} /></div>
      <div className="text-container">
        <p>{t('downloadAppView.availableOn')}</p>
        <p className="store-text">{displayedStore}</p>
      </div>
    </button>
  );
};

export default DownloadAppButton;
