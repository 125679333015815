export const ROUTE_TRANSLATIONS = 'https://www.medelse.com/api/translations';

export const SPLIT_LAYOUT_COVER_IMAGE_URL = 'https://static.medelse.com/images/medele-app-bg-image.jpg';

export const apiCandidateURI = `${process.env.REACT_APP_API_URL}/graphql/candidate`;
export const apiregisterURI = `${process.env.REACT_APP_API_URL}/graphql/register`;
export const authAPIRoutes = {
  signIn: '/api/login_check',
  recoverPassword: '/api/password/recover',
  refreshToken: '/api/token/refresh',
  logNavigation: '/log',
};

export const faqURL = 'https://help.medelse.com/professionnels-de-sant%C3%A9';
export const playStoreURL = 'https://play.google.com/store/apps/details?id=com.medelse';
export const appStoreURL = 'https://apps.apple.com/fr/app/medelse/id1533597661';
export const cguUrl = 'https://www.medelse.com/conditions';
export const privacyPolicyUrl = 'https://www.medelse.com/politique-de-confidentialite/';

export const SUPPORT_BOOKING_LINK = 'https://meetings.hubspot.com/medelse/vl';
export const SUPPORT_PHONE_NUMBER_LINK = 'tel:+33187665563';
export const SUPPORT_PHONE_NUMBER = '01 87 66 55 63';

export const ARRAY_ITEMS_PER_PAGE = 10;

export const LOCAL_STORAGE_REMEMBER_KEY = 'medelse-app-remember-me';
export const LOCAL_STORAGE_AUTH_KEY = 'medelse-app-auth';

export const availableCountryCodes = [{
  flag: '🇫🇷', code: 33,
}, {
  flag: '🇬🇵', code: 590,
}, {
  flag: '🇬🇫', code: 594,
}, {
  flag: '🇲🇶', code: 596,
}, {
  flag: '🇾🇹', code: 269,
}, {
  flag: '🇳🇨', code: 687,
}, {
  flag: '🇵🇫', code: 689,
}, {
  flag: '🇷🇪', code: 262,
}, {
  flag: '🇵🇲', code: 508,
}, {
  flag: '🇼🇫', code: 681,
}];

// For components not relying on scss
export const theme = {
  primary: '#7B3081',
  secondary: '#0E5168',
  tertiary: '#BAC0C4',
  success: '#007E4C',
  danger: '#007E4C',
};
