import React from 'react';

import recoverPasswordUseCase from '../../domain/useCases/recoverPassword';

const useRecover = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const recoverPassword = (email: string, onSuccess: VoidFunction) => {
    setLoading(true);
    recoverPasswordUseCase(email)
      .then(() => {
        setSuccess(true);
        onSuccess();
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  };

  return {
    loading,
    success,
    error,
    recoverPassword,
  };
};

export default useRecover;
