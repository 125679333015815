import React, { ChangeEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

type PasswordInputProps = {
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  label: string
  labelClasses: string
  placeholder: string
  classes: string
  id: string
  required?: boolean
};

const PasswordInput = ({
  value, onChange, label, placeholder, labelClasses, classes, id, required,
}: PasswordInputProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const toggleId = `${label}-toggle`;

  return (
    <>
      <label htmlFor={id} className={labelClasses}>
        {label}
      </label>
      <div className="input-group mb-2">
        <input
          type={visible ? 'text' : 'password'}
          id={id}
          data-testid={id}
          aria-describedby={toggleId}
          aria-labelledby={id}
          className={classes}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id={toggleId}
          aria-labelledby={toggleId}
          data-testid={toggleId}
          onClick={() => setVisible(!visible)}
        >
          <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
        </button>
      </div>
    </>
  );
};

PasswordInput.defaultProps = {
  required: false,
};

export default PasswordInput;
