import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Modal from 'app/components/Modal';
import CardTitle from 'app/components/Card/CardTitle';
import Button from 'app/components/Button';
import { CandidateAccountStatusEnum, useCheckAccountStatusQuery } from 'app/generated/graphql';

const CheckAccountStatus = () => {
  const { t } = useTranslation();
  const [isAccountModalVisible, setIsAccountModalVisible] = React.useState<boolean>(false);

  const { data } = useCheckAccountStatusQuery();

  React.useEffect(() => {
    if (
      data?.me?.accountStatus === CandidateAccountStatusEnum.Off
      && data?.me?.hasProfileFilled === true
    ) {
      setIsAccountModalVisible(true);
    }
  }, [data]);

  return (
    <Modal onClose={() => setIsAccountModalVisible(false)} visible={isAccountModalVisible}>
      <CardTitle>{t('checkAccountStatus.title')}</CardTitle>
      <div className="d-flex justify-content-center p-2">
        <FontAwesomeIcon
          className="text-black"
          icon={faWarning}
          role="img"
          fontSize={30}
        />
      </div>
      <p>{t('checkAccountStatus.body1')}</p>
      <p>{t('checkAccountStatus.body2')}</p>
      <Button
        onClick={() => setIsAccountModalVisible(false)}
        title={t('actions.close')}
        variant="secondary"
      />
    </Modal>
  );
};

export default CheckAccountStatus;
