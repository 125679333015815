import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAcceptCguMutation } from 'app/generated/graphql';
import { cguUrl } from 'app/constants';
import Button from 'app/components/Button';
import Card from 'app/components/Card';
import CardTitle from 'app/components/Card/CardTitle';
import QueryIndicator from 'app/components/QueryIndicator';

export type Event = 'dashboard' | 'logout' | 'retry' | 'open-cgu';

type Props = {
  needToValidateCGU: boolean
  onAction: (event: Event) => void
};

const CGUForm = ({ needToValidateCGU, onAction }: Props) => {
  const { t } = useTranslation();
  const isReactNativeWebView = window.ReactNativeWebView !== undefined;

  const [acceptCguMutation, { loading, error }] = useAcceptCguMutation({
    onCompleted(res) {
      if (res.acceptCGU.profile.userNeedCguValidation === true) {
        onAction('dashboard');
      }
    },
  });

  const handleAcceptCGU = () => {
    acceptCguMutation()
      .then(() => onAction('dashboard'))
      .catch(() => {});
  };

  const handleCGULinkPress = () => {
    onAction('open-cgu');
  };

  return (
    <div className="cgu-container">
      <div className="cgu-container-content">
        <Card>
          <CardTitle>{t('cgu.title')}</CardTitle>
          <p className="mt-3">
            {t('cgu.content')}
          </p>
          <p className="mt-3">
            <span className="me-1">{t('cgu.details')}</span>
            {isReactNativeWebView ? (
              <button type="button" className="btn btn-link" onClick={handleCGULinkPress}>
                {t('cgu.link')}
              </button>
            ) : (
              <a href={cguUrl} target="_blank" rel="noreferrer">
                {t('cgu.link')}
              </a>
            )}
          </p>
          <div>{t('cgu.desc')}</div>
          {error ? (
            <div className="alert alert-danger">{error.message}</div>
          ) : null}
          {needToValidateCGU ? (
            <div className="d-flex justify-content-between mt-3">
              {loading ? (
                <div className="d-flex w-100 justify-content-center">
                  <QueryIndicator />
                </div>
              ) : (
                <>
                  <Button
                    onClick={() => onAction('logout')}
                    title={t('cgu.logout')}
                    variant="tertiary"
                  />
                  <Button
                    onClick={handleAcceptCGU}
                    title={t('cgu.accept')}
                    variant="primary"
                  />
                </>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-end mt-3">
              <Button
                onClick={() => onAction('dashboard')}
                title={t('cgu.dashboard')}
                variant="primary"
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default CGUForm;
