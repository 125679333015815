import TagManager from 'react-gtm-module';

const initialize = () => {
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };

    TagManager.initialize(tagManagerArgs);
  }
};

export default initialize;
