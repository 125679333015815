import axios from 'axios';
import { ROUTE_TRANSLATIONS } from '../app/constants';
import frTranslations from './resources/fr';

class TranslationsService {
  localTranslations: any;

  constructor() {
    this.localTranslations = frTranslations;
  }

  initResources(): Promise<object> {
    return new Promise((resolve) => {
      axios.get(ROUTE_TRANSLATIONS)
        .then(({ data }) => {
          resolve({
            ...this.localTranslations,
            ...data.fr,
          });
        })
        .catch(() => {
          resolve(this.localTranslations);
        });
    });
  }
}

export default TranslationsService;
