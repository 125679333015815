import { formatDateTime } from '../../../../../app/domain/utils';
import { GetContractsQuery } from '../../../../../app/generated/graphql';
import { Contract } from '../../../domain/models';
import { IContractRepository } from '../../../domain/repository/IContractRepository';

export default class ContractRepository implements IContractRepository {
  private contracts: Contract[];

  constructor(data: GetContractsQuery) {
    if (!data.me || !data.me.contracts || !data.me.contracts.edges) {
      this.contracts = [];
    }

    this.contracts = data.me!.contracts!.edges!.map((edge) => {
      const contract = edge!.node!;
      const contractDetailsDates: string[] = contract.contractDetails!
        .map((cd) => cd?.dateTimes.startDate);

      return ({
        contractNumber: contract.contractNumber,
        office: `${contract.groupOffice!.name} (${contract.groupOffice!.zipCode})`,
        dates: contractDetailsDates.map((date) => formatDateTime(date)).join('; '),
        packageStatus: contract.packageStatus,
        downloadURL: contract.documentDownloadLink,
      });
    });
  }

  getContracts() {
    return this.contracts;
  }
}
