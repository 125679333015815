import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageLayout from 'app/components/PageLayout';
import useAuth from 'auth/view/hooks/useAuth';
import Parameters from 'features/Parameters';

const SettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <PageLayout navigateTo={(path) => navigate(path)} title={t('pages.settings')}>
      <Parameters onAccountDeleted={() => auth.signout(() => navigate('/'))} />
    </PageLayout>
  );
};

export default SettingsPage;
