import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-bootstrap';

import Card from 'app/components/Card';
import CardTitle from 'app/components/Card/CardTitle';
import Button from 'app/components/Button';
import Modal from 'app/components/Modal';
import QueryIndicator from 'app/components/QueryIndicator';
import { GetParametersQueryResult, useGetParametersQuery } from 'app/generated/graphql';
import Toast from 'app/components/Toast';
import Field from 'app/components/Field';
import ParametersEmailForm from './ParametersEmailForm';
import ParametersPasswordForm from './ParametersPasswordForm';
import ParametersDeleteAccountForm from './ParametersDeleteAccountForm';

type Props = { onAccountDeleted: VoidFunction };
const Parameters = ({ onAccountDeleted }: Props) => {
  const [emailFormVisible, setEmailFormVisible] = useState<boolean>(false);
  const [emailToastVisible, setEmailToastVisible] = useState<boolean>(false);

  const [passwordFormVisible, setPasswordFormVisible] = useState<boolean>(false);
  const [passwordToastVisible, setPasswordToastVisible] = useState<boolean>(false);

  const [deleteAccountFormVisible, setDeleteAccountFormVisible] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    data,
    error,
    loading,
  } = useGetParametersQuery({ notifyOnNetworkStatusChange: true });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div className="alert alert-danger">{error.message}</div>;
  }

  const userData = (data as GetParametersQueryResult['data'])!.me!;
  const { profile } = userData;

  return (
    <div className="parameter-container">
      <div className="parameter-container-content">
        <Card>
          <CardTitle>{t('parametersPage.titleCredentials')}</CardTitle>
          <div className="py-2">
            <Field
              id="email"
              label={t('parametersPage.labelEmail')}
              value={profile.email}
              onEdit={() => setEmailFormVisible(true)}
            />
          </div>
          <div className="py-2">
            <Field
              id="password"
              label={t('parametersPage.labelPassword')}
              value="******"
              onEdit={() => setPasswordFormVisible(true)}
              type="password"
            />
          </div>
        </Card>
        <div style={{ margin: '25px 0' }}>
          <Button
            title={t('parametersPage.deleteAccount')}
            onClick={() => setDeleteAccountFormVisible(true)}
            variant="negative"
          />
        </div>
      </div>
      <Modal
        onClose={() => setEmailFormVisible(false)}
        visible={emailFormVisible}
      >
        <ParametersEmailForm
          userProfile={userData}
          onCancel={() => setEmailFormVisible(false)}
          onMutationSuccess={() => {
            setEmailFormVisible(false);
            setEmailToastVisible(true);
          }}
        />
      </Modal>
      <Modal
        onClose={() => setPasswordFormVisible(false)}
        visible={passwordFormVisible}
      >
        <ParametersPasswordForm
          onCancel={() => setPasswordFormVisible(false)}
          onMutationSuccess={() => {
            setPasswordFormVisible(false);
            setPasswordToastVisible(true);
          }}
        />
      </Modal>
      <Modal
        onClose={() => setDeleteAccountFormVisible(false)}
        visible={deleteAccountFormVisible}
      >
        <ParametersDeleteAccountForm
          onCancel={() => setDeleteAccountFormVisible(false)}
          onMutationSuccess={onAccountDeleted}
        />
      </Modal>
      <ToastContainer style={{ width: '25vw' }} className="p-2" position="top-end">
        <Toast
          show={emailToastVisible}
          onClose={() => setEmailToastVisible(false)}
          message={t('parametersEmailForm.successMessage')}
        />
        <Toast
          show={passwordToastVisible}
          onClose={() => setPasswordToastVisible(false)}
          message={t('parametersPasswordForm.successMessage')}
        />
      </ToastContainer>
    </div>
  );
};

export default Parameters;
