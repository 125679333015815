import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardTitle from 'app/components/Card/CardTitle/CardTitle';
import TextField from 'app/components/TextField';
import FormActions from 'app/components/FormActions';
import QueryIndicator from 'app/components/QueryIndicator';
import AlertMessage from 'app/components/AlertMessage';
import { useUpdateCandidateIbanMutation } from 'app/generated/graphql';
import { webviewPostProfileSubmission } from 'app/domain/utils';

type Props = {
  iban: string
  onClose: () => void
  onMutationSuccess: () => void
};

const ProfileIbanForm = ({ iban, onClose, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState<string>('');

  const [update, { loading, error }] = useUpdateCandidateIbanMutation();

  const handleSubmit = () => {
    update({
      variables: { input: newValue },
      onCompleted() {
        onMutationSuccess();
        webviewPostProfileSubmission();
      },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('profilePage.profileIbanForm.title')}</CardTitle>
      {error && <AlertMessage message={error.message} />}
      {loading ? <QueryIndicator /> : (
        <>
          <form>
            <div style={{ marginBottom: 20 }}>
              {iban && (
                <TextField
                  type="text"
                  label={t('profilePage.profileIbanForm.currentIban')}
                  id="ibanCurrent"
                  value={iban}
                  disabled
                  onChange={() => {}}
                />
              )}
            </div>
            <div>
              <TextField
                type="text"
                label={t('profilePage.profileIbanForm.newIban')}
                id="ibanNew"
                value={newValue}
                onChange={setNewValue}
              />
            </div>
          </form>
          <FormActions
            onCancel={onClose}
            onConfirm={handleSubmit}
            variant="primary"
          />
        </>
      )}
    </div>
  );
};

export default ProfileIbanForm;
