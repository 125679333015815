import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useTracking from 'app/domain/useTracking';
import useAuth from './hooks/useAuth';

type Props = { children: JSX.Element };

const RequireAnonymous = ({ children }: Props) => {
  const auth = useAuth();
  const { manageTracking } = useTracking();
  const location = useLocation();

  const token = auth.getToken();

  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  manageTracking(null);

  return children;
};

export default RequireAnonymous;
