import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import { useGetContractsQuery } from '../../../app/generated/graphql';
import { Contract } from '../domain/models';
import { downloadDocument } from '../../../app/domain/utils';
import { GetEntityListUsecase } from '../../../app/domain/GetEntityListUsecase';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import ContractRepository from './data/repository/ContractRepository';

const useContracts: GetEntityListUsecase<Contract> = () => {
  let contracts: Contract[] | undefined;
  const { error, loading, data } = useGetContractsQuery();

  if (data) {
    const repo = new ContractRepository(data);

    contracts = repo.getContracts();
  }

  return {
    loading,
    entities: contracts,
    errorMessage: error?.message,
  };
};

const ContractTable = () => {
  const { t } = useTranslation();
  const {
    entities,
    errorMessage,
    loading,
  } = useContracts();

  const columns: ColumnDef<Contract>[] = [
    {
      accessorKey: 'contractNumber',
      header: () => t('contract.contractNumber'),
    },
    {
      id: 'groupOffice',
      header: () => t('contract.groupOffice'),
      accessorFn: (row) => row.office,
    },
    {
      id: 'packageStatus',
      header: () => t('contract.status'),
      accessorFn: (row) => t(`enum.medelse_core_bundle_entity_enum_contract_status_${row.packageStatus?.toLowerCase()}`),
    },
    {
      id: 'dates',
      header: () => t('contract.dates'),
      accessorFn: (row) => row.dates,
    },
    {
      id: 'download',
      header: () => t('contract.download'),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => (
        <button
          type="button"
          className="btn border-0 text-primary"
          data-testid="donwload_contract"
          aria-labelledby="Download contract"
          onClick={() => downloadDocument(row.original.downloadURL)}
        >
          <FontAwesomeIcon icon={faFileArrowDown} />
        </button>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];

  if (loading) {
    return <QueryIndicator />;
  }

  if (errorMessage) {
    return <div className="alert alert-danger">{errorMessage}</div>;
  }

  return (
    <Table
      title={t('contracts.tableTitle')}
      pageSize={ARRAY_ITEMS_PER_PAGE}
      columns={columns}
      data={entities!}
      canBeFiltered
    />
  );
};

export default ContractTable;
