import { faqURL } from 'app/constants';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import logoMedelse from '../../../assets/logo_medelse.svg';
import useAuth from '../../../auth/view/hooks/useAuth';

type Props = {
  children: JSX.Element
  title: string
  navigateTo: (path: string) => void
};

type NavItemProp = {
  active: boolean
  isRed?: boolean
  title: string,
  onClick: VoidFunction
  hasBottomBorder?: boolean
};

const NavItem = ({
  active,
  hasBottomBorder,
  isRed,
  onClick,
  title,
}: NavItemProp) => {
  const classes: string[] = ['border-primary'];

  if (active) {
    classes.push('is-active');
  }

  if (hasBottomBorder) {
    classes.push('border-bottom');
  }

  if (active) {
    classes.push('fw-semibold');
  }

  if (isRed) {
    classes.push('is-red');
  }

  return (
    <NavDropdown.Item
      as="button"
      onClick={onClick}
      className={classes.join(' ')}
    >
      {title}
    </NavDropdown.Item>
  );
};
NavItem.defaultProps = { hasBottomBorder: false, isRed: false };

const LogoutItem = ({ title }: Pick<NavItemProp, 'title'>) => (
  <a
    data-rr-ui-dropdown-item=""
    className="border-primary dropdown-item"
    href={faqURL}
    target="_blank"
    rel="noreferrer"
  >
    {title}
  </a>
);

const PageLayout = ({ children, navigateTo, title }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const auth = useAuth();

  const logout = () => auth.signout(() => navigateTo('/'));

  return (
    <div id="page-layout" className="min-vh-100 bg-body">
      <Navbar expand="lg" className="bg-white shadow-sm">
        <Container>
          <button
            className="btn border-0"
            type="button"
            onClick={() => navigateTo('/')}
          >
            <img
              src={logoMedelse}
              alt="Medelse logo"
            />
          </button>
          <Nav>
            <NavDropdown
              id="main-navbar-dropdown"
              className="fw-semibold border-primary"
              title={title}
            >
              {auth.getToken() === null ? (
                <>
                  <NavItem title={t('pages.login')} onClick={() => navigateTo('/login')} active={false} hasBottomBorder />
                  <NavItem title={t('pages.register')} onClick={() => navigateTo('/register')} active={pathname === '/register'} />
                </>
              ) : (
                <>
                  <NavItem title={t('pages.home')} onClick={() => navigateTo('/')} active={pathname === '/'} hasBottomBorder />
                  <NavItem title={t('pages.profile')} onClick={() => navigateTo('/profile')} active={pathname === '/profile'} hasBottomBorder />
                  <NavItem title={t('pages.contracts')} onClick={() => navigateTo('/contracts')} active={pathname === '/contracts'} hasBottomBorder />
                  <NavItem title={t('pages.invoices')} onClick={() => navigateTo('/invoices')} active={pathname === '/invoices'} hasBottomBorder />
                  <NavItem title={t('pages.feesInvoices')} onClick={() => navigateTo('/fees-invoices')} active={pathname === '/fees-invoices'} hasBottomBorder />
                  <NavItem title={t('pages.settings')} onClick={() => navigateTo('/settings')} active={pathname === '/settings'} hasBottomBorder />
                  <LogoutItem title={t('pages.faq')} />
                  <NavItem title={t('logout')} onClick={logout} active={false} isRed />
                </>
              )}
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <main>
        <Container className="px-2 py-5">
          {children}
        </Container>
      </main>
    </div>
  );
};

export default PageLayout;
