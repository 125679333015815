import React from 'react';
import {
  BrowserRouter, Navigate, Routes as RouterElements, Route,
} from 'react-router-dom';

import Autolog from 'auth/view/Autolog';
import Profile from 'features/Profile';
import Parameters from 'features/Parameters';
import Chatbot from 'features/chatbot/Chatbot';
import CGUPage from 'features/CGU/CGUPage';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import RecoverPage from './pages/RecoverPage';
import DashboardPage from './pages/DashboardPage';
import ContractsPage from './pages/ContractsPage';
import InvoicesPage from './pages/InvoicesPage';
import ProfilePage from './pages/ProfilePage';
import ParametersPage from './pages/ParametersPage';
import RegisterPage from './pages/RegisterPage';
import RequireAnonymous from '../auth/view/RequireAnonymous';
import RequireAuth from '../auth/view/RequireAuth';
import MedelseFeesInvoicesPage from './pages/MedelseFeesInvoicesPage';

type WebViewWrapperProps = { children: React.ReactNode };
const WebViewWrapper = ({ children }: WebViewWrapperProps) => (
  <div className="p-4">{children}</div>
);

export const Routes = () => (
  <RouterElements>
    <Route
      path="/"
      element={(
        <RequireAuth>
          <DashboardPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/contracts"
      element={(
        <RequireAuth>
          <ContractsPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/invoices"
      element={(
        <RequireAuth>
          <InvoicesPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/fees-invoices"
      element={(
        <RequireAuth>
          <MedelseFeesInvoicesPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/profile"
      element={(
        <RequireAuth>
          <ProfilePage />
        </RequireAuth>
      )}
    />
    <Route
      path="/settings"
      element={(
        <RequireAuth>
          <ParametersPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/cgu-update"
      element={(
        <RequireAuth>
          <CGUPage />
        </RequireAuth>
      )}
    />
    <Route
      path="/cgu-update/:refreshToken"
      element={(
        <Autolog>
          <CGUPage />
        </Autolog>
      )}
    />
    <Route
      path="/mobile-profile/:refreshToken"
      element={(
        <Autolog>
          <WebViewWrapper>
            <Profile />
          </WebViewWrapper>
        </Autolog>
      )}
    />
    <Route
      path="/mobile-settings/:refreshToken"
      element={(
        <Autolog>
          <WebViewWrapper>
            <Parameters onAccountDeleted={() => {}} />
          </WebViewWrapper>
        </Autolog>
      )}
    />
    <Route
      path="/mobile-chatbot/:refreshToken"
      element={(
        <Autolog>
          <Chatbot />
        </Autolog>
      )}
    />
    <Route
      path="/arsarot/:refreshToken"
      element={(
        <Autolog>
          <Navigate to="/" replace />
        </Autolog>
      )}
    />
    <Route
      path="/login"
      element={(
        <RequireAnonymous>
          <LoginPage />
        </RequireAnonymous>
      )}
    />
    <Route
      path="/login/recover"
      element={(
        <RequireAnonymous>
          <RecoverPage />
        </RequireAnonymous>
      )}
    />
    <Route
      path="/register"
      element={(
        <RequireAnonymous>
          <RegisterPage />
        </RequireAnonymous>
      )}
    />
    <Route path="*" element={<NotFoundPage />} />
  </RouterElements>
);

const Router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default Router;
