import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { theme } from 'app/constants';

export interface FileInputProps {
  id: string
  label?: string
  onChange: (val: File) => void
  lastUpdatedDate?: string | null
  isEmpty: boolean
}

const VALID_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
];

const FileInput = ({
  id, label, onChange, lastUpdatedDate, isEmpty,
}: FileInputProps) => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const validateFileType = (type: string): boolean => VALID_FILE_TYPES.includes(type);

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = evt.target.files;
    if (!fileList || fileList.length === 0) return;

    const isTypeValid = validateFileType(fileList[0].type);

    setHasError(!isTypeValid);

    if (isTypeValid) {
      onChange(fileList[0]);
    }
  };

  const labelIcon = {
    type: isEmpty ? faCircleXmark : faCircleCheck,
    color: isEmpty ? theme.danger : theme.success,
    testId: isEmpty ? 'file-missing-icon' : 'file-valid-icon',
  };

  return (
    <div className="py-3">

      <div className="row w-100 m-0">
        {hasError ? (
          <div className="text-danger">{t('profilePage.error.fileType')}</div>
        ) : null}
        <label htmlFor={`field-${id}`} id={`field-label-${id}`} className="d-flex w-100 my-2 p-0 col" style={{ cursor: 'pointer' }}>
          <div className="d-flex gap-4 py-1">
            <span className="text-dark">{label}</span>
            <div className="d-inline">
              <FontAwesomeIcon data-testid={labelIcon.testId} size="xl" color={labelIcon.color} icon={labelIcon.type} />
            </div>
          </div>
          <input
            data-testid={`field-${id}`}
            className="d-none"
            id={`field-${id}`}
            onChange={(
              evt: React.ChangeEvent<HTMLInputElement>,
            ) => handleFileChange(evt)}
            type="File"
            accept={VALID_FILE_TYPES.join(', ')}
            placeholder={t('fileInput.noFile')}
          />
          <div className="col w-100 p-0 d-flex gap-2 justify-content-end align-items-end">
            <span className="text-primary"><u><strong>{t('fileInput.update')}</strong></u></span>
            <FontAwesomeIcon data-testid="download-icon" size="xl" color={theme.primary} icon={faFileUpload} />
          </div>
        </label>
        {lastUpdatedDate && (
          <div className="p-0">
            <span className="col-auto fst-italic text-muted" style={{ fontSize: 12 }}>{`${t('date.lastUpdated')} ${lastUpdatedDate}`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

FileInput.defaultProps = {
  label: undefined,
  lastUpdatedDate: undefined,
};

export default FileInput;
