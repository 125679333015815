import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FileFieldProps = {
  id: string
  label: string
  onEdit: () => void
  fileCount: number
  totalFileCount: number
};

type EditButtonProps = { id: string, onEdit: VoidFunction };

const EditButton = ({ onEdit, id }: EditButtonProps) => (
  <button
    onClick={onEdit}
    type="button"
    data-testid={`${id}-edit-icon`}
    aria-labelledby="Edit"
    className="edit-button"
  >
    <FontAwesomeIcon
      className="text-primary"
      icon={faEdit}
      role="img"
      fontSize={18}
    />
  </button>
);

const FileField = ({
  id, label, onEdit, fileCount, totalFileCount,
}: FileFieldProps) => (
  <div
    id={id}
    className="row py-2 mh-40"
  >
    <span className="col text-dark">{label}</span>
    <span className="col-auto d-flex align-items-center">{`${fileCount}/${totalFileCount} fichiers`}</span>
    <div className="col-auto d-flex self-center">
      {onEdit !== undefined && <EditButton id="file-field" onEdit={onEdit} />}
    </div>
  </div>
);

export default FileField;
