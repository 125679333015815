/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, ToastContainer } from 'react-bootstrap';

import {
  GenderEnum,
  Phone,
  useGetUserProfileQuery,
  UserCanExerciseEnum,
  useUpdateCandidateProfileMutation,
  File as NicFile,
  CandidateAccountStatusEnum,
} from 'app/generated/graphql';
import {
  ProfileFieldsValidationInput, SpecialtiesData, Specialty, City, ProfileInputValidation,
} from 'app/domain/profileTypes';
import CardTitle from 'app/components/Card/CardTitle';
import Card from 'app/components/Card';
import TextField from 'app/components/TextField';
import Button from 'app/components/Button';
import PhoneNumberInput from 'app/components/PhoneNumberInput';
import QueryIndicator from 'app/components/QueryIndicator';
import Field from 'app/components/Field';
import Modal from 'app/components/Modal';
import AlertMessage from 'app/components/AlertMessage';
import Toast from 'app/components/Toast';
import FileField from 'features/Profile/FileField/FileField';
import {
  formatDateTime,
  getLastUpdatedDate,
  isWebview,
  webviewNavigateToAssistance,
  webviewPostProfileSubmission,
} from 'app/domain/utils';
import {
  SUPPORT_BOOKING_LINK,
  SUPPORT_PHONE_NUMBER,
  SUPPORT_PHONE_NUMBER_LINK,
  theme,
} from 'app/constants';
import CustomSearchDropdown from './CustomSearchDropdown/CustomSearchDropdown';
import ProfileIbanForm from './ProfileIbanForm';
import ProfileIdDocumentsForm from './ProfileIdDocumentsForm/ProfileIdDocumentsForm';

type AlertPresenterProps = {
  isAccountOff: boolean | undefined
  webview: boolean
  hasCompleteProfile: boolean | undefined
  errorMessage: string | null
  isLiberal: boolean
};

const AlertPresenter = ({
  isAccountOff, webview, hasCompleteProfile, errorMessage, isLiberal,
}: AlertPresenterProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isAccountOff && webview && (
        <AlertMessage message={(
          <Trans i18nKey="profilePage.error.accountOffWebview">
            <span>{t('profilePage.error.accountOffWebview')}</span>
            <button className="btn-link border-0 bg-transparent text-secondary p-0" type="button" onClick={() => webviewNavigateToAssistance()}>1</button>
          </Trans>
        )}
        />
      )}
      {isAccountOff && !webview && (
      <AlertMessage message={(
        <Trans i18nKey="profilePage.error.accountOffBooking" values={{ number: SUPPORT_PHONE_NUMBER }}>
          <span>
            {t('profilePage.error.accountOffBooking')}
          </span>
          <a href={SUPPORT_BOOKING_LINK} target="_blank" rel="noreferrer">{t('profilePage.error.accountOffBooking')}</a>
          <a href={SUPPORT_PHONE_NUMBER_LINK} target="_blank" rel="noreferrer">{SUPPORT_PHONE_NUMBER}</a>
        </Trans>
          )}
      />
      )}

      {(!hasCompleteProfile && !isLiberal) && (
        <AlertMessage message={t('profilePage.info.notLiberal')} variant="info" />
      )}

      {(!hasCompleteProfile && isLiberal) && (
        <AlertMessage message={t('profilePage.info.header')} variant="info" />
      )}

      {errorMessage && (
        <AlertMessage message={errorMessage} />
      )}
    </>
  );
};

const Profile = () => {
  const [ibanFormVisible, setIbanFormVisible] = useState<boolean>(false);
  const [idDocumentsFormVisible, setIdDocumentsFormVisible] = useState<boolean>(false);
  const [otherSpecialtiesVisible, setOtherSpecialtiesVisible] = useState<boolean>(false);
  const [profileToastVisible, setProfileToastVisible] = useState<boolean>(false);
  const [validateErrors, setValidateErrors] = useState<null | ProfileInputValidation>(null);
  const { loading, error, data } = useGetUserProfileQuery({ notifyOnNetworkStatusChange: true });
  const [update, {
    loading: updateLoading, error: updateError,
  }] = useUpdateCandidateProfileMutation({
    onCompleted() {
      setProfileToastVisible(true);
      webviewPostProfileSubmission();
    },
    onError() {},
  });

  const userProfile = data && data.me;
  const hasCompleteProfile = data?.me?.hasProfileFilled;
  const accountStatus = data?.me?.accountStatus;
  const specialtiesData: SpecialtiesData | null = (data && data.Specialties)
    ? data.Specialties as SpecialtiesData : null;
  const { t } = useTranslation();
  const [input, setInput] = useState<ProfileFieldsValidationInput>({
    gender: GenderEnum.None,
    firstName: null,
    lastName: null,
    phone: { code: 33, number: '' },
    address: null,
    city: null,
    zipCode: null,
    authorization: null,
    primarySpecialty: null,
    otherSpecialties: [],
    rppsNumber: '',
    siret: '',
    proIban: '',
    nicFront: null,
    nicBack: null,
  });

  useEffect(() => {
    const nicFront = userProfile?.nic ? userProfile?.nic!.find((i) => i?.name === 'document.nic_front') as NicFile : null;
    const nicBack = userProfile?.nic ? userProfile?.nic!.find((i) => i?.name === 'document.nic_back') as NicFile : null;

    setInput((prevInput: ProfileFieldsValidationInput) => ({
      ...prevInput,
      gender: userProfile?.profile?.gender || GenderEnum.None,
      firstName: userProfile?.profile?.firstName || null,
      lastName: userProfile?.profile?.lastName || null,
      phone: { code: userProfile?.profile?.phone?.code || 33, number: userProfile?.profile?.phone?.number || '' },
      address: userProfile?.profile?.location?.address || null,
      city: userProfile?.profile?.location?.city ? { id: userProfile.profile.location.city.id, name: userProfile.profile.location.city.name || '' } : null,
      zipCode: userProfile?.profile?.location?.zipCode || null,
      authorization: userProfile?.proInformation.canExercise || null,
      primarySpecialty: userProfile?.specialties[0] || null,
      otherSpecialties: userProfile?.specialties.slice(1) || [],
      rppsNumber: userProfile?.proInformation?.rppsNumber || '',
      siret: userProfile?.proInformation?.siret || '',
      proIban: userProfile?.iban || '',
      nicFront,
      nicBack,
    }));
  }, [userProfile, data]);

  const transformSpecialtiesData = (specialtiesInput: SpecialtiesData | null) => (
    specialtiesInput && specialtiesData!.edges.filter(((specialty) => specialty.node.isActive))
      .map((specialty) => ({ id: specialty.node.id, name: specialty.node.name }))
  );

  function extractErrorMessages(errors: ProfileInputValidation) {
    const errorMessages = Object.values(errors)
      .filter((message) => message !== null)
      .map((message) => message);

    const incompleteMessage = t('profilePage.error.incomplete');
    return `${`${incompleteMessage} ${errorMessages.join(', ')}`}`;
  }

  const handleChange = (
    fieldName: keyof ProfileFieldsValidationInput,
    value: string | GenderEnum | UserCanExerciseEnum | Specialty | File | Phone,
  ) => {
    if (fieldName === 'otherSpecialties') {
      if (typeof value !== 'string' && 'id' in value) {
        setInput((prevInput: ProfileFieldsValidationInput) => {
          const existingSpecialties = prevInput.otherSpecialties || [];
          const index = existingSpecialties.findIndex(
            (specialty: Specialty) => specialty.id === value.id,
          );
          let newSpecialties;

          if (index === -1) {
            newSpecialties = [...existingSpecialties, value];
          } else {
            newSpecialties = [
              ...existingSpecialties.slice(0, index),
              ...existingSpecialties.slice(index + 1),
            ];
          }

          return { ...prevInput, [fieldName]: newSpecialties };
        });
      }
    } else {
      setInput((prevInput) => ({ ...prevInput, [fieldName]: value }));
    }
  };

  const handleSubmit = () => {
    let finalSpecialties;
    if (input && input.primarySpecialty && input.primarySpecialty.id) {
      finalSpecialties = [input.primarySpecialty!.id]
        .concat(input.otherSpecialties!
          .map((speciality) => speciality.id));
    }

    if (!input.address || !input.zipCode || !input.firstName || !input.lastName || input.phone.number === '' || input.city === null) {
      setValidateErrors({
        address: !input.address ? t('profilePage.error.address') : null,
        zipCode: !input.zipCode ? t('profilePage.error.zipCode') : null,
        firstName: !input.firstName ? t('profilePage.error.firstName') : null,
        lastName: !input.lastName ? t('profilePage.error.lastName') : null,
        phoneNumber: input.phone.number === '' ? t('profilePage.error.phoneNumber') : null,
        city: input.city === null ? t('profilePage.error.city') : null,
      });
    } else if (userProfile) {
      setValidateErrors(null);
      update({
        variables: {
          input: {
            gender: input.gender,
            firstName: input.firstName,
            lastName: input.lastName,
            phone: input.phone,
            canExercise: input.authorization,
            rppsNumber: input.rppsNumber,
            siret: input.siret,
            specialities: finalSpecialties,
            localisation: {
              address: input.address,
              city: input.city ? input.city?.id : '',
              zipCode: input.zipCode,
            },
          },
        },
      });
    }
  };

  if (loading || updateLoading) {
    return <QueryIndicator />;
  }

  const isAccountOff = hasCompleteProfile && accountStatus === CandidateAccountStatusEnum.Off;
  const hasError = error || updateError || validateErrors;
  const isLiberal = userProfile?.proInformation.isLiberal ?? false;

  const errorMessage = hasError ? error?.message || (validateErrors && extractErrorMessages(validateErrors)) || updateError?.message || '' : null;

  return (
    <main className="container justify-self-center">
      <AlertPresenter
        isAccountOff={isAccountOff}
        webview={isWebview()}
        hasCompleteProfile={hasCompleteProfile}
        errorMessage={errorMessage}
        isLiberal={isLiberal}
      />
      <div className="row">
        <section className="col-md-6 mb-4">
          <Card h100>
            <CardTitle>{t('profilePage.titles.personalInfo')}</CardTitle>
            <div className="d-flex gap-4 justify-content-around mt-3">
              <label htmlFor="medelse-dropdown" className="text-field-label">
                <span>{t('profilePage.fieldLabels.gender')}</span>
                <DropdownButton
                  className="border-5 border-red"
                  id="medelse-dropdown"
                  title={input.gender ? t(`gender.${input.gender}`) : t('profilePage.fieldLabels.gender')}
                >
                  {Object.values(GenderEnum).map((gender) => (
                    <Dropdown.Item onClick={() => handleChange('gender', gender)} key={gender}>
                      {t(`gender.${gender}`)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </label>
              <TextField
                onChange={(value: string) => { handleChange('firstName', value); }}
                error={validateErrors?.firstName ? true : false}
                id="firstName"
                label={t('profilePage.fieldLabels.firstName')}
                value={input.firstName || ''}
              />
              <TextField
                onChange={(value: string) => { handleChange('lastName', value); }}
                error={validateErrors?.lastName ? true : false}
                id="lastName"
                label={t('profilePage.fieldLabels.lastName')}
                value={input.lastName || ''}
              />
            </div>
            <div className="mt-3">
              <PhoneNumberInput
                phoneValue={input.phone}
                onChange={(e) => handleChange('phone', { code: e.code!, number: e.number! })}
                label={t('profilePage.fieldLabels.phone')}
                error={validateErrors?.phoneNumber ? true : false}
              />
            </div>
            <div className="mt-3">
              <TextField
                onChange={(value: string) => { handleChange('address', value); }}
                error={validateErrors?.address ? true : false}
                id="address"
                label={t('profilePage.fieldLabels.address')}
                value={input.address || ''}
              />
            </div>
            <div className="d-flex gap-4 justify-content-between personal-container-row-4 mt-3">
              <label htmlFor="dropdown" className="text-field-label">
                <span className={`${validateErrors?.city && 'text-invalid'}`}>{t('profilePage.fieldLabels.city')}</span>
                <CustomSearchDropdown
                  onChange={(item: City) => handleChange('city', { id: item.id, name: item.name })}
                  error={validateErrors?.city ? true : false}
                  value={input.city}
                />
              </label>
              <TextField
                onChange={(value: string) => { handleChange('zipCode', value); }}
                error={validateErrors?.zipCode ? true : false}
                id="zip-code"
                label={t('profilePage.fieldLabels.zipCode')}
                value={input.zipCode || ''}
              />
            </div>
          </Card>
        </section>
        <section className="col-md-6 mb-4">
          <Card>
            <CardTitle>{t('profilePage.titles.generalInfo')}</CardTitle>
            <div className="mt-3">
              <label htmlFor="medelse-dropdown" className="text-field-label">
                <span>{t('profilePage.fieldLabels.exerciceAutorization')}</span>
                <DropdownButton id="medelse-dropdown" title={input?.authorization ? t(`enum.medelse_core_bundle_entity_enum_user_can_exercise_${input.authorization.toLowerCase()}`) : t('profilePage.fieldLabels.exerciceAutorization')}>
                  <Dropdown.Item
                    className={UserCanExerciseEnum.Yes === input?.authorization ? 'bg-primary text-white' : undefined}
                    onClick={() => handleChange('authorization', UserCanExerciseEnum.Yes)}
                  >
                    {t('enum.medelse_core_bundle_entity_enum_user_can_exercise_yes')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={UserCanExerciseEnum.Pending === input?.authorization ? 'bg-primary text-white' : undefined}
                    onClick={() => handleChange('authorization', UserCanExerciseEnum.Pending)}
                  >
                    {t('enum.medelse_core_bundle_entity_enum_user_can_exercise_pending')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={UserCanExerciseEnum.No === input?.authorization ? 'bg-primary text-white' : undefined}
                    onClick={() => handleChange('authorization', UserCanExerciseEnum.No)}
                  >
                    {t('enum.medelse_core_bundle_entity_enum_user_can_exercise_no')}
                  </Dropdown.Item>
                </DropdownButton>
              </label>
            </div>
            <div className="mt-3">
              <label htmlFor="medelse-dropdown" className="text-field-label">
                <span>{t('profilePage.fieldLabels.specialty')}</span>
                <DropdownButton id="medelse-dropdown" title={input.primarySpecialty?.name ? t(`database_fields.${input.primarySpecialty?.name}`) : t('profilePage.fieldLabels.specialty')}>
                  {specialtiesData && transformSpecialtiesData(specialtiesData)!.map(
                    (specialty) => {
                      const isOtherSpecialty = input?.otherSpecialties?.some(
                        (x) => x.id === specialty?.id,
                      );
                      if (isOtherSpecialty) {
                        return null;
                      }
                      return (
                        <Dropdown.Item
                          key={specialty!.id}
                          className={specialty!.id === input?.primarySpecialty?.id ? 'bg-primary text-white' : undefined}
                          onClick={() => handleChange('primarySpecialty', specialty)}
                        >
                          {t(`database_fields.${specialty!.name!}`)}
                        </Dropdown.Item>
                      );
                    },
                  )}
                </DropdownButton>
              </label>
            </div>
            <div className="mt-3">
              {otherSpecialtiesVisible || input!.otherSpecialties!.length > 0
                ? (
                  <label htmlFor="medelse-dropdown" className="text-field-label">
                    <span>{t('profilePage.fieldLabels.otherSpecialties')}</span>
                    <DropdownButton
                      id="medelse-dropdown"
                      className="other-specialties"
                      title={(
                        <div className="dropdown-other-specialties">
                          {input!.otherSpecialties!.length > 0 ? (input.otherSpecialties?.map(
                            (specialty) => (
                              <span key={specialty.id} data-testid="other-specialty-selected-placeholder">
                                {t(`database_fields.${specialty.name}`)}
                                ,&nbsp;
                              </span>
                            ),
                          )) : t('profilePage.fieldLabels.otherSpecialties')}
                        </div>
)}
                    >
                      {specialtiesData && transformSpecialtiesData(specialtiesData)!.map(
                        (specialty) => {
                          if (input?.primarySpecialty?.id === specialty?.id) {
                            return null;
                          }
                          const isOtherSpecialty: boolean = input!.otherSpecialties!.some(
                            (someSpecialty) => someSpecialty.id === specialty!.id,
                          );
                          return (
                            <Dropdown.Item
                              key={specialty!.id}
                              className={isOtherSpecialty ? 'bg-primary text-white' : undefined}
                              onClick={() => {
                                handleChange('otherSpecialties', specialty!);
                              }}
                            >
                              {t(`database_fields.${specialty!.name!}`)}
                            </Dropdown.Item>
                          );
                        },
                      )}
                    </DropdownButton>
                  </label>
                ) : (
                  <button
                    style={{ border: 'none', backgroundColor: 'transparent', color: theme.primary }}
                    type="button"
                    onClick={() => setOtherSpecialtiesVisible(true)}
                  >
                    {t('profilePage.info.openOtherSpecialties')}
                  </button>
                )}
            </div>
            <div className="mt-3">
              <TextField
                onChange={(value: string) => { handleChange('rppsNumber', value); }}
                id="rppsNumber"
                label={t('profilePage.fieldLabels.rpps')}
                value={input.rppsNumber || ''}
              />
            </div>
            <div className="mt-3">
              <TextField
                onChange={(value: string) => { handleChange('siret', value); }}
                id="siret"
                label={t('profilePage.fieldLabels.siret')}
                value={input.siret || ''}
              />
            </div>
          </Card>
        </section>
        <div className="mb-3 w-100 d-grid">
          <Button title={t('profilePage.info.save')} variant="primary" onClick={() => handleSubmit()} />
        </div>
      </div>
      <div className="mt-3 row justify-content-center">
        <section className="col-md-12">
          <Card>
            <CardTitle>{t('profilePage.titles.documents')}</CardTitle>
            <div className="mt-3">
              <FileField
                id="field-files"
                label={t('profilePage.fieldLabels.nic')}
                onEdit={() => setIdDocumentsFormVisible(true)}
                fileCount={[input.nicFront, input.nicBack].filter((el) => el !== undefined).length}
                totalFileCount={[input.nicFront, input.nicBack].length}
              />
              {(input.nicFront || input.nicBack)
              && (input.nicFront?.uploadedAt || input.nicBack?.uploadedAt)
              && (
                <span className="fst-italic text-muted text-s" style={{ fontSize: 12 }}>
                  {`${t('date.lastUpdated')} ${getLastUpdatedDate([input.nicFront?.uploadedAt, input.nicBack?.uploadedAt])}`}
                </span>
              )}
            </div>
            <hr />
            <div className="mt-3">
              <Field
                id="iban"
                label={t('profilePage.fieldLabels.iban')}
                value={input.proIban}
                onEdit={() => setIbanFormVisible(true)}
              />
              {data?.me?.ibanUpdatedAt && (
                <span className="fst-italic text-muted text-s" style={{ fontSize: 12 }}>
                  {`${t('date.lastUpdated')} ${formatDateTime(data?.me?.ibanUpdatedAt, 'dd/MM/yyyy (HH:mm)')}`}
                </span>
              )}
            </div>
          </Card>
          <Modal onClose={() => setIbanFormVisible(false)} visible={ibanFormVisible}>
            <ProfileIbanForm
              iban={input.proIban}
              onClose={() => {
                setIbanFormVisible(false);
              }}
              onMutationSuccess={() => {
                setIbanFormVisible(false);
              }}
            />
          </Modal>
          <Modal onClose={() => setIdDocumentsFormVisible(false)} visible={idDocumentsFormVisible}>
            <ProfileIdDocumentsForm
              documents={{
                nicFront: {
                  name: input.nicFront?.publicName || null,
                  lastUpdatedDate: input.nicFront?.uploadedAt && formatDateTime(input.nicFront?.uploadedAt, 'dd/MM/yyyy (HH:mm)'),
                },
                nicBack: {
                  name: input.nicBack?.publicName || null,
                  lastUpdatedDate: input.nicBack?.uploadedAt && formatDateTime(input.nicBack?.uploadedAt, 'dd/MM/yyyy (HH:mm)'),
                },
              }}
              onClose={() => {
                setIdDocumentsFormVisible(false);
              }}
              onMutationSuccess={() => {
                setIdDocumentsFormVisible(false);
              }}
            />
          </Modal>
          <ToastContainer position="top-end">
            <Toast
              show={profileToastVisible}
              onClose={() => setProfileToastVisible(false)}
              message={t('profilePage.successMessage.profile')}
            />
          </ToastContainer>
        </section>
      </div>
    </main>
  );
};

export default Profile;
