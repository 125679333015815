import React from 'react';
import { Toast as BSToast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type Props = {
  message: string
  show?: boolean
  onClose?: VoidFunction | undefined
};

const Toast = ({
  message,
  onClose,
  show,
}: Props) => (
  <BSToast
    onClose={onClose}
    show={show}
    className="toast toast-success"
  >
    <BSToast.Header className="header header-success" closeButton={onClose !== undefined}>
      <div className="icon-container icon-container-success">
        <FontAwesomeIcon icon={faCheck} role="img" fontSize={20} />
      </div>
      <div className="ms-3 me-auto">
        <strong>{message}</strong>
      </div>
    </BSToast.Header>
  </BSToast>
);

Toast.defaultProps = {
  show: true,
  onClose: undefined,
};

export default Toast;
