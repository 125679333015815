import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';
import useRecover from '../hooks/useRecover';

type Props = { onCancel: VoidFunction };

const RecoverForm = ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    success,
    recoverPassword,
  } = useRecover();
  const [email, setEmail] = useState<string>('');
  const [isEmailFieldValid, setIsEmailFieldValid] = useState<boolean>(true);

  const validateEmail = (emailToValidate: string) => String(emailToValidate)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const emailIsValid = email.trim().length > 0 && validateEmail(email);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (emailIsValid) {
      setIsEmailFieldValid(true);
      recoverPassword(email, () => setEmail(''));
    } else {
      setIsEmailFieldValid(false);
    }
  };

  return (
    <div className="mt-3">
      {success && !error && (
        <div className="alert alert-success" role="alert">
          {t('recover.successMessage')}
        </div>
      )}
      {error && (
        <AlertMessage message={error} />
      )}
      {loading ? (
        <QueryIndicator />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="input-group has-validation">
            <label htmlFor="emailField" className="form-label w-100">
              {t('recover.email')}
              <input
                className={`form-control${!isEmailFieldValid ? ' is-invalid' : ''}`}
                id="emailField"
                name="email"
                type="email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                required
              />
              {!isEmailFieldValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
            </label>
          </div>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-grey me-2"
              onClick={onCancel}
            >
              {t('actions.cancel')}
            </button>
            <button
              type="submit"
              className="btn btn-medelse btn-primary"
            >
              {t('recover.submit')}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default RecoverForm;
