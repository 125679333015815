import React from 'react';

import GraphQLProvider from './providers/GraphqlProvider';
import TranslationsProvider from '../i18n/TranslationsProvider';
import AuthProvider from '../auth/view/providers/AuthProvider';
import Router from '../navigation/Router';

const App = () => (
  <TranslationsProvider>
    <AuthProvider>
      <GraphQLProvider>
        <Router />
      </GraphQLProvider>
    </AuthProvider>
  </TranslationsProvider>
);

export default App;
