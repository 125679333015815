import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../app/components/Card';
import PageLayout from '../../app/components/PageLayout';
import ContractTable from '../../features/Contract/ContractTable';

const ContractsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout navigateTo={(path) => navigate(path)} title={t('pages.contracts')}>
      <Card>
        <ContractTable />
      </Card>
    </PageLayout>
  );
};

export default ContractsPage;
