import React from 'react';
import { Spinner as BootsrapSpinner } from 'react-bootstrap';

const Spinner = () => (
  <BootsrapSpinner animation="border" role="status" variant="primary">
    <span className="visually-hidden">Loading...</span>
  </BootsrapSpinner>
);

export default Spinner;
