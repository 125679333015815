import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FieldProps = {
  label: string
  id: string
  value: string
  onEdit?: undefined | VoidFunction
  type?: React.HTMLInputTypeAttribute
};

type EditButtonProps = { id: string, onEdit: VoidFunction };

const EditButton = ({ onEdit, id }: EditButtonProps) => (
  <button
    aria-labelledby="Edit button"
    onClick={onEdit}
    type="button"
    data-testid={`${id}-edit-icon`}
    className="edit-button"
  >
    <FontAwesomeIcon
      className="text-primary"
      icon={faEdit}
      role="img"
      fontSize={18}
    />
  </button>
);

const Field = ({
  label, id, value, onEdit, type,
}: FieldProps) => (
  <label
    htmlFor={id}
    className="row"
  >
    <span className="col text-dark">{label}</span>
    <div
      role="button"
      tabIndex={0}
      onClick={onEdit}
      onKeyDown={onEdit}
      className="col col-sm-auto"
    >
      <input
        aria-labelledby={id}
        id={id}
        disabled
        value={value}
        type={type}
        className="field-label-input border border-1 px-2 py-1 rounded-1"
      />
    </div>
    <div className="col-auto d-flex self-center">
      {onEdit !== undefined && <EditButton id={id} onEdit={onEdit} />}
    </div>
  </label>
);

Field.defaultProps = {
  onEdit: undefined, type: 'text',
};

export default Field;
