import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import { useGetFeesInvoicesQuery } from '../../../app/generated/graphql';
import { downloadDocument } from '../../../app/domain/utils';
import { GetEntityListUsecase } from '../../../app/domain/GetEntityListUsecase';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import { FeesInvoice } from '../domain';
import FeesInvoicesRepository from '../FeesInvoicesRepository';

const useInvoices: GetEntityListUsecase<FeesInvoice> = () => {
  let invoices: FeesInvoice[] | undefined;
  const { error, loading, data } = useGetFeesInvoicesQuery();

  if (data) {
    const repo = new FeesInvoicesRepository(data);

    invoices = repo.getInvoices();
  }

  return {
    loading,
    entities: invoices,
    errorMessage: error?.message,
  };
};

const InvoiceTable = () => {
  const { t } = useTranslation();
  const {
    entities,
    errorMessage,
    loading,
  } = useInvoices();

  const columns: ColumnDef<FeesInvoice>[] = [
    {
      accessorKey: 'invoiceNumber',
      header: () => t('invoice.invoiceNumber'),
    },
    {
      id: 'groupOffice',
      header: () => t('invoice.groupOffice'),
      accessorFn: (row) => row.office,
    },
    {
      accessorKey: 'amount',
      header: () => t('invoice.amount'),
    },
    {
      id: 'created_at',
      header: () => t('invoice.created_at'),
      accessorFn: (row) => row.createdAt,
    },
    {
      id: 'dates',
      header: () => t('invoice.dates'),
      accessorFn: (row) => row.dates.join('; '),
    },
    {
      id: 'download',
      header: () => t('invoice.download'),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }) => (
        <button
          type="button"
          className="btn border-0 text-primary"
          data-testid="donwload_fees_invoice"
          aria-labelledby="Download fees invoice"
          onClick={() => downloadDocument(row.original.downloadURL)}
        >
          <FontAwesomeIcon icon={faFileArrowDown} />
        </button>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];

  if (loading) {
    return <QueryIndicator />;
  }

  if (errorMessage) {
    return <div className="alert alert-danger">{errorMessage}</div>;
  }

  return (
    <Table
      title={t('feesInvoices.tableTitle')}
      pageSize={ARRAY_ITEMS_PER_PAGE}
      columns={columns}
      data={entities!}
      canBeFiltered
    />
  );
};

export default InvoiceTable;
