import React from 'react';

import { SPLIT_LAYOUT_COVER_IMAGE_URL } from '../../constants';

const coverImageBaseStyles: React.CSSProperties = {
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

type Props = { children: React.ReactChild };

const LayoutSplit = ({ children }: Props) => {
  const coverImageStyles = {
    ...coverImageBaseStyles,
    backgroundImage: `url(${SPLIT_LAYOUT_COVER_IMAGE_URL})`,
  };

  return (
    <div className="container-fluid">
      <div className="min-vh-100 row">
        <div className="d-none d-lg-block position-relative col-6">
          <div className="bg-holder" style={coverImageStyles} />
        </div>
        <div className="align-self-center mx-auto py-3 col-md-6 col-sm-10">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutSplit;
