import jwtDecode from 'jwt-decode';

type ManageTracking = (token: string | null) => void;
type UseTracking = () => {
  manageTracking: ManageTracking
};

const manageTracking: ManageTracking = (token) => {
  document.body.dataset.logged = token ? 'true' : 'false';
  if (token) {
    const decodedToken: any = jwtDecode(token);
    const isAdmin = decodedToken.username && (decodedToken.username as string).endsWith('@medelse.com');
    document.body.dataset.admin = isAdmin ? 'true' : 'false';
    document.body.dataset.username = decodedToken.username;
  } else {
    delete document.body.dataset.admin;
    delete document.body.dataset.username;
  }
};

const useTracking: UseTracking = () => ({
  manageTracking,
});

export default useTracking;
