import React from 'react';
import { useTranslation } from 'react-i18next';

import AlertMessage from 'app/components/AlertMessage';
import CardTitle from 'app/components/Card/CardTitle/CardTitle';
import FormActions from 'app/components/FormActions';
import QueryIndicator from 'app/components/QueryIndicator';
import { useDeleteCandidateAccountMutation } from 'app/generated/graphql';

type Props = {
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersDeleteAccountForm = ({ onCancel, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [deleteCandidateAccount, { loading, error }] = useDeleteCandidateAccountMutation({
    onCompleted() { onMutationSuccess(); },
    onError() {},
  });

  return (
    <div>
      <CardTitle>{t('parametersDeleteAccountForm.title')}</CardTitle>
      { loading ? (
        <QueryIndicator />
      ) : (
        <div>
          {error && <AlertMessage message={error.message} />}
          <div style={{ marginBottom: 35, font: "400 16px/24px 'Open Sans', sans-serif" }}>
            {t('parametersDeleteAccountForm.description')}
          </div>
          <div style={{ font: "400 16px/24px 'Open Sans', sans-serif", fontStyle: 'italic' }}>
            {t('parametersDeleteAccountForm.legalNotice')}
          </div>
          <FormActions
            onCancel={onCancel}
            onConfirm={deleteCandidateAccount}
            variant="negative"
          />
        </div>
      )}
    </div>
  );
};

export default ParametersDeleteAccountForm;
