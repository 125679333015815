import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RegisterForm from 'features/register/RegisterForm';
import { Tokens } from 'auth/domain/types/Tokens';
import LoginService from 'auth/data/LoginService';
import LayoutSplit from 'app/components/LayoutSplit/LayoutSplit';
import { UtmDataInput } from 'app/domain/registerTypes';

const RegisterPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const utmData: UtmDataInput = {
    utmCampaign: null,
    utmContent: null,
    utmMedium: null,
    utmSource: null,
    utmTerm: null,
  };

  queryParams.forEach((utmValue, utmKey) => {
    if (utmKey === 'utm_campaign' && utmValue) {
      utmData.utmCampaign = utmValue;
    }
    if (utmKey === 'utm_content' && utmValue) {
      utmData.utmContent = utmValue;
    }
    if (utmKey === 'utm_medium' && utmValue) {
      utmData.utmMedium = utmValue;
    }
    if (utmKey === 'utm_source' && utmValue) {
      utmData.utmSource = utmValue;
    }
    if (utmKey === 'utm_term' && utmValue) {
      utmData.utmTerm = utmValue;
    }
  });

  const handleAutolog = (tokens: Tokens) => {
    LoginService.saveToken(tokens);
    navigate('/');
  };

  return (
    <LayoutSplit>
      <div className="row justify-content-center px-3" id="register-page">
        <div className="col-xxl-8 col-xl-8 col-lg-9">
          <RegisterForm
            onAutoLog={handleAutolog}
            utmData={utmData}
          />
          <div className="text-center my-4">
            <span>{t('register.alreadyHaveAnAccount')}</span>
            <Link
              className="ms-2 link link-primary fw-bold"
              to="/login"
            >
              {t('register.login')}
            </Link>
          </div>
        </div>
      </div>
    </LayoutSplit>
  );
};

export default RegisterPage;
