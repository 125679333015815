import { GetInvoicesQueryResult, PaymentStatus } from '../../../app/generated/graphql';
import { IInvoiceRepository } from '../domain/IInvoiceRepository';
import { Invoice } from '../domain/models';
import { formatDateTime, formatNumberToCurrency } from '../../../app/domain/utils';

class InvoiceRepository implements IInvoiceRepository {
  private invoices: Invoice[];

  constructor(data: GetInvoicesQueryResult['data']) {
    let paymentStatus: PaymentStatus;
    if (data && data.me && data.me.invoices && data.me.invoices.edges) {
      this.invoices = data.me.invoices.edges.map((item) => {
        const invoice = item!.node!;

        switch (invoice.payment?.status) {
          case PaymentStatus.Paid:
            paymentStatus = PaymentStatus.Paid;
            break;
          case PaymentStatus.Canceled:
            paymentStatus = PaymentStatus.Canceled;
            break;
          case PaymentStatus.Blocked:
            paymentStatus = PaymentStatus.Blocked;
            break;
          default:
            paymentStatus = PaymentStatus.Processing;
            break;
        }

        return ({
          amount: formatNumberToCurrency((invoice.totalAmount as number)),
          createdAt: formatDateTime(invoice.createdAt),
          dates: invoice.contractDetails!.map((cd) => formatDateTime(cd!.dateTimes.startDate)),
          datePaid: invoice.payment!.pendingDate
            ? formatDateTime(invoice.payment!.pendingDate) : null,
          downloadURL: (invoice.documentDownloadLink as string),
          invoiceNumber: invoice.id,
          office: `${invoice.groupOffice?.name} (${invoice.groupOffice?.zipCode})`,
          status: paymentStatus,
        });
      });
    } else {
      this.invoices = [];
    }
  }

  getInvoices() {
    return this.invoices;
  }
}

export default InvoiceRepository;
