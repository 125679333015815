import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'app/components/Card';
import CheckAccountStatus from 'app/view/CheckAccountStatus';
import DownloadAppButton from '../../components/DownloadAppButton';

import mockupDashboard from '../../../assets/mockup-app-dashboard-20240318.png';
import mockupMissions from '../../../assets/mockup-app-missions-20240318.png';
import bgShapePlum from '../../../assets/bg-shape-plum.png';
import bgShapeBlue from '../../../assets/bg-shape-blue.png';
import bgShapeBlueMobile from '../../../assets/bg-shape-blue-mobile.png';
import QR from '../../../assets/QR.svg';

const bgStyles = {
  backgroundImage: `url(${bgShapePlum}), url(${bgShapeBlue}), url(${bgShapeBlueMobile})`,
};

const DownloadAppView = () => {
  const { t } = useTranslation();
  return (
    <div id="download-app-view">
      <Card style={bgStyles}>
        <div className="p-xl-5">
          <div className="row">
            <div className="col-xl-6">
              <h1 className="display-1 fw-bold text-uppercase mb-4">
                {t('downloadAppView.title')}
              </h1>
              <div className="mb-5">{t('downloadAppView.content')}</div>
              <div className="pt-xl-5">
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <div className="d-flex d-xl-block d-xl-block justify-content-evenly w-100">
                      <div className="mb-xl-5">
                        <DownloadAppButton store="apple" />
                      </div>
                      <DownloadAppButton store="google" />
                    </div>
                  </div>
                  <div className="col-xl-4 d-none d-xl-block">
                    <div className="text-center">
                      <img
                        src={QR}
                        alt="QR code"
                        width={100}
                        height="auto"
                        className="mb-3"
                      />
                      <div>{t('downloadAppView.QRcode')}</div>
                    </div>
                  </div>
                  <div className="col-xl-2 d-none d-xl-block" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 pt-5 px-2 px-xl-5 pt-xl-0">
              <div className="row">
                <div className="col" style={{ marginRight: -40 }}>
                  <img src={mockupDashboard} alt="App preview dashboard" className="img-fluid p-4" />
                </div>
                <div className="col mt-5 mt-xl-0">
                  <img src={mockupMissions} alt="App preview missions" className="img-fluid p-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <CheckAccountStatus />
    </div>
  );
};

export default DownloadAppView;
