import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from 'app/components/Button';
import Card from 'app/components/Card';

type Props = { onContinue: VoidFunction };

const RegisterStep3Form = ({ onContinue }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Card>
        <div className="text-center">
          <FontAwesomeIcon icon={faEnvelopeOpenText} className="display-3 mb-4" />
          <div className="h5 fw-bold">{t('register.validation.title')}</div>
          <div>{t('register.validation.content')}</div>
        </div>
      </Card>
      <div className="d-grid gap-2 mt-5">
        <Button title={t('register.continue')} variant="primary" onClick={onContinue} />
      </div>
    </div>
  );
};

export default RegisterStep3Form;
