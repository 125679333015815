import React from 'react';

type Props = { children: string };

const CardTitle = ({ children }: Props) => (
  <h2 className="card-title fw-bold border-bottom border-secondary pb-2 mb-2">
    {children}
  </h2>
);
export default CardTitle;
