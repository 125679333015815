import React, { ReactNode, useEffect, useState } from 'react';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import i18n from 'i18next';

import TranslationsService from './TranslationsService';

type Props = {
  children: ReactNode
};

const TranslationsProvider = ({ children }: Props) => {
  const [i18nInstance, setInstance] = useState<typeof i18n>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const service = new TranslationsService();
    service.initResources()
      .then((resources) => {
        i18n
          .use(initReactI18next)
          .init({
            debug: true,
            fallbackLng: 'fr',
            interpolation: {
              escapeValue: false, // not needed for react as it escapes by default
            },
            resources: {
              fr: {
                translation: { ...resources },
              },
            },
          });
        setInstance(i18n);
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (<div />) : (
    <I18nextProvider i18n={i18nInstance!}>
      { children }
    </I18nextProvider>
  );
};

export default TranslationsProvider;
